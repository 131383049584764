<template>
  <div v-show="loading">
    <div class="modal-background"></div>
     <div class="modal-wrap">
      <div class="saving-modal d-flex flex-column align-center">
        <div>
          <img src="@/assets/icons/searching.gif" alt="searching" class="h-78">
        </div>
        <span>Saving...</span>
      </div>
    </div>
  </div>
  <div class="map-container" id="screenshot-map">
    <div :id="count" class="mapid"></div>
    <Feature visible="VUE_APP_NSA_1410">
      <div class="print-screen__container">
        <button class="print-screen" :class="{ 'screen-taken':  screenshotStatus === 'shot' }">
          <Icon v-if="screenshotStatus === 'save'" name="saved-screen"/>
          <Icon v-else name="print-screen" @click="takeScreenshot"/>
        </button>
        <TagPopup v-if="popupIsOpen" :class="{openInMap: popupIsOpen}" :openInMap="true" @closeTagsPopup="closeTagsPopup" @currentTag="currentTag" :mapScreen="mapScreen" :profiles="[mapScreen]"/>
      </div>

      <div v-if="screenshotStatus === 'shot'" class="p-absolute top-0px right-0px bottom-0px left-0px d-flex fd-column jc-center ai-center bfilter-blur-14 z-400">
        <h6 class="text-h6">Screenshot taken</h6>
        <p class="text-base mt-6">Add a tag and we can add it to the report</p>
      </div>
    </Feature>
  </div>

  <div class="mp-16 mb-2">
    <div v-if="sendSuccess" class="input-info d-flex jc-space-between pb-24">
      <div>
        <h5 class="text-h5 text-initial color-link-darker">The tag has been saved </h5>
        <p class="text-base fw-400 text-initial color-text mt-8">Located in tag section</p>
      </div>
      <Icon class="w-12px h-12px cursor-pointer" name="cancel-btn" @click="sendSuccess = false" />
    </div>

    <div v-if="sendError" class="input-error d-flex jc-space-between pb-24">
      <div>
        <h5 class="text-h5 text-initial color-warning">Something went wrong</h5>
        <p class="text-base fw-400 text-initial color-warning mt-8">Contact support team for help</p>
      </div>
      <Icon class="w-12px h-12px cursor-pointer" color="#FF7A7A" name="cancel-btn" @click="closeError" />
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import { eventListener, getEntityAvatar, getEntityName, getLocation } from '../../utils/helpers';
import Icon from '@/components/app/Icon';
import TagPopup from '@/components/TagPopup';
import * as htmlToImage from 'html-to-image';
import Feature from '@/components/app/Feature';

export default {
  name: 'Map',
  props: ['entities', 'type'],
  components: {
    Icon, TagPopup, Feature
  },
  data () {
    return {
      map: null,
      iconAnchor: [20, 74],
      popupIsOpen: false,
      mapScreen: null,
      tagOid: null,
      loading: false,
      count: null,
      screenshotStatus: null,
      image: null,
      sendSuccess: false,
      sendError: false
    };
  },
  async mounted () {
    this.createMap();
    this.addTileLayer();
    const clusters = this.createClusters();

    for (const ent of this.entities) {
      if ((ent.lat && ent.lon) || (ent.location && ent.location.lat && ent.location.lng)) {
        const marker = this.createMarker(ent);
        if (!ent?.fields?.connection?.value) {
          if (!ent.fields['user-details']) {
            this.addMarkerPopup(marker, ent);
          } else {
            this.addMarkerClickListener(marker, ent);
          }
        }
        clusters.addLayer(marker);
      }
    }
    this.map.addLayer(clusters);

    if (this.type === 'results') {
      this.$store.commit('addResultsMap', this.map);
    }

    this.map.on('move', () => {
      if (this.screenshotStatus !== 'shot') {
        this.tagOid = null;
        this.screenshotStatus = null;
        this.image = null;
      }
    });
  },
  beforeMount () {
    this.count = Math.random().toString().replace('.', '');
  },
  beforeUnmount () {
    if (this.type === 'results') {
      this.$store.commit('addResultsMap', null);
    }
  },
  methods: {
    currentTag (data) {
      if (data) {
        this.loading = true;
        this.tagOid = data.oid;
        this.popupIsOpen = false;
        this.sendScreenshot(this.img);
      }
    },
    closeTagsPopup () {
      this.popupIsOpen = false;
      this.screenshotStatus = null;
      this.img = null;
    },
    async sendScreenshot (img) {
      const formdata = new FormData();
      const data = {
        case_id: this.$store.state.results.case.oid,
        tag_id: this.tagOid
      };
      formdata.append('data', JSON.stringify(data));
      formdata.append('image', img);
      const payload = {
        method: 'POST',
        url: '/api/user/reports/screenshots',
        body: formdata
      };
      try {
        await this.$store.dispatch('ajaxWithTokenRefresh', payload)
          .then(() => {
            this.popupIsOpen = false;
            this.sendSuccess = true;
            this.screenshotStatus = 'save';
          });
      } catch (error) {
        console.log(error);
        this.sendError = true;
        this.screenshotStatus = null;
      }
      this.loading = false;
    },
    takeScreenshot () {
      this.sendError = false;
      this.sendSuccess = false;
      this.loading = true;

      htmlToImage.toJpeg(document.getElementById('screenshot-map'), { quality: 0.75 })
        .then(dataUrl => {
          this.screenshotStatus = 'shot';
          this.popupIsOpen = true;
          this.img = this.dataURLtoFile(dataUrl, 'map.jpeg');
          this.loading = false;
        });
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    openProfileSidebar (profile) {
      eventListener.emit('openProfileSidebar', profile);
    },
    addMarkerClickListener (marker, entity) {
      marker.on('click', () => { this.openProfileSidebar(entity); });
    },
    createMap () {
      this.map = window.L.map(this.count, {
        zoomControl: true,
        zoom: 1,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true
      }).setView([30, 10], 2);
    },
    addTileLayer () {
      window.L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
      }).addTo(this.map);
    },
    createClusters () {
      const L = window.L;
      return L.markerClusterGroup({
        showCoverageOnHover: false,
        chunkedLoading: true,
        spiderLegPolylineOptions: { opacity: 0 },
        iconCreateFunction: function (cluster) {
          const html = '<div class="map-cluster-icon">' + cluster.getChildCount() + '</div>';
          return L.divIcon({
            html: html,
            className: 'map-cluster',
            iconSize: L.point(54, 76),
            iconAnchor: this.iconAnchor
          });
        }
      });
    },
    createMarker (entity) {
      const L = window.L;
      const avatar = getEntityAvatar(entity) || require('../../assets/icons/avatar.png');
      const markerIcon = L.divIcon({
        html: '<div class="map-marker-icon"><img class="map-marker-avatar" src="' + avatar + '"></div>',
        className: 'map-marker',
        iconAnchor: this.iconAnchor
      });
      if (entity.location && entity.location.lat && entity.location.lng) {
        return L.marker([entity.location.lat, entity.location.lng], { icon: markerIcon });
      } else if (entity.lat && entity.lon) {
        return L.marker([entity.lat, entity.lon], { icon: markerIcon });
      }
    },
    addUserDetailsClickListener ({ btn, popup, entity, marker }) {
      btn.onclick = () => {
        this.openProfileSidebar(entity);
        this.$nextTick(() => {
          const timerID = setInterval(function () {
            const userDetailsButton = document.querySelector('.user-details-btn');
            if (userDetailsButton) {
              clearInterval(timerID);
              userDetailsButton.click();
            }
          }, 100);
          popup.closePopup();
          popup.unbindPopup();
          this.addMarkerClickListener(marker, entity);
        });
      };
    },
    addPopupOpenListener ({ popup, entity, marker }) {
      popup.on('popupopen', () => {
        for (const btn of document.querySelectorAll('.leaflet-popup-content-wrapper .map-popup-btn')) {
          btn.remove();
        }
        for (const popupWrapper of document.querySelectorAll('.leaflet-popup-content-wrapper')) {
          const btn = document.createElement('button');
          btn.classList.add('map-popup-btn');
          btn.textContent = 'Get more info (5 slp)';
          popupWrapper.append(btn);
          this.addUserDetailsClickListener({ btn, popup, entity, marker });
        }
        for (const leafletPopup of document.querySelectorAll('.leaflet-popup')) {
          leafletPopup.style.bottom = (parseInt(leafletPopup.style.bottom) - 60) + 'px';
        }
      });
    },
    addMarkerPopup (marker, entity) {
      let popupInfoList = '';
      for (const row of this.bio(entity)) {
        popupInfoList += `<li>${row}</li>`;
      }

      const popup = marker.bindPopup(`<div class="map-popup"><ul class="map-popup-list">${popupInfoList}</ul></div>`, {
        maxWidth: 361,
        minWidth: 361,
        maxHeight: 255,
        closeButton: false,
        offset: [7, -120]
      });
      this.addPopupOpenListener({ popup, entity, marker });
    },
    alias (profile) {
      const fields = profile.fields;
      let alias = fields?.alias;
      if (!alias) {
        alias = fields && fields['twitter.screen-name'];
      }
      if (!alias) {
        alias = fields?.username;
      }
      return (alias?.value) || '';
    },
    id (profile) {
      const fields = profile.fields;
      let id = fields?.id;
      if (!id) {
        id = fields && fields['affiliation.uid'];
      }
      if (!id) {
        id = fields && fields['twitter.id'];
      }
      return (id?.value) || '';
    },
    getProfileFieldValue (profile, fieldName) {
      try {
        return profile.fields[fieldName].value;
      } catch (e) {
        return '';
      }
    },
    socialNet (profile) {
      const typeID = profile.typeid.toLowerCase();
      if (typeID.includes('facebook')) {
        return 'Facebook';
      }
      if (typeID.includes('twitter')) {
        return 'Twitter';
      }
      if (typeID.includes('instagram')) {
        return 'Instagram';
      }
      if (typeID.includes('linkedin')) {
        return 'Linkedin';
      }
      return '';
    },
    info (profile) {
      if (profile?.fields?.info) {
        return profile.fields.info.value.split('###');
      }
      return [];
    },
    bio (profile) {
      const bio = [getEntityName(profile)];
      const alias = this.alias(profile);
      if (alias) {
        bio.push(alias);
      }
      const id = this.id(profile);
      if (id) {
        bio.push((!isNaN(id)) ? 'ID: ' + id : id);
      }
      const createdDate = this.getProfileFieldValue(profile, 'created_at');
      if (createdDate) {
        bio.push('Date of creation: ' + createdDate);
      }
      const followersCount = this.getProfileFieldValue(profile, 'followers_count');
      if (followersCount) {
        bio.push(followersCount + ' followers');
      }
      const tweetsCount = this.getProfileFieldValue(profile, 'tweets_count');
      if (tweetsCount) {
        bio.push(tweetsCount + ' tweets');
      }
      const friendsCount = this.getProfileFieldValue(profile, 'twitter.friendcount');
      if (friendsCount) {
        bio.push(friendsCount + ' friends');
      }
      const description = this.getProfileFieldValue(profile, 'description');
      if (description) {
        bio.push('Description: ' + description);
      }
      const location = getLocation(profile);
      const socialNet = this.socialNet(profile);
      if (location && socialNet !== 'Facebook') {
        bio.push(location);
      }
      bio.push(...this.info(profile));
      return bio;
    }
  }
};
</script>

<style lang="scss">
  .mapid {
    height: 400px;
  }
  .map-popup {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #8D8D8E;
    &-list {
      margin: 0;
      & li {
        padding: 6px 16px;
        &:nth-child(odd) {
          background-color: #EFF4F7;
        }
        &:nth-child(even) {
          background-color: #F8FCFF;
        }
      }
    }
    &-btn {
      font-style: normal;
      font-size: 14px;
      letter-spacing: 0.04em;
      padding: 16px;
      font-weight: 600;
      line-height: 19px;
      color: #60BCE3;
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }
  }
  .leaflet-popup {
    margin-bottom: 0;
    border-radius: 8px;
  }
  .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 8px;
  }
  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    box-shadow: 0 70px 100px 8px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px -70px 100px rgba(0, 0, 0, 0.03));
  }
  .leaflet-popup-content {
    margin: 0;
    border-radius: 8px;
    overflow-y: auto;
    background: #D6E2F1;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #D6E2F1;
      border-top-right-radius: 300px;
      margin-top: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #60BCE3;
      border-top-right-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #8BD1EF;
    }
  }
  .leaflet-popup-scrolled {
    border: none;
    border-radius: 8px;
  }
  .map-container {
    position: relative;
  }
  .print-screen__container {
    z-index: 999;
    position: absolute;
    top: 32px;
    right: 32px;
  }
  .print-screen {
    &:hover, &.screen-taken {
      svg {
        circle {
          fill: #60BCE3;
        }
        path {
          fill: white;
        }
      }
    }
  }
  .pressed {
    circle {
      fill: #60BCE3;
      stroke: white;
    }
    path {
      fill: white;
    }
  }
  .openInMap {
    z-index: 999;
    left: -260px;
    bottom: 65px;
    .triangle {
      left: 265px;
    }
  }
  .saving-modal {
    background: transparent;
    position: relative;
    padding: 48px;
    span {
      font-family: "Segoe UI", "Segoe UI Mac", Avenir, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #8D8D8E;
    }
  }
</style>
