<template>
  <div class="search-input">
    <div class="d-flex justify-between align-center">
      <h3>{{ title }}</h3>
      <!-- <div class="d-flex" v-if="!searchCompleted">
        <span class="load-circle load-circle--1"></span>
        <span class="load-circle load-circle--2"></span>
        <span class="load-circle load-circle--3"></span>
      </div> -->
    </div>
    <div class="search-input__block justify-between align-center" v-if="!editMode">
      <div class="search-input__info">
        <Icon name="tagged-connection-icon" color="#BFD2DF" />
        <p>{{ searchProfileName }}</p>
        <p v-if="secondProfile" class="ml-0">&nbsp;&amp;&nbsp;{{ secondProfileName }}</p>
      </div>
      <button id="edit-connection" class="results-btn" @click="editMode = !editMode">{{ buttonText }}</button>
    </div>
    <div v-else>
      <div class="search-input__block justify-between align-center">
        <input
          v-if="!firstProfile"
          type="text"
          placeholder="Type the subject's name, alias, company, education, or location"
          class="search-input__first-profile w-100 typography-7"
          v-model.trim="firstProfileSearchQuery"
          @keyup="search(0)"
        >
        <div v-else class="search-input__info d-flex align-center">
          <font-awesome-icon :icon="['fab', searchProfileSocialNetIcon]" class="find-connections-form__item-social-icon color-theme"/>
          <img class="find-connections-form__item-avatar" v-if="searchProfileAvatar" :src="searchProfileAvatar">
          <img class="find-connections-form__item-avatar" v-else src="@/assets/icons/avatar.png" alt="">
          <div v-if="searchProfileName" class="mr-8 typography-4">{{ searchProfileName }}</div>
          <div v-if="searchProfileLocation" class="typography-4 terms-color">{{ searchProfileLocation }}</div>
        </div>
        <div v-if="firstProfile" class="d-flex align-center justify-end mr-18">
          <font-awesome-icon :icon="['fas', 'trash']" class="trash-icon" @click="firstProfile = null"/>
        </div>
      </div>
      <div class="find-connections-form__items" v-if="firstProfileSearchResults.length">
        <div class="find-connections-form__counters d-flex align-center flex-wrap">
          <div class="find-connections-form__btn d-flex align-center">Saved in tag ({{ savedInTagCount }})</div>
          <div class="find-connections-form__btn d-flex align-center">Find connections results ({{ searchConnectionsCount }})</div>
          <div class="find-connections-form__btn d-flex align-center">Profile Search results ({{ searchResultsCount }})</div>
        </div>
        <div class="find-connections-form__item d-flex justify-between pl-32" v-for="result in firstProfileSearchResults" :key="result.oid">
          <ResultsSearchProfile :profile="result" @selectProfile="selectProfile(0, result)" />
        </div>
      </div>
      <div class="d-flex align-center mt-12">
        <Icon name="vertical-dots" class="mr-20" />
        <div class="typography-base fw-600 terms-color">and</div>
      </div>
      <div class="search-input__block justify-between align-center mt-10">
        <input
          v-if="!secondProfile"
          type="text"
          placeholder="Type the subject's name, alias, company, education, or location"
          class="search-input__second-profile w-100 typography-7"
          v-model.trim="secondProfileSearchQuery"
          @keyup="search(1)"
        >
        <div v-else class="search-input__info d-flex align-center">
          <font-awesome-icon :icon="['fab', secondProfileSocialNetIcon]" class="find-connections-form__item-social-icon color-theme"/>
          <img class="find-connections-form__item-avatar" v-if="secondProfileAvatar" :src="secondProfileAvatar">
          <img class="find-connections-form__item-avatar" v-else src="@/assets/icons/avatar.png" alt="">
          <div v-if="secondProfileName" class="mr-8 typography-4">{{ secondProfileName }}</div>
          <div v-if="secondProfileLocation" class="typography-4 terms-color">{{ secondProfileLocation }}</div>
        </div>
        <div v-if="secondProfile" class="d-flex align-center justify-end mr-18">
          <font-awesome-icon :icon="['fas', 'trash']" class="trash-icon" @click="secondProfile = null"/>
        </div>
      </div>
      <div class="find-connections-form__items" v-if="secondProfileSearchResults.length">
        <div class="find-connections-form__counters d-flex align-center flex-wrap">
          <div class="find-connections-form__btn d-flex align-center">Saved in tag ({{ savedInTagCount }})</div>
          <div class="find-connections-form__btn d-flex align-center">Find connections results ({{ searchConnectionsCount }})</div>
          <div class="find-connections-form__btn d-flex align-center">Profile Search results ({{ searchResultsCount }})</div>
        </div>
        <div class="find-connections-form__item d-flex justify-between pl-32" v-for="result in secondProfileSearchResults" :key="result.oid">
          <ResultsSearchProfile :profile="result" @selectProfile="selectProfile(1, result)" />
        </div>
      </div>
    </div>
    <div class="d-flex mr-12">
      <button
        id="show-connections"
        class="primary-btn typography-base mt-24 mr-12"
        @click="showResults"
        :disabled="!profilesExist"
        v-if="editMode"
      >
        Show results (30 SLP)
      </button>
      <button id="connections-controller-back" class="opacity-btn typography-base mt-24" @click="backToResults">
        <Icon name="arrow-left-blue" class="mr-14"></Icon>
        Back to profile search
      </button>
    </div>
  </div>
</template>

<script>
import smartlookClient from 'smartlook-client';
import Icon from './app/Icon';
import { getEntityAvatar, getEntityName, getEntitySocialNetIcon, getLocation } from '../utils/helpers';
import ResultsSearchProfile from './ResultsSearchProfile';

export default {
  name: 'FindConnectionsController',
  data () {
    return {
      editMode: false,
      firstProfile: null,
      secondProfile: null,
      firstProfileSearchQuery: '',
      secondProfileSearchQuery: '',
      firstProfileSearchResults: [],
      secondProfileSearchResults: [],
      visibleOIDs: [],
      savedInTagCount: 0,
      searchResultsCount: 0,
      searchConnectionsCount: 0,
      unsubscribe: null
    };
  },
  mounted () {
    this.firstProfile = this.$store.state.connections.from;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setProfileToConnect') {
        this.firstProfile = mutation.payload;
      }
    });
  },
  beforeUnmount () {
    this.unsubscribe();
  },
  computed: {
    searchCompleted () {
      return this.$store.getters.connectionsSearchCompleted;
    },
    profilesExist () {
      return !!this.firstProfile && !!this.secondProfile;
    },
    searchProfileName () {
      if (this.firstProfile) {
        return getEntityName(this.firstProfile);
      }
      return '';
    },
    secondProfileName () {
      if (this.secondProfile) {
        return getEntityName(this.secondProfile);
      }
      return '';
    },
    searchProfileAvatar () {
      if (this.firstProfile) {
        return getEntityAvatar(this.firstProfile);
      }
      return '';
    },
    secondProfileAvatar () {
      if (this.secondProfile) {
        return getEntityAvatar(this.secondProfile);
      }
      return '';
    },
    searchProfileSocialNetIcon () {
      if (this.firstProfile) {
        return getEntitySocialNetIcon(this.firstProfile);
      }
      return '';
    },
    secondProfileSocialNetIcon () {
      if (this.secondProfile) {
        return getEntitySocialNetIcon(this.secondProfile);
      }
      return '';
    },
    searchProfileLocation () {
      if (this.firstProfile) {
        return getLocation(this.firstProfile);
      }
      return '';
    },
    secondProfileLocation () {
      if (this.secondProfile) {
        return getLocation(this.secondProfile);
      }
      return '';
    },
    buttonText () {
      return (this.firstProfile && this.secondProfile) ? 'Change' : 'Find connections between';
    },
    title () {
      return ((this.firstProfile && this.secondProfile) || this.editMode) ? 'Find connections between' : 'Get closest relationships';
    },
    profileType () {
      if (this.firstProfile) {
        return this.firstProfile.typeid;
      }
      if (this.secondProfile) {
        return this.secondProfile.typeid;
      }
      return '';
    }
  },
  methods: {
    async backToResults () {
      if ((this.$route.name === 'Case') || (this.$route.name === 'CasePage')) {
        document.title = this.$store.getters.currentCaseName + ((!this.$store.state.tags.openSavedInTagsPage) ? ' — Profile search results' : ' — Tags');
      }
      this.$store.commit('setFindConnectionEdit', false);
      await this.$store.dispatch('backToResults');
    },
    search (profileNumber) {
      this.firstProfileSearchResults = [];
      this.secondProfileSearchResults = [];
      this.visibleOIDs = [];
      this.savedInTagCount = this.searchResultsCount = this.searchConnectionsCount = 0;

      let searchQuery, searchResults;
      if (profileNumber === 1) {
        searchQuery = this.secondProfileSearchQuery;
        searchResults = this.secondProfileSearchResults;
        this.firstProfileSearchQuery = '';
      } else {
        searchQuery = this.firstProfileSearchQuery;
        searchResults = this.firstProfileSearchResults;
        this.secondProfileSearchQuery = '';
      }

      if (!searchQuery) {
        return;
      }

      const results = this.$store.getters.results;
      const connections = this.$store.getters.sortedConnections;
      const searchFields = [
        'id', 'name', 'alias', 'info', 'affiliation.uid', 'created_at', 'location', 'person.name', 'twitter.id',
        'twitter.screen-name', 'description', 'experience', 'industry', 'occupation', 'lives', 'location_name',
        'username'
      ];

      searchQuery = searchQuery.toLowerCase();

      [results, connections].forEach((source, idx) => {
        const counter = ['searchResultsCount', 'searchConnectionsCount'][idx];
        source.forEach(ent => {
          searchFields.forEach(fld => {
            if (!ent.typeid.match(this.profileType)) {
              return;
            }
            let oid;
            if (this.firstProfile) {
              oid = this.firstProfile.oid;
            } else if (this.secondProfile) {
              oid = this.secondProfile.oid;
            }
            if (oid === ent.oid) {
              return;
            }
            const field = ent.fields[fld];
            if (field && (field.value || '').toLowerCase().includes(searchQuery) && !(this.visibleOIDs.includes(ent.oid))) {
              searchResults.push(ent);
              (ent.tags && ent.tags.length) ? this.savedInTagCount++ : this[counter]++;
              this.visibleOIDs.push(ent.oid);
            }
          });
        });
      });
    },
    selectProfile (profileNumber, profile) {
      document.getElementsByClassName('content')[0].scrollIntoView();
      if (profileNumber === 1) {
        this.secondProfile = profile;
        this.secondProfileSearchResults = [];
        this.secondProfileSearchQuery = '';
      } else {
        this.firstProfile = profile;
        this.firstProfileSearchResults = [];
        this.firstProfileSearchQuery = '';
      }
      this.visibleOIDs = [];
    },
    async showResults () {
      smartlookClient.track('spent_slp', { where: 'find connections between', quantity: 30 });
      this.editMode = false;
      this.$store.commit('removeTimer');
      await this.$store.dispatch('findConnections', [this.firstProfile, this.secondProfile]);
    }
  },
  components: {
    Icon, ResultsSearchProfile
  }
};
</script>

<style scoped>

</style>
