<template>
  <div class="results-filter d-flex justify-between">
    <div class="results-filter__categories">
      <div class="results-filter__row d-flex">
        <div class="results-filter__category">
          Relation tags
        </div>
        <div class="flex-wrap d-flex">
          <div class="results-filter__results d-flex flex-wrap">
            <button
              class="results-filter-btn"
              v-for="(count, relation) in firstRow"
              :key="relation"
              @click="filterResults($event, relation, 'relation')"
            >
              {{ relation }} ({{ count }})
            </button>
            <button
              class="filter-btn-opacity filter-show-more"
              v-if="!showMore && filteredRelationsCount > 4"
              @click="clickShowMore"
            >
              Show more
            </button>
          </div>
          <div class="results-filter__results d-flex flex-wrap" v-show="showMore">
            <button
              class="results-filter-btn"
              v-for="(count, relation) in nextRows"
              :key="relation"
              @click="filterResults($event, relation, 'relation')"
            >
              {{ relation }} ({{ count }})
            </button>
            <button
              class="filter-btn-opacity filter-show-less"
              v-show="showMore && filteredRelationsCount > 4"
              @click="clickShowMore"
            >
              Show less
            </button>
          </div>
        </div>
      </div>
      <div class="results-filter__row d-flex">
        <div id="connection-filter-cancel" class="btn" @click="cancelSelection" v-if="isRelationChecked">
          Cancel selection <Icon name="cancel-btn" />
        </div>
      </div>
    </div>
    <div class="results-filter__select">
      <input
        type="checkbox"
        id="profile-checkbox"
        class="sidebar-checkbox"
        @input="checkAllVisibleConnections($event.target?.checked)"
        :checked="checked"
      >
      <label for="profile-checkbox" class="sidebar-checkbox__label"></label>
      <div class="mt-16 typography-9">Select all</div>
    </div>
  </div>
</template>

<script>
import Icon from './app/Icon';
export default {
  name: 'ConnectionsFilter',
  components: {
    Icon
  },
  data () {
    return {
      relations: {},
      buttons: {
        relation: {}
      },
      counters: {
        relation: {}
      },
      showMore: false
    };
  },
  mounted () {
    this.checkResults();
  },
  computed: {
    checked () {
      const visibleResultsLength = this.visibleResults.length;
      const checkedResultsLength = this.visibleResults.filter(res => res.edge?.checked).length;
      return visibleResultsLength === checkedResultsLength;
    },
    results () {
      return this.$store.state.connections.connections;
    },
    visibleResults () {
      return this.$store.getters.visibleConnections;
    },
    clear () {
      return !this.isRelationChecked;
    },
    isRelationChecked () {
      // eslint-disable-next-line no-unused-vars
      for (const [_, val] of Object.entries(this.buttons.relation)) {
        if (val) { return true; }
      }
      return false;
    },
    filteredRelations () {
      const relations = {};
      Object.keys(this.counters.relation).forEach(relation => {
        if (this.counters.relation[relation]) {
          relations[relation] = this.counters.relation[relation];
        }
      });
      return relations;
    },
    firstRow () {
      const relations = {};
      Object.keys(this.filteredRelations).slice(0, 4).forEach(rel => {
        relations[rel] = this.filteredRelations[rel];
      });
      return relations;
    },
    nextRows () {
      const relations = {};
      Object.keys(this.filteredRelations).forEach(rel => {
        if (!this.firstRow[rel]) {
          relations[rel] = this.filteredRelations[rel];
        }
      });
      return relations;
    },
    filteredRelationsCount () {
      return Object.keys(this.filteredRelations).length;
    }
  },
  watch: {
    clear () {
      this.$store.commit('activateFilter', !this.clear);
    }
  },
  methods: {
    cancelSelection () {
      this.relations = {};
      this.buttons = {
        relation: {}
      };
      this.counters = {
        relation: {}
      };
      this.$store.commit('updateConnectionsVisibility', {
        buttons: this.buttons,
        relationExists: this.isRelationChecked
      });
      this.checkResults();
      this.resetAllButtonsStyle();
    },
    clickShowMore () {
      this.showMore = !this.showMore;
      this.updateButtonStylesByState();
    },
    checkAllVisibleConnections (checked) {
      this.$store.commit('checkAllVisibleConnections', checked);
    },
    updateButtonStates () {
      Object.keys(this.buttons.relation).forEach(rel => {
        if (!this.counters.relation[rel]) {
          this.buttons.relation[rel] = false;
        }
      });
    },
    updateButtonStylesByState () {
      const buttons = document.getElementsByClassName('results-filter-btn');
      for (const btn of buttons) {
        const text = btn.innerText.split('(')[0].trim();
        if (this.buttons.relation[text]) {
          btn.classList.remove('results-filter-btn--active');
          this.updateButtonStyle(btn, text, 'relation');
        }
      }
    },
    updateButtonStyle (selector, value, type) {
      const className = selector.className;
      if (className.includes('active')) {
        selector.classList.remove('results-filter-btn--active');
        this.buttons[type][value] = false;
      } else {
        selector.classList.add('results-filter-btn--active');
        this.buttons[type][value] = true;
      }
    },
    updateCounters (type) {
      const setDefaultRelationCounters = () => {
        Object.keys(this.counters.relation).forEach(relation => {
          this.counters.relation[relation] = this.relations[relation];
        });
      };
      const setVisibleRelationCounters = () => {
        const visibleRelations = this.visibleRelations();
        Object.keys(this.counters.relation).forEach(relation => {
          this.counters.relation[relation] = visibleRelations[relation] || 0;
        });
      };

      if (this.clear) {
        setDefaultRelationCounters();
      } else if (this.isRelationChecked) {
        setVisibleRelationCounters();
      }
    },
    filterResults (event, value, type) {
      this.$store.commit('selectConnectionProfile', null);
      this.$store.commit('removeLastVisibleConnectionOIDs');

      this.updateButtonStyle(event.target, value, type);
      this.$store.commit('updateConnectionsVisibility', {
        buttons: this.buttons,
        relationExists: this.isRelationChecked
      });

      this.updateCounters(type);
      this.updateButtonStates();
      this.$nextTick(() => {
        this.updateButtonStylesByState();
      });
    },
    visibleProfilesCount (entityType) {
      return this.visibleResults.filter(res => res.typeid === entityType).length;
    },
    profilesCount (entityType) {
      return this.results.filter(res => res.typeid === entityType).length;
    },
    visibleRelations () {
      const relations = {};
      this.visibleResults.forEach(res => {
        let connRelations = res.fields && res.fields.connection_types && res.fields.connection_types.value;
        connRelations = (connRelations) ? connRelations.split(',') : ['No info'];
        connRelations.forEach(relation => {
          relations[relation] = (relations[relation] || 0) + 1;
        });
      });
      return relations;
    },
    checkResults () {
      this.results.forEach(res => {
        let relations = res.fields && res.fields.connection_types && res.fields.connection_types.value;
        relations = (relations) ? relations.split(',') : ['No info'];
        relations.forEach(relation => {
          this.relations[relation] = this.counters.relation[relation] = (this.relations[relation] || 0) + 1;
          this.buttons.relation[relation] = false;
        });
      });
    },
    resetAllButtonsStyle () {
      const buttons = document.getElementsByClassName('results-filter-btn');
      for (const btn of buttons) {
        if (btn.classList.contains('results-filter-btn--active')) {
          btn.classList.remove('results-filter-btn--active');
        }
      }
    }
  }
};
</script>

<style scoped>
.btn{
cursor: pointer;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
text-align: right;
letter-spacing: 0.08em;
color: #60BCE3;
}
</style>
