<template>
  <div class="connection-post d-flex flex-column pb-1" style="page-break-inside:avoid">
    <div class="connection-post__header p-24 d-flex justify-between">
      <div>
      <div class="connection-post__activity mt-5" v-if="liked">
        <div class="connection-post__activity-data d-flex align-center">
          <Icon name="like" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} liked {{ sourceProfileName }}'s post
          </div>
            <div class="d-flex justify-between header-date">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
          <div class="header-marker ml-10"><Icon name="location-marker" color="#60BCE3" class="mr-10" />{{postLocation}}</div>
        </div>
      </div>
      <div class="connection-post__activity mt-5" v-if="reacted">
        <div class="connection-post__activity-data d-flex align-center">
          <Icon name="like" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} reacted to {{ sourceProfileName }}'s post
          </div>
            <div class="d-flex justify-between header-date">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
           <div class="header-marker ml-10"><Icon name="location-marker" color="#60BCE3" class="mr-10" />{{postLocation}}</div>
        </div>
      </div>
      <div class="connection-post__activity mt-5" v-if="tagged">
        <div class="connection-post__activity-data d-flex align-center">
          <Icon name="mention" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} tagged with {{ sourceProfileName }}
          </div>
            <div class="d-flex justify-between header-date">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
           <div class="header-marker ml-10"><Icon name="location-marker" color="#60BCE3" class="mr-10" />{{postLocation}}</div>
        </div>
      </div>
      <div class="connection-post__activity mt-5" v-if="repost">
        <div class="connection-post__activity-data d-flex align-center">
          <Icon name="repost" class="mr-16"/>
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} repost {{ sourceProfileName }}'s post
          </div>
            <div class="d-flex justify-between header-date">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
           <div class="header-marker ml-10"><Icon name="location-marker" color="#60BCE3" class="mr-10" />{{postLocation}}</div>
        </div>
      </div>
      <div class="connection-post__activity mt-5" v-if="mentioned">
        <div class="connection-post__activity-data d-flex align-center">
          <Icon name="mention" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ sourceProfileName }} mentioned {{ connectionName }}
          </div>
            <div class="d-flex justify-between header-date">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
           <div class="header-marker ml-10"><Icon name="location-marker" color="#60BCE3" class="mr-10" />{{postLocation}}</div>
        </div>
      </div>
      <div class="connection-post__activity mt-5" v-if="commented">
        <div class="connection-post__activity-data d-flex align-center mb-4">
          <Icon name="comment" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">{{ connectionName }} commented {{ sourceProfileName }}'s post</div>
             <div class="d-flex justify-between typography-9">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
           <div class="header-marker ml-10"><Icon name="location-marker" color="#60BCE3" class="mr-10" />{{postLocation}}</div>
        </div>
      </div>
      </div>
     <button  class="d-flex align-center mb-14" @click="togglePost">
      <span v-if="showPost" class="toggle-btn">Hide</span>
      <span v-if="!showPost" class="toggle-btn">Show post</span>
      </button>
    </div>
    <div class="connection-post__content container" v-if="showPost">
      <img class="connection-post__photo" :src="image" @error="image = ''" v-if="image">
      <div class="connection-post__caption">
        <div class="connection-post__date d-flex justify-between mb-16 greyed">
          <div class="d-flex justify-between typography-9">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
          <div class="connection-post__caption-title typography-caption mb-4 grey-theme">POST DATE</div>
        </div>
        <div class="connection-post__link d-flex justify-between mb-12">
         <div class="connection-post__profile d-flex align-center"><Icon :name="profileSocialIcon" />
         <span class="connection-post__profile-name">{{connectionName}}</span> (<a :href="profileSocialLink">Profile</a>)
         </div>
          <div class="connection-post__caption-title typography-caption color-theme mb-4">SOURCE</div>
        </div>
         <div class="mb-12 source-link"><a :href="url" class="typography-7 color-link" target="_blank">{{ url }}</a></div>
         <div class="reaction-container d-flex mb-16">
           <div v-if="tagged" class="reaction">tagged on photo</div>
           <div v-if="commented" class="reaction">commented</div>
           <span v-if="liked" class="reaction">Liked</span>
        </div>
        <div class="connection-post__text-content d-flex d-flex justify-between" v-if="text">
          <div class="connection-post__text typography-9 w-70" v-html="text" v-linkified ></div>
          <div class="connection-post__caption-title typography-caption mb-4 color-theme">POST TEXT</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './app/Icon';
import { getEntityName } from '../utils/helpers';
import unraw from 'unraw';

export default {
  name: 'ConnectionPostMap',
  props: ['post', 'connection', 'source'],
  emits: ['postRelations'],
  data () {
    return {
      image: '',
      showPost: false
    };
  },
  beforeMount () {
    this.$store.dispatch('getLatLon', this.post);
  },
  mounted () {
    this.$emit('postRelations', {
      postID: this.post.id || this.post.value,
      liked: this.liked,
      reacted: this.reacted,
      commented: this.commented,
      repost: this.repost,
      tagged: this.tagged,
      mentioned: this.mentioned
    });
    if (this.post?.typeid === 'maltego.instagram.photo') {
      this.image = document.location.origin + '/proxy/cors/' + this.post.fields.image.value;
    }
    this.image = this.post.fields.image && this.post.fields.image.value;
  },
  methods: {
    togglePost () {
      this.showPost = !this.showPost;
    },
    parseConnectionPosts (posts, type) {
      if (posts) {
        return JSON.parse(posts);
      }
      return (this.connection && this.connection[type]) || {};
    },
    relationExists (relationType) {
      const postID = this.post.id || this.post.value;
      for (const relation of this.connection?.relations || []) {
        if ((postID.toString() === relation.target) && relation.type === relationType) {
          return true;
        }
      }
      const photo = this.parseConnectionPosts(this.connection?.fields?.connection_photos?.value, 'connection_photos')[postID];
      if (photo && photo[relationType]) {
        return true;
      }
      const relations = this.connection?.fields?.relations?.value;
      if (relations) {
        for (const relation of JSON.parse(relations)) {
          if ((postID.toString() === relation.target) && relation.type === relationType) {
            return true;
          }
        }
      }
      const post = this.parseConnectionPosts(this.connection?.fields?.connection_posts?.value, 'connection_posts')[postID];
      return !!(post && post[relationType]);
    }
  },
  computed: {
    profileSocialIcon () {
      if (this.connection.typeid === 'maltego.instagram.profile') {
        return 'person-card-instagram';
      }
      if (this.connection.typeid === 'maltego.twitter.profile') {
        return 'person-card-twitter';
      }
      if (this.connection.typeid === 'maltego.facebook.profile') {
        return 'person-card-facebook';
      }
      if (this.connection.typeid === 'maltego.linkedin.profile') {
        return 'person-card-linkedin';
      }
      return '';
    },
    profileSocialLink () {
      if (this.connection.fields.url.value) {
        return this.connection.fields.url.value;
      }
      return '';
    },
    postLocation () {
      if (this.post.location) {
        return this.post.location.name;
      }
      return '';
    },
    reverse () {
      return this.post?.reverse || this.post?.fields?.reverse?.value;
    },
    sourceProfileName () {
      const sourceProfile = (this.reverse) ? this.connection : this.source;
      const name = getEntityName(sourceProfile);
      const username = sourceProfile?.fields?.username?.value;
      return name || username;
    },
    connectionName () {
      const connectionProfile = (this.reverse) ? this.source : this.connection;
      const name = getEntityName(connectionProfile);
      const username = connectionProfile?.fields?.username?.value;
      return name || username;
    },
    time () {
      const unixTime = this.post.fields.date.value;
      const date = new Date(unixTime * 1000);
      const hours = date.getHours();
      const minutes = '0' + date.getMinutes();
      return hours + ':' + minutes.substr(-2);
    },
    date () {
      const unixTime = this.post.fields.date.value;
      const date = new Date(unixTime * 1000);
      const day = '0' + date.getDate();
      const month = '0' + (date.getMonth() + 1);
      const year = date.getFullYear();
      return `${day.substr(-2)}.${month.substr(-2)}.${year}`;
    },
    liked () {
      return this.relationExists('like');
    },
    reacted () {
      return this.relationExists('reaction');
    },
    commented () {
      return this.relationExists('comment');
    },
    repost () {
      return this.relationExists('repost');
    },
    tagged () {
      return this.relationExists('with');
    },
    mentioned () {
      return this.relationExists('mention');
    },
    text () {
      const text = this.post.fields.text && this.post.fields.text.value;
      if (text) {
        return unraw(text.replaceAll(/\\n|\\r/g, '<br>'));
      }
      return '';
    },
    url () {
      return this.post.fields.url && this.post.fields.url.value;
    }
  },
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
  .connection-post  {
    padding: 0px;
    box-shadow: 0 0 0 0;
  }
  .connection-post__header {
    width: 100%;
    background-color: #fff;
    margin: 2px 0;
  }
  .connection-post__content {
    margin: 0 auto;
    &.container {
      padding: 0;
      width: 50%;
      margin: 24px auto;
      background: #FFFFFF;
      border: 1px solid #CCDEF2;
      box-sizing: border-box;
      box-shadow: 0px 70px 100px 8px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
    }
  }
  .w-70 {
    width: 70%;
  }
  .p-24 {
    padding: 24px;
  }
  .greyed {
    color: #8D8D8E;
  }
  .grey-theme {
    color: #A0B2BE;
  }
  .reaction {
    background: #EEF7FC;
    border-radius: 5px;
    font-weight: 600;
    padding: 5px;
    margin-right: 5px;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #60BCE3;
  }
  .source-link {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    color: #51AEE2;
  }
  .header-date {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #8D8D8E;
  }
  .typography-base {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    color: #29292B;
    }
  .header-marker {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    color: #29292B;
    }
  .connection-post__profile {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #8D8D8E;
    }
  .connection-post__profile-name {
    margin: 0 5px 0 5px;
  }
  .toggle-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.08em;
    color: #60BCE3;
  }
  .connection-post__caption {
    padding: 16px 10px 0 10px;
  }
  .connection-post__text {
    overflow: scroll;
    height: 75px;
  }
</style>
