<template>
  <aside class="profile-sidebar" :style="styles">
    <div class="sidebar-header">
      <div class="profile-sidebar__head">
        <button class="close-btn" @click="closeSidebar" :disabled="searching"><Icon name="cancel-btn" /></button>
        <img :src="avatar" @load="avatarLoadHandler" @error="avatarErrorHandler" v-if="avatar" class="mb-8 circle" :class="loadImage ? 'd-block' : 'd-none'">
        <img src="@/assets/icons/avatar.png" alt="" class="mb-12" :class="loadImage ? 'd-none' : 'd-block'">
        <h3 class="mb-8">{{name}}</h3>
        <div class="d-flex align-center justify-between">
          <a
            :href="profileUrl"
            class="profile-url d-flex link typography-7 let-space-08"
            target="_blank"
          >
          <span class="icon-size mr-12">
            <Icon :name="socialNetworkIcon" />
          </span>
            Profile
          </a>
          <button class="open-profile-tags link typography-7 let-space-08" @click="showTagsPopup">
            <div class="tag-circle mr-12 icon-size" :style="'background:' + tagInfoColor || '#eff4f7'">
              <Icon name="tag" :color="tagInfoColor ? '#ffffff' : '#60BCE3'" />
            </div>
            {{tagInfoName ? `Saved in ${tagInfoName}` : 'Save in tag'}}
          </button>
        </div>
      </div>
      <div class="profile-sidebar__tabs">
        <button :class="{ active: tabsActivity.profileInfo }" @click="openTab('profileInfo')">Profile info</button>
        <button :class="{ active: tabsActivity.socialGraph }" @click="openTab('socialGraph')">Social graph</button>
        <button :class="{ active: tabsActivity.content }" disabled>Content</button>
        <!-- <button :class="{ active: tabsActivity.monitoring }" class="ml-auto" @click="openMonitoringSidebar">
          Monitoring
        </button>
        <div class="onoffswitch">
          <input
            type="checkbox"
            name="onoffswitch"
            class="onoffswitch-checkbox"
            :id="'profile-switch-' + profile.oid"
            tabindex="0"
            v-model="monitored"
          >
          <label class="onoffswitch-label" :for="'profile-switch-' + profile.oid"></label>
        </div> -->
      </div>
    </div>

    <div v-show="tabsActivity.profileInfo">
      <div class="profile-filters" v-if="userDetailsInfo">
        <button class="profile-filters__all mr-12" @click="showAllTypes" :class="activeAllTypes ? 'color-theme fw-600' : ''">
          All
        </button>
        <button class="profile-filters__general mr-12" :class="generalInfo.length ? 'color-theme fw-600' : ''" @click="showGeneralInfo">
          General info
        </button>
        <template v-if="profile.fields['user-details']">
          <button
            class="profile-filters__details mr-12"
            :class="userDetailsToShow[name] ? 'color-theme fw-600' : ''"
            v-for="(value, name) in typesForButtons"
            :key="name"
            @click="addTypesToShow(name)"
          >
            {{name}}
          </button>
        </template>
      </div>
      <div class="profile-filters" v-else>
        <button class="profile-filters__general mr-12" :class="generalInfo.length ? 'color-theme fw-600' : ''">
          General info
        </button>
      </div>
      <div class="profile-sidebar__body let-space-04" >
        <template v-if="!searching">
          <h4 class="mb-24">{{ tabName }}</h4>
          <div v-if="generalInfo.length">
            <button class="details-block-btn" @click="openGeneralInfo = !openGeneralInfo">
              General information
              <img src="@/assets/icons/arrow.svg" alt="" class="ml-10 svg-index" :class="{ rotated: openGeneralInfo }" />
            </button>
            <ul class="profile-info-list" v-if="openGeneralInfo">
              <li v-for="(row, idx) in generalInfo" :key="row + idx">
                <p v-html="row" v-linkified></p>
              </li>
            </ul>
          </div>
          <div class="user-details-block" v-if="Object.keys(userDetailsToShow).length">
            <div v-for="(type, name) in userDetailsToShow" :key="type">
              <ProfileSidebarInfoGroup :name="name" :type="type" />
            </div>
          </div>
          <div class="slpTooltip">
            <button
              class="primary-btn typography-base w-100 max-w-100 user-details-btn custom-btn mt-24"
              @click="loadUserDetails"
              v-if="!userDetailsInfo"
              :disabled="searching || disableButton || disabledByBrokenLinkedIn">
              Get more info ({{price}} SLP)
            </button>
            <div class="tooltip-text d-flex flex-column" v-if="disableButton">
              <span>There are not enough SLP on your account</span>
              <router-link :to="'/plan'" class="color-theme typography-7">
              Buy more
              </router-link>
            </div>
          </div>
        </template>
        <div class="loading-details" v-else>
          <div class="mb-12">
            <img src="@/assets/icons/searching.gif" alt="" class="h-78">
          </div>
          <span class="typography-3 mb-6">Searching...</span>
          <button class="typography-theme color-link" @click="cancelSearch">Cancel</button>
        </div>
      </div>
    </div>
    <div v-show="tabsActivity.socialGraph">
      <div class="profile-sidebar__body let-space-04">
        <h4 class="mb-16">Social graph</h4>
        <div class="profile-filters mb-24">
          <span class="color-theme mb-4">Enrich info</span>
          <span class="typography-8">You can extract more information on this profile's connections with an additional request.</span>
        </div>
        <div class="slpTooltip">
          <button class="primary-btn typography-base w-100 max-w-100 user-details-btn custom-btn" @click="openConnections" v-if="isLoadConnections">Open connections</button>
          <button class="primary-btn typography-base w-100 max-w-100 user-details-btn custom-btn" @click="findConnections" :disabled="accountState?.balance < 15 || disabledByBrokenLinkedIn" v-else>Explore connections (15 slp)</button>
          <div class="tooltip-text d-flex flex-column" v-if="accountState?.balance < 15">
            <span>There are not enough SLP on your account</span>
            <router-link :to="'/plan'" class="color-theme typography-7">
            Buy more
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </aside>
  <div class="searching-background" v-if="searching"></div>
</template>

<script>
import Icon from './Icon';
import ProfileSidebarInfoGroup from './ProfileSidebarInfoGroup';
import userDetailsMixin from '../../mixins/userDetailsMixin';
import { mapGetters } from 'vuex';
import smartlookClient from 'smartlook-client';
import amplitude from 'amplitude-js';

export default {
  name: 'ProfileSidebar',
  components: {
    Icon, ProfileSidebarInfoGroup
  },
  mixins: [userDetailsMixin],
  data () {
    return {
      userDetailsToShow: {},
      generalInfo: [],
      loadImage: false,
      searching: false,
      tabsActivity: {
        profileInfo: true,
        socialGraph: false,
        content: false,
        monitoring: false
      },
      openGeneralInfo: true,
      monitored: false
    };
  },
  created () {
    if (this.userDetailsInfo) {
      this.showAllTypes();
    }
    this.generalInfo = this.bio;
  },
  props: ['profile', 'styles', 'tagInfo'],
  emits: ['closeProfileSidebar', 'showTagsPopup', 'openMonitoringSidebar', 'closeAllProfileSidebars'],
  computed: {
    ...mapGetters(['accountState', 'sortedRelationSearches']),
    disableButton () {
      if (this.accountState.balance < this.price) {
        return true;
      }
      return false;
    },
    disabledByBrokenLinkedIn () {
      if (this.profile.typeid === 'maltego.linkedin.profile' && !this.profile.value) {
        return true;
      }
      return false;
    },
    tabName () {
      if (this.tabsActivity.profileInfo) return 'Profile info';
      if (this.tabsActivity.socialGraph) return 'Social graph';
      if (this.tabsActivity.content) return 'Content';
      return '';
    },
    tagInfoColor () {
      return !this.tagInfo ? '#fff' : this.tagInfo.color;
    },
    tagInfoName () {
      return !this.tagInfo ? '' : this.tagInfo.name;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    socialNetworkIcon () {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (this.profile.typeid.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    },
    userDetailsInfo () {
      return this.profile.fields['user-details'];
    },
    typesForButtons () {
      return JSON.parse(this.userDetailsInfo.value);
    },
    activeAllTypes () {
      if (this.userDetailsInfo && this.userDetailsInfo.value) {
        const parseDetails = JSON.parse(this.userDetailsInfo.value);
        return (Object.keys(parseDetails).length === Object.keys(this.userDetailsToShow).length) && this.generalInfo.length;
      } else return false;
    },
    price () {
      let index;
      if (this.profile.typeid === 'maltego.facebook.profile') {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/facebook/user/v4';
        });
      } else if (this.profile.typeid === 'maltego.affiliation.Twitter') {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/twitter/user';
        });
      } else if (this.profile.typeid === 'maltego.instagram.profile') {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/instagram/user';
        });
      } else {
        index = this.accountState.transforms.findIndex(transform => {
          return transform.name === '/linkedin/user';
        });
      }
      return this.accountState.transforms[index].price;
    },
    isLoadConnections () {
      let isLoaded = false;
      for (let i = 0; i < this.sortedRelationSearches.length; i++) {
        if (this.sortedRelationSearches[i].targets.length > 1) continue;
        if (this.sortedRelationSearches[i].targets[0] === this.profile.oid) {
          isLoaded = this.sortedRelationSearches[i];
          break;
        }
      }
      return isLoaded;
    }
  },
  methods: {
    openMonitoringSidebar () {
      this.$emit('openMonitoringSidebar');
    },
    avatarLoadHandler () {
      this.loadImage = true;
    },
    avatarErrorHandler () {
      this.loadImage = false;
    },
    showTagsPopup () {
      const event = 'save_tag';
      const eventProperties = {
        place: 'profileSidebar',
        soc_net: this.profile.typeid.replace('maltego.', '').replace('.profile', '').replace('affiliation.', '').toLowerCase()
      };
      const userProperties = {
        subscription: this.accountState.subscription.name,
        company: this.accountState.company,
        role: this.accountState.role
      };
      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);

      this.$emit('showTagsPopup');
    },
    async findConnections () {
      const event = 'prof_details_conn';
      const eventProperties = {
        place: 'profileSidebar',
        soc_net: this.profile.typeid.replace('maltego.', '').replace('.profile', '').replace('affiliation.').toLowerCase()
      };
      const userProperties = {
        subscription: this.accountState.subscription.name,
        company: this.accountState.company,
        role: this.accountState.role
      };

      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);

      smartlookClient.track('spent_slp', { where: 'find connections (profile sidebar)', quantity: 15 });
      this.$store.commit('selectProfile', null);
      this.$store.commit('removeLastVisibleOIDs');
      if (this.$store.state.connections.opened) {
        this.closeAllSidebars();
      } else {
        this.closeSidebar();
      }
      await this.$store.dispatch('findConnections', [this.profile]);
    },
    addTypesToShow (type) {
      const parseDetails = JSON.parse(this.userDetailsInfo.value);
      if (!this.userDetailsToShow[type]) {
        this.userDetailsToShow[type] = parseDetails[type];
      } else {
        delete this.userDetailsToShow[type];
      }
    },
    showAllTypes () {
      if (!this.userDetailsInfo) return;
      const parseDetails = JSON.parse(this.userDetailsInfo.value);
      if (Object.keys(parseDetails).length === Object.keys(this.userDetailsToShow).length) {
        this.userDetailsToShow = {};
        this.generalInfo = [];
      } else {
        this.userDetailsToShow = parseDetails;
        this.generalInfo = this.bio;
      }
    },
    getProfileFieldValue (fieldName) {
      try {
        return this.profile.fields[fieldName].value.split('###');
      } catch (e) {
        return '';
      }
    },
    closeSidebar () {
      this.$emit('closeProfileSidebar');
    },
    closeAllSidebars () {
      this.$emit('closeAllProfileSidebars');
    },
    showGeneralInfo () {
      if (this.generalInfo.length) {
        this.generalInfo = [];
      } else {
        this.generalInfo = this.bio;
      }
    },
    async loadUserDetails () {
      const event = 'prof_details';
      const eventProperties = {
        place: 'profileSidebar',
        soc_net: this.profile.typeid.replace('maltego.', '').replace('.profile', '').replace('affiliation.').toLowerCase()
      };
      const userProperties = {
        subscription: this.accountState.subscription.name,
        company: this.accountState.company,
        role: this.accountState.role
      };
      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);

      this.$store.commit('setSearchingUserDetails', true);
      this.searching = true;
      await this.$store.dispatch('getUserDetails', this.profile);
      smartlookClient.track('spent_slp', { where: 'profile details', quantity: 5 });
      this.showAllTypes();
      this.$store.commit('setSearchingUserDetails', false);
      this.searching = false;
    },
    cancelSearch () {
      this.$store.state.userDetails.detailsCancelToken.cancel();
      this.$store.commit('setSearchingUserDetails', false);
      this.searching = false;
    },
    openTab (type) {
      Object.keys(this.tabsActivity).forEach(key => {
        this.tabsActivity[key] = false;
      });
      this.tabsActivity[type] = true;
    },
    async openConnections () {
      if (this.$store.state.connections.opened) {
        this.closeAllSidebars();
      } else {
        this.closeSidebar();
      }
      this.$store.commit('activateRelationsSection', true);
      await this.$store.dispatch('openConnections', this.isLoadConnections);
      this.$nextTick(async () => { await this.$store.dispatch('stopSearching', true); });
    }
  }
};
</script>

<style lang="scss" scoped>
  aside {
    position: fixed;
    right: 0;
    top: 80px;
    min-height: calc(100vh - 80px);
    bottom: 0;
    width: 470px;
    background-color: #FFFFFF;
    overflow: auto;
    z-index: 1002;
    box-shadow: 0px 70px 100px 8px rgba(0, 0, 0, 0.05);
    visibility: hidden;
    transform: translateX(100%);
    transition: 0.3s;
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #29292B;
  }

  .profile-sidebar--show {
    visibility: visible;
    transform: translateX(0);
    transition: 0.3s;
  }

  .profile-sidebar--first {
    visibility: visible;
    transform: translateX(-470px);
    transition: 0.3s;
  }

  .sidebar-header {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .profile-sidebar__head {
    img {
      height: 64px;
      width: 64px;
    }
  }

  .profile-sidebar__tabs {
    height: 46px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0 24px;
    border-bottom: 2px solid #EFF4F7;
    & button {
      color: #8D8D8E;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.04em;
      height: 100%;
      padding-top: 3px;
      margin-right: 16px;
      &.active {
        border-bottom: 2px solid #60BCE3;
        margin-bottom: -3px;
        color: #60BCE3;
        font-weight: 600;
      }
      &:disabled {
        color: #A0B2BE;
      }
    }
  }

  .icon-size {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tag-size {
    height: 12px;
    width: 12px;
  }

  .profile-sidebar__body {
    .results-filter-btn {
      margin-bottom: 12px;
      margin-right: 8px;
    }
  }

  .circle {
    object-fit: cover;
    border-radius: 50%;
  }

  .loading-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 170px;
  }

  .searching-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: #eaeff1;
    opacity: 0.5;
  }

  .interests-chips {
    background: rgba(180, 214, 255, 0.31);
    color: #5692EC;
    font-weight: 400;
    line-height: 19px;
  }

  .white-back {
    background-color: #FFFFFF;
  }

  .tag-circle {
    background-color: #f8fcff;
    svg {
      width: 14px;
      height: 14px;
    }
  }

  .slpTooltip {
    position: relative;
  }
  .custom-btn:hover + .tooltip-text{
    visibility: visible;
    opacity: 1;
  }
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s all cubic-bezier(0.83, 0, 0.17, 1);
    position: absolute;
    padding: 16px;
    top: -125px;
    right: 0px;
    bottom: auto;
    width: 275px;
    height: 108px;
    background-color: #eff4f7;
    border-radius: 16px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #8D8D8E;
    &:hover {
      visibility: visible;
      opacity: 1;
    }
    &:after {
       content: '';
       background:  url('../../assets/icons/tooltip_arrow.svg') no-repeat;
       background-position: center;
       width: 100%;
       height: 20px;
       top: 104px;
       left: -5px;
       position: absolute;
    }
    &:before {
       content: '';
    }
  }

     .slpTooltip {
    position: relative;
  }
  .custom-btn:hover + .tooltip-text{
    visibility: visible;
    opacity: 1;
  }
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity .3s linear;
    position: absolute;
    padding: 16px;
    top: -125px;
    right: 0px;
    bottom: auto;
    width: 275px;
    height: 108px;
    background-color: #eff4f7;
    border-radius: 16px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #8D8D8E;
    &:hover {
      visibility: visible;
      opacity: 1;
    }
    &:after {
       content: '';
       background:  url('../../assets/icons/tooltip_arrow.svg') no-repeat;
       background-position: center;
       width: 100%;
       height: 20px;
       top: 104px;
       left: -5px;
       position: absolute;
    }
    &:before {
       content: '';
    }
  }
</style>
