<template>
  <Map :entities="profiles" type="results"></Map>
  <div class="map-profiles">
    <div v-for="country in countries" :key="country" class="pt-16">
      <MapViewAccordion :country="country" :profiles="profiles" @openProfileSidebar="openProfileSidebar" />
    </div>
  </div>
</template>

<script>
import Map from './Map';
import MapViewAccordion from './MapViewAccordion';
import { eventListener } from '../../utils/helpers';

export default {
  name: 'MapView',
  emits: ['openProfileSidebar'],
  computed: {
    visibleResults () {
      return this.$store.getters.visibleResults;
    },
    profiles () {
      return this.visibleResults.filter(ent => ent.lat && ent.lon);
    },
    countries () {
      return [...new Set(this.profiles.map(profile => profile.country))];
    }
  },
  methods: {
    openProfileSidebar (profile) {
      eventListener.emit('openProfileSidebar', profile);
    }
  },
  components: {
    Map, MapViewAccordion
  }
};
</script>

<style scoped>
  .map-profiles {
    background-color: #F8FCFF;
    padding: 24px;
    padding-top: 0;
  }
</style>
