<template>
  <FindConnectionsController v-if="visibleConnections.length" />
  <EmptyConnectionsController v-else />
  <div v-if="!isConnectionsBetween">
    <h3 class="profiles-count-header" v-if="visibleConnections.length">
      {{ visibleConnections.length }} profiles are connected with {{ searchProfileName }}
    </h3>
    <ConnectionsSearch :key="connections.length" v-if="!$store.getters.getSearching && visibleConnections.length" />
    <ConnectionsFilter :key="connectionsFilterID" v-if="!$store.getters.getSearching && visibleConnections.length" />
    <div class="connections" v-for="connection in connectionsToShow" :key="connection.oid">
      <Connection :connection="connection" :source="from" @openSidebar="openSidebar" />
    </div>
    <div ref="end-of-connections"></div>
  </div>
  <div v-else>
    <div v-if="mergedConnectionsBetween.length">
      <div class="connections" v-for="connection in mergedConnectionsBetween" :key="connection.oid">
        <Connection :connection="connection" :source="connection.parent" @openSidebar="openSidebar" />
      </div>
    </div>
    <div v-else class="search-input">
      <h3>Sorry, but we didn't find anything, please try to change the input data</h3>
    </div>
  </div>
  <Popup :profiles="checkedConnections" />
  <ProfileSidebar v-if="openMainProfile" :profile="from" @closeProfileSidebar="closeProfileSidebar(0)" :tagInfo="savedTagInfoLeft" @showTagsPopup="showTagsPopup(2)" @closeAllProfileSidebars="closeAllProfileSidebars" />
  <div class="popup-container right" v-if="sidebarTagPopupOpenRight && connectedProfile">
    <TagPopup  :profiles="[connectedProfile]" @closeTagsPopup="sidebarTagPopupOpenRight = false" @showSavedTag="showSavedTag" :class="{fixed: sidebarTagPopupOpenRight}"/>
  </div>
  <ProfileSidebar v-if="sidebarProfile" :profile="sidebarProfile" @closeProfileSidebar="closeProfileSidebar(1)" :tagInfo="savedTagInfoRight" @showTagsPopup="showTagsPopup(1)" @closeAllProfileSidebars="closeAllProfileSidebars" />
  <div class="popup-container left"  v-if="sidebarTagPopupOpenLeft" :class="{shifted: shifted && !sidebarProfile }">
    <TagPopup :profiles="[from]" @closeTagsPopup="sidebarTagPopupOpenLeft = false" @showSavedTag="showSavedTag" :class="{fixed: sidebarTagPopupOpenLeft}"/>
  </div>
  <div class="aside-backwall" v-if="openMainProfile || sidebarProfile"></div>
</template>

<script>
import FindConnectionsController from './FindConnectionsController';
import EmptyConnectionsController from './EmptyConnectionsController';
import { getEntityName } from '../utils/helpers';
import Connection from './Connection';
import Popup from './app/Popup';
import ConnectionsFilter from './ConnectionsFilter';
import ConnectionsSearch from './ConnectionsSearch';
import ProfileSidebar from './app/ProfileSidebar';
import TagPopup from './TagPopup.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MainConnections',
  data () {
    return {
      loadingCount: 10,
      connectionsBetween: [],
      loadedCount: 0,
      sidebarProfile: null,
      connectionsFilterID: null,
      sidebarTagPopupOpenRight: false,
      sidebarTagPopupOpenLeft: false,
      connectedProfile: {},
      shifted: false,
      savedTagInfoLeft: {
        name: '',
        color: ''
      },
      savedTagInfoRight: {
        name: '',
        color: ''
      },
      openMainProfile: false
    };
  },
  mounted () {
    this.connectionsFilterID = this._id;
    this.loadedCount += this.loadingCount;
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll();
    });

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'addConnectionBetween') {
        this.connectionsBetween.push(mutation.payload);
      } else if (mutation.type === 'clearConnectionsBetween') {
        this.connectionsBetween = [];
      }
    });
  },
  beforeUnmount () {
    this.$store.commit('checkAllConnections', false);
    this.$store.commit('removeTimer');
    this.$store.state.connections.connectionsCancelToken.cancel();
    window.removeEventListener('scroll', this.onScroll);
    this.unsubscribe();
  },
  computed: {
    ...mapGetters(['connections']),
    mergedConnectionsBetween () {
      if (this.$store.getters.mergedConnectionsBetween.length) {
        return this.$store.getters.mergedConnectionsBetween;
      }
      if (!this.connectionsBetween.length) {
        return [];
      }
      if (this.connectionsBetween.length === 1) {
        this.$store.commit('addMergedConnectionsBetween', this.connectionsBetween);
        return this.$store.getters.mergedConnectionsBetween;
      }
      const connection1 = this.connectionsBetween[0];
      const connection2 = this.connectionsBetween[1];
      const connection = JSON.parse(JSON.stringify(connection1));

      const score = Math.max(connection1.score, connection2.score);
      connection.score = score;
      connection.fields.score = { name: 'score', value: score.toString() };

      const connectionTypes1 = connection1.fields.connection_types.value.split(',');
      const connectionTypes2 = connection2.fields.connection_types.value.split(',');
      const connectionTypes = connectionTypes1;
      connectionTypes2.forEach(type => {
        if (!connectionTypes.includes(type)) {
          connectionTypes.push(type);
        }
      });
      connection.fields.connection_types = { name: 'connection_types', value: connectionTypes.toString() };

      const posts = { ...connection2.posts };
      Object.keys(posts).forEach(postID => { posts[postID].reverse = true; });
      connection.posts = { ...connection1.posts, ...posts };

      const likedPostsCount = +connection1.fields.liked_count.value + (+connection2.fields.liked_count.value);
      const taggedPostsCount = +connection1.fields.tagged_count.value + (+connection2.fields.tagged_count.value);
      const commentedPostsCount = +connection1.fields.commented_count.value + (+connection2.fields.commented_count.value);
      const reactedPostsCount = +connection1.fields.reacted_count.value + (+connection2.fields.reacted_count.value);
      const repostPostsCount = +connection1.fields.repost_count.value + (+connection2.fields.repost_count.value);
      const mentionedPostsCount = +connection1.fields.mentioned_count.value + (+connection2.fields.mentioned_count.value);
      connection.fields.liked_count.value = likedPostsCount.toString();
      connection.fields.tagged_count.value = taggedPostsCount.toString();
      connection.fields.commented_count.value = commentedPostsCount.toString();
      connection.fields.reacted_count.value = reactedPostsCount.toString();
      connection.fields.repost_count.value = repostPostsCount.toString();
      connection.fields.mentioned_count.value = mentionedPostsCount.toString();

      if (connection1.relations && connection2.relations) {
        const relations1 = [...connection1.relations];
        const relations2 = [...connection2.relations];
        connection.relations = relations1.concat(relations2);
      }

      if (connection1.connection_photos && connection2.connection_photos) {
        connection.connection_photos = { ...connection1.connection_photos, ...connection2.connection_photos };
      }

      if (connection1.connection_posts && connection2.connection_posts) {
        connection.connection_photos = { ...connection1.connection_posts, ...connection2.connection_posts };
      }

      this.$store.commit('addMergedConnectionsBetween', [connection]);
      return this.$store.getters.mergedConnectionsBetween;
    },
    checkedConnections () {
      const mergedConnectionsBetween = this.mergedConnectionsBetween.filter(conn => { return conn.checked === true; });
      const results = this.$store.getters.results.filter(res => { return res.checked === true; });
      const edges = this.$store.getters.edges.filter(edge => { return edge.checked === true; });
      const connectionProfiles = this.sortedConnections.filter(conn => { return conn.checked === true; });
      return [...mergedConnectionsBetween, ...results, ...edges, ...connectionProfiles];
    },
    connections () {
      return this.$store.state.connections.connections;
    },
    isConnectionsBetween () {
      return this.$store.state.connections.between;
    },
    sortedConnections () {
      return this.$store.getters.sortedConnections;
    },
    visibleConnections () {
      return this.$store.getters.visibleConnections;
    },
    connectionsToShow () {
      const visibleConnections = this.sortedConnections.filter(conn => conn.visible);
      return visibleConnections.filter((conn, idx) => {
        return idx <= this.loadedCount;
      });
    },
    from () {
      return this.$store.state.connections.from;
    },
    searchProfileName () {
      return getEntityName(this.from);
    }
  },
  methods: {
    showSavedTag (obj) {
      if (this.sidebarTagPopupOpenLeft) {
        this.savedTagInfoLeft.name = obj.name;
        this.savedTagInfoLeft.color = obj.color;
      }
      if (this.sidebarTagPopupOpenRight) {
        this.savedTagInfoRight.name = obj.name;
        this.savedTagInfoRight.color = obj.color;
      }
    },
    showTagsPopup (value) {
      if (value === 1) {
        this.sidebarTagPopupOpenRight = true;
      }
      if (value === 2) {
        this.sidebarTagPopupOpenLeft = true;
      }
      const result = {};
      for (const key in this.sidebarProfile) {
        if (Object.prototype.hasOwnProperty.call(this.sidebarProfile, key)) {
          if (key.toLowerCase().trim().indexOf('parent') !== -1) continue;
          result[key] = this.sidebarProfile[key];
        }
      }
      this.connectedProfile = result;
    },
    closeProfileSidebar (idx) {
      if (idx === 0) {
        this.sidebarTagPopupOpenLeft = false;
        this.savedTagInfoLeft.name = '';
        this.savedTagInfoLeft.color = '';
        this.openMainProfile = false;
      }
      if (idx === 1) {
        this.sidebarTagPopupOpenRight = false;
        this.sidebarProfile = null;
        this.shifted = true;
        this.savedTagInfoRight.name = '';
        this.savedTagInfoRight.color = '';
      }
      const anySidebar = document.getElementsByClassName('profile-sidebar')[idx];
      if (anySidebar) {
        anySidebar.classList.remove('profile-sidebar--show');
      }
      const firstSidebar = document.querySelector('.profile-sidebar--first');
      if (firstSidebar) {
        firstSidebar.classList.remove('profile-sidebar--first');
      }
      if (idx) {
        this.sidebarProfile = null;
      }
    },
    closeAllProfileSidebars () {
      this.closeProfileSidebar(0);
      this.closeProfileSidebar(1);
    },
    openSidebar (connectionProfile) {
      this.sidebarProfile = connectionProfile.connection;
      this.openMainProfile = true;
      setTimeout(() => {
        Array.from(document.getElementsByClassName('profile-sidebar')).forEach((el, index) => {
          if (index === 0) {
            el.classList.add('profile-sidebar--first');
          }
          el.classList.add('profile-sidebar--show');
        });
      }, 300);
    },
    onScroll () {
      const end = this.$refs['end-of-connections'];
      if (end) {
        const marginTop = end.getBoundingClientRect().top;
        const innerHeight = window.innerHeight;
        if (marginTop <= innerHeight) {
          this.loadedCount += this.loadingCount;
        }
      }
    }
  },
  components: {
    ConnectionsSearch,
    ConnectionsFilter,
    Connection,
    FindConnectionsController,
    EmptyConnectionsController,
    Popup,
    ProfileSidebar,
    TagPopup
  }
};
</script>

<style scoped>
  .popup-container {
    position: fixed;
    top: 144px;
    z-index: 2001;
    height: 78px;
    width: 300px;
  }
  .left {
    right: 383px;
  }
  .right {
    right: 60px;
  }
  .shifted {
    right: 60px !important;
    transition: .4s;
  }
</style>
