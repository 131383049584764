<template>
  <div v-if="visibleResults.length">
    <div class="search-input" v-if="hideSearch">
      <h3>Search input</h3>
      <div class="search-input__block justify-between align-center">
        <div class="search-input__queries">
          <Icon name="unknown-profile" class="mr-12" />
          <template v-for="(query, name) in searchQueries">
            <template v-if="query">{{name[0].toUpperCase() + name.replace('_', ' ').substring(1)}}: {{getReadableSearchQuery(name, query)}} &nbsp;</template>
          </template>
        </div>
        <button class="results-btn" id="change-search-form" @click="showSearchForm">Change</button>
      </div>
    </div>
    <SearchForm :caseName="hideSearch" :searchQueries="searchQueries" v-else>
      <slot>
        <div class="d-flex align-center justify-between mb-16">
          <h3>Search input</h3>
          <button id="hide-search-form" type="button" class="results-btn p-0 m-0 h-auto" @click="showSearchForm">
            Hide
          </button>
        </div>
      </slot>
    </SearchForm>
    <div class="d-flex justify-between align-center">
      <h3 class="profiles-count-header">{{ onOverview || foundLocations ? resultsLength + ' results' : '' }}</h3>
      <div class="view-controller d-flex align-center justify-between" v-if="this.visibleResults.filter(ent => ent.lat && ent.lon).length">
        <button id="overview-button" :class="{ active: onOverview }" @click="openView('#overview-button')">
          <Icon name="window" />
          Show on overview
        </button>
        <button id="mapview-button" :class="{ active: !onOverview }" @click="openView('#mapview-button')">
          <Icon name="location-marker" />
          Show on map
        </button>
      </div>
    </div>
    <div v-if="onOverview">
      <template v-if="visibleResults && visibleResults.length">
        <ResultsSearch :key="results.length" />
        <ResultsFilter :key="results.length" />
      </template>
      <div v-if="searchTypes.length">
        <MainResultsSearchGroup
          v-for="searchType of searchTypes"
          :key="searchType"
          :search-type="searchType"
          :entities="visibleResults.filter(ent => ent.fields['search-type'].value === searchType)"
        />
      </div>
      <div v-else>
        <div class="profiles d-flex flex-wrap mx-n12">
          <div class="profile-card-wrap" v-for="(entity, idx) in visibleResults" :key="entity.value + idx">
            <ResultsEntity :entity="entity" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-32">
      <div v-if="!foundLocations" id="mapview-loader">
        <img src="@/assets/icons/searching.gif" alt="" class="h-78 mb-12">
        <span class="typography-3 color-2">Loading...</span>
      </div>
      <div v-else><MapView /></div>
    </div>
  </div>
  <SearchForm :case-name="false" v-else>
    <slot>
      <div class="empty-results-header" v-if="showEmptyResultsHeader">
        <div>
          <p>Sorry, but we didn't find anything</p>
          <p>Please try changing the input data</p>
        </div>
        <button type="button" class="empty-results-header__btn" @click="showEmptyResultsHeader = false">
          <Icon name="cancel-btn" />
        </button>
      </div>
    </slot>
  </SearchForm>
</template>

<script>
import Icon from '../components/app/Icon';
import ResultsFilter from '../components/ResultsFilter';
import SearchForm from '../components/app/SearchForm.vue';
import ResultsSearch from '../components/ResultsSearch';
import MapView from './map/MapView';
import MainResultsSearchGroup from './MainResultsSearchGroup';
import ResultsEntity from './ResultsEntity';
import { mapGetters, mapState } from 'vuex';
import { eventListener, getReadableSearchQuery } from '../utils/helpers';

export default {
  name: 'MainResults',
  data () {
    return {
      showEmptyResultsHeader: true,
      sortedSearchTypes: ['phone', 'email', 'name,photo', 'name,education', 'name,company', 'name,location', 'alias', 'name']
    };
  },
  mounted () {
    this.$store.dispatch('removeZeroResultsUrlParameter');
    if (!this.visibleResults.length) {
      this.$store.dispatch('addZeroResultsUrlParameter');
    }
  },
  methods: {
    openView (buttonID) {
      const btn = document.querySelector(buttonID);
      if (!btn || btn.className.includes('active')) return;
      if (this.onOverview) {
        eventListener.emit('clearFilter');
        if (this.$store.state.results.foundLocations) {
          this.$store.commit('foundLocations', false);
          setTimeout(() => {
            this.$store.commit('foundLocations', true);
          }, 500);
        }
      }
      this.$store.commit('toggleViewController');
    },
    showSearchForm () {
      this.$store.commit('hideSearch', !this.hideSearch);
    },
    getReadableSearchQuery (queryName, query) {
      return getReadableSearchQuery(queryName, query);
    }
  },
  computed: {
    ...mapGetters(['openSearch']),
    ...mapState(['hideSearch']),
    foundLocations () {
      return this.$store.state.results.foundLocations;
    },
    onOverview () {
      return this.$store.state.results.onOverview;
    },
    resultsLength () {
      return (this.onOverview) ? this.visibleResults.length : this.visibleResults.filter(ent => ent.lat && ent.lon).length;
    },
    results () {
      return this.$store.getters.results;
    },
    visibleResults () {
      return this.$store.getters.visibleResults;
    },
    checkedResults () {
      const results = [];
      const addCheckedEntities = (array) => {
        array.forEach(ent => {
          if (ent.checked) {
            results.push(ent);
          }
        });
      };
      addCheckedEntities(this.$store.getters.results);
      this.$store.getters.results.forEach(res => { addCheckedEntities(res.children || []); });
      addCheckedEntities(this.$store.getters.sortedConnections);
      addCheckedEntities(this.$store.getters.edges);
      return results;
    },
    searchQueries () {
      return this.openSearch && this.openSearch.query;
    },
    searchTypes () {
      const searchTypes = [];
      this.visibleResults.forEach(ent => {
        const searchType = ent.fields['search-type'] && ent.fields['search-type'].value;
        if (searchType) searchTypes.push(searchType);
      });
      const uniqueAndSortedSearchTypes = [];
      for (const searchType of this.sortedSearchTypes) {
        if (searchTypes.includes(searchType)) uniqueAndSortedSearchTypes.push(searchType);
      }
      return uniqueAndSortedSearchTypes;
    }
  },
  components: {
    ResultsFilter,
    Icon,
    ResultsEntity,
    SearchForm,
    ResultsSearch,
    MapView,
    MainResultsSearchGroup
  }
};
</script>

<style lang="scss">
  .view-controller {
    padding: 4px;
    width: 401px;
    height: 48px;
    background: #F8FCFF;
    border-radius: 6px;
    & button {
      color: #60BCE3;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      justify-content: center;
      padding: 8px 24px;
      border-radius: 5px;
      & svg {
        margin-right: 14px;
      }
      &:hover {
        background-color: #60BCE3;
        color: #ffffff;
      }
      &:hover svg path {
        fill: #ffffff
      }
      &.active {
        background-color: #60BCE3;
        color: #ffffff;
        & svg path {
          fill: #ffffff
        }
      }
      &:disabled:hover {
        background-color: #F8FCFF;
        color: #60BCE3;
      }
      &:disabled:hover svg path {
        fill: #60BCE3
      }
    }
  }
  #mapview-loader {
    height: 400px;
    background-color: #F8FCFF;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .popup-container {
    position: fixed;
    top: 40px;
    right: 10px;
    left: auto;
    bottom: 369px;
    z-index: 2001;
    height: 136px;
    width: 300px;
  }
  .empty-results-header {
    background: #F8FCFF;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -16px;
    & p {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
      &:first-child {
        font-weight: 600;
        color: #29292B;
      }
      &:last-child {
        font-weight: normal;
        color: #A0B2BE;
      }
    }
  }
</style>
