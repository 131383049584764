<template>
  <div class="modal-background"></div>
  <div class="modal-wrap">
    <div class="modal d-flex flex-column align-center" v-if="!searching">
      <h2 class="mb-16">Information will be incomplete</h2>
      <p class="mb-16 align-center">You can get more information about the profile saved in the tag</p>
      <div class="d-flex justify-center w-100 slpTooltip">
        <button id="save-anyway" class="opacity-btn mr-12 mt-10 typography-8" @click="saveAnyWay">Save it anyway</button>
        <button id="get-more-info" class="primary-btn mr-12 mt-10 typography-8 custom-btn"
        @click="loadUserDetails"
        :disabled="disableButton"
        @mouseover="showTooltip = true"
        @mouseleave="showTooltip = false">Get more info ({{ slp * profilesToEnrichment.length }} SLP)</button>
        <span class="tooltip-text d-flex flex-column" v-if="disableButton">
          <span>There are not enough SLP on your account</span>
          <router-link :to="'/plan'" @click="turnOffPopup" class="color-theme typography-7">
          Buy more
          </router-link>
        </span>
      </div>
    </div>
    <div class="modal loading-details" v-else>
      <div class="mb-12">
        <img src="@/assets/icons/searching.gif" alt="" class="h-78">
      </div>
      <div class="d-flex">
        <span class="typography-theme mr-4">Loading...</span>
        <button class="typography-theme color-link" id="cancel-search" @click="cancelSearch">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { eventListener } from '../../utils/helpers';
import amplitude from 'amplitude-js';

export default {
  name: 'GetInfoPopup',
  props: ['profile'],
  data () {
    return {
      searching: false,
      detailsCancelToken: axios.CancelToken.source(),
      userDetailsToShow: {},
      generalInfo: [],
      slp: 1,
      profilesInConnection: [],
      canceled: false,
      showTooltip: false
    };
  },
  computed: {
    ...mapGetters(['profilesToEnrichment', 'takenTagName', 'accountState']),
    userDetailsInfo () {
      return this.profile[0].fields['user-details'];
    },
    disableButton () {
      if (this.accountState.balance < this.profilesToEnrichment.length * this.slp) {
        return true;
      }
      return false;
    },
    tags () {
      return this.$store.getters.getTags;
    }
  },
  methods: {
    turnOffPopup () {
      this.$store.commit('openInfoModal', false);
      this.searching = false;
    },
    saveAnyWay () {
      this.amplitudeTracking('save_tag', true);

      eventListener.emit('saveProfileInTag', this.takenTagName);
      this.$store.commit('openInfoModal', false);
    },
    async loadUserDetails () {
      this.amplitudeTracking('prof_details');

      this.canceled = false;
      this.checkRelations();
      if (this.profilesToEnrichment.some(i => i.parent_id)) {
        this.searching = true;
        await this.profileTreatment(this.profilesInConnection);
      } else {
        this.searching = true;
        await this.profileTreatment(this.profilesToEnrichment);
      }
    },
    amplitudeTracking (eventName, saveAnyway = false) {
      const event = eventName;
      const eventProperties = {
        soc_net: []
      };
      if (saveAnyway) eventProperties.saveAnyway = true;
      const userProperties = {
        subscription: this.accountState.subscription.name,
        company: this.accountState.company,
        role: this.accountState.role
      };

      for (const profile of this.profilesToEnrichment) {
        switch (profile.typeid.replace('maltego.', '').replace('.profile', '').replace('affiliation.', '').toLowerCase()) {
          case 'instagram':
            eventProperties.soc_net.push('instagram');
            break;
          case 'facebook':
            eventProperties.soc_net.push('facebook');
            break;
          case 'twitter':
            eventProperties.soc_net.push('twitter');
            break;
          case 'linkedin':
            eventProperties.soc_net.push('linkedin');
            break;
        }
      }
      eventProperties.soc_net = eventProperties.soc_net.filter((val, index, array) => array.indexOf(val) === index);

      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);
    },
    async profileTreatment (profile) {
      this.$store.commit('setSearchingUserDetails', true);
      Promise.all(profile.map(item => {
        if (!item.fields['user-details'] && !(item.typeid === 'maltego.linkedin.profile' && !item.value)) {
          return this.$store.dispatch('getUserDetails', item);
        }
      })).then(() => {
        if (!this.canceled) {
          eventListener.emit('saveProfileInTag', this.takenTagName);
          this.$store.commit('setSearchingUserDetails', false);
          this.$store.commit('openInfoModal', false);
          this.searching = false;
        }
      });
    },
    cancelSearch () {
      this.$store.state.userDetails.detailsCancelToken.cancel();
      this.$store.commit('openInfoModal', false);
      this.canceled = true;
      this.searching = false;
    },
    checkRelations () {
      const targetsMap = {};
      const existingTag = this.tags.find(tag => {
        return tag.name === this.takenTagName;
      });
      const setOfResults = new Set();
      for (const profile of this.profilesToEnrichment) {
        if (existingTag && profile.tags && profile.tags.length && profile.tags.includes(existingTag.oid)) {
          continue;
        }
        if (profile?.fields?.relation?.value) {
          const connProfiles = this.$store.getters.sortedConnections.filter(conn => {
            return (profile.parent_id === conn.oid) || (profile.child_id === conn.oid) || (profile.oid === conn.parent_id);
          });
          const results = this.$store.getters.results.filter(res => {
            return (profile.parent_id === res.oid) || (profile.child_id === res.oid) || (profile.oid === res.parent_id);
          });
          const connsBetween = this.$store.getters.mergedConnectionsBetween.filter(conn => {
            return (profile.parent_id === conn.oid) || (profile.child_id === conn.oid) || (profile.oid === conn.parent_id);
          });
          for (const entity of [...connProfiles, ...results, ...connsBetween]) {
            if (!entity.tags || !entity.tags.includes(existingTag && existingTag.oid)) {
              targetsMap[entity.oid] = { type: 'entity', id: entity.oid };
              setOfResults.add(entity.oid);
            }
            this.profilesInConnection.push(entity);
          }
        }
      }
    }
  },
  mounted () {
    this.$store.getters.accountState.transforms.map(i => {
      if (i.name === '/facebook/user/v4') {
        this.slp = i.price;
      }
      if (i.name === '/instagram/user') {
        this.slp = i.price;
      }
      if (i.name === '/twitter/user') {
        this.slp = i.price;
      }
      if (i.name === '/linkedin/user') {
        this.slp = i.price;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
  .loading-details {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 62px;
  }
  .modal {
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.06em;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: .04em;
      color: #8d8d8e;
    }
  }
   .slpTooltip {
    position: relative;
  }
  .custom-btn:hover + .tooltip-text{
    visibility: visible;
    opacity: 1;
  }
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s all cubic-bezier(0.83, 0, 0.17, 1);
    position: absolute;
    padding: 16px;
    top: -110px;
    right: 50px;
    bottom: auto;
    width: 225px;
    height: 108px;
    background-color: #eff4f7;
    border-radius: 16px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #8D8D8E;
    &:hover {
      visibility: visible;
      opacity: 1;
    }
    &:after {
       content: '';
       background:  url('../../assets/icons/tooltip_arrow.svg') no-repeat;
       background-position: center;
       width: 100%;
       height: 20px;
       top: 104px;
       left: -5px;
       position: absolute;
    }
    &:before {
       content: '';
    }
  }
</style>
