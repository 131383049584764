<template>
  <div class="results-search">
    <input
      v-if="!selectedProfile"
      v-model.trim="searchQuery"
      type="text"
      placeholder="Type the subject's name, alias, company, education, or location"
      class="w-100 typography-7"
      @keyup="search"
      :style="inputStyles"
      id="connections-search"
    >
    <div class="input w-100 typography-7 d-flex align-center justify-between" v-else>
      <div class="d-flex align-center">
        <font-awesome-icon :icon="['fab', socialNetIcon]" class="results-search__item-social-icon color-theme mr-14"/>
        <img class="results-search__item-avatar" v-if="avatar" :src="avatar">
        <img class="results-search__item-avatar" v-else src="@/assets/icons/avatar.png" alt="">
        <div v-if="name" class="mr-8 typography-4">{{ name }}</div>
        <div v-if="location" class="typography-4 terms-color">{{ location }}</div>
      </div>
      <div class="d-flex align-center justify-end">
        <font-awesome-icon :icon="['fas', 'trash']" class="trash-icon" @click="removeProfile"/>
      </div>
    </div>
    <ul class="profiles-list" v-if="results.length">
      <ResultsSearchProfile
        v-for="(result, index) in results"
        :key="result.oid"
        :profile="result"
        :index="index"
        @selectProfile="selectProfile"
      />
    </ul>
  </div>
</template>

<script>
import ResultsSearchProfile from './ResultsSearchProfile';
import { getEntityAvatar, getEntityName, getEntitySocialNetIcon, getLocation } from '../utils/helpers';

export default {
  name: 'ConnectionsSearch',
  components: { ResultsSearchProfile },
  data () {
    return {
      results: [],
      visibleOIDs: [],
      searchQuery: ''
    };
  },
  computed: {
    inputStyles () {
      const radius = this.results.length ? 0 : 5;
      let styles = `border-bottom-left-radius: ${radius}px; border-bottom-right-radius: ${radius}px;`;
      if (this.results.length) {
        styles = styles + 'background-color: #F1F8FD';
      }
      return styles;
    },
    selectedProfile () {
      return this.$store.state.connections.selectedProfile;
    },
    lastVisibleOIDs () {
      return this.$store.state.connections.lastVisibleOIDs;
    },
    avatar () {
      if (this.selectedProfile) {
        return getEntityAvatar(this.selectedProfile);
      }
      return '';
    },
    name () {
      if (this.selectedProfile) {
        return getEntityName(this.selectedProfile);
      }
      return '';
    },
    location () {
      if (this.selectedProfile) {
        return getLocation(this.selectedProfile);
      }
      return '';
    },
    socialNetIcon () {
      if (this.selectedProfile) {
        return getEntitySocialNetIcon(this.selectedProfile);
      }
      return '';
    }
  },
  methods: {
    search () {
      this.results = [];
      this.visibleOIDs = [];
      if (!this.searchQuery) {
        return;
      }

      const visibleConnections = this.$store.getters.visibleConnections;
      const searchFields = ['name', 'alias', 'location', 'username'];
      const searchQuery = this.searchQuery.toLowerCase();

      visibleConnections.forEach(ent => {
        searchFields.forEach(fld => {
          const field = ent.fields[fld];
          if (field && (field.value || '').toLowerCase().includes(searchQuery) && !(this.visibleOIDs.includes(ent.oid))) {
            this.results.push(ent);
            this.visibleOIDs.push(ent.oid);
          }
        });
      });
    },
    selectProfile (entity) {
      this.$store.commit('selectConnectionProfile', entity);
      this.$store.commit('saveLastVisibleConnectionOIDs', this.$store.getters.visibleConnections);
      this.$store.commit('showConnectionsByIDs', [entity.oid]);
      this.results = [];
      this.visibleOIDs = [];
      this.searchQuery = '';
    },
    removeProfile () {
      this.$store.commit('selectConnectionProfile', null);
      this.$store.commit('showConnectionsByIDs', this.lastVisibleOIDs);
      this.$store.commit('removeLastVisibleConnectionOIDs');
    }
  }
};
</script>
