<template>
  <div class="search-input">
    <h3 v-if="!anotherErrors">Sorry, but we didn't find anything, please try to change the input data</h3>
    <h3 v-else>Error, please try once more</h3>
    <div>
      <div class="search-input__block justify-between align-center">
        <input
          id="empty-connections-search"
          v-if="!searchProfile"
          type="text"
          placeholder="Type the subject's name, alias, company, education, or location"
          class="w-100 typography-7"
          v-model.trim="profileSearchQuery"
          @keyup="search"
        >
        <div class="search-input__info d-flex align-center" v-else>
          <font-awesome-icon :icon="['fab', searchProfileSocialNetIcon]" class="find-connections-form__item-social-icon color-theme"/>
          <img class="find-connections-form__item-avatar" v-if="searchProfileAvatar" :src="searchProfileAvatar">
          <img class="find-connections-form__item-avatar" v-else src="@/assets/icons/avatar.png" alt="">
          <div v-if="searchProfileName" class="mr-8 typography-4">{{ searchProfileName }}</div>
          <div v-if="searchProfileLocation" class="typography-4 terms-color">{{ searchProfileLocation }}</div>
        </div>
        <div v-if="searchProfile" class="d-flex align-center justify-end mr-18">
          <font-awesome-icon :icon="['fas', 'trash']" class="trash-icon" @click="searchProfile = null"/>
        </div>
      </div>
      <div class="find-connections-form__items" v-if="profileSearchResults.length">
        <div class="find-connections-form__counters d-flex align-center flex-wrap">
          <div class="find-connections-form__btn d-flex align-center">Saved in tag ({{ savedInTagCount }})</div>
          <div class="find-connections-form__btn d-flex align-center">Find connections results ({{ searchConnectionsCount }})</div>
          <div class="find-connections-form__btn d-flex align-center">Profile Search results ({{ searchResultsCount }})</div>
        </div>
        <div class="find-connections-form__item d-flex justify-between pl-32" v-for="result in profileSearchResults" :key="result.oid">
          <ResultsSearchProfile :profile="result" @selectProfile="selectProfile(result)" />
        </div>
      </div>
      <div class="d-flex">
        <button
          id="empty-connections-show-results"
          class="primary-btn typography-base mt-16 mr-12"
          @click="showResults"
          :disabled="disableSearch"
        >
          Show results (15 SLP)
        </button>
        <button id="empty-connections-back" class="opacity-btn typography-base mt-16" @click="backToResults">
          <Icon name="arrow-left-blue" class="mr-14"></Icon>
          Back to profile search
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntityAvatar, getEntityName, getEntitySocialNetIcon, getLocation } from '../utils/helpers';
import ResultsSearchProfile from './ResultsSearchProfile';
import Icon from './app/Icon';
import smartlookClient from 'smartlook-client';
import { mapGetters } from 'vuex';

export default {
  name: 'EmptyConnectionsController',
  components: {
    ResultsSearchProfile, Icon
  },
  data () {
    return {
      searchProfile: null,
      profileSearchQuery: '',
      profileSearchResults: [],
      visibleOIDs: [],
      savedInTagCount: 0,
      searchResultsCount: 0,
      searchConnectionsCount: 0,
      disableSearch: true
    };
  },
  created () {
    this.searchProfile = this.$store.state.connections.from;
  },
  computed: {
    ...mapGetters(['anotherErrors']),
    searchProfileSocialNetIcon () {
      if (this.searchProfile) {
        return getEntitySocialNetIcon(this.searchProfile);
      }
      return '';
    },
    searchProfileAvatar () {
      if (this.searchProfile) {
        return getEntityAvatar(this.searchProfile);
      }
      return '';
    },
    searchProfileName () {
      if (this.searchProfile) {
        return getEntityName(this.searchProfile);
      }
      return '';
    },
    searchProfileLocation () {
      if (this.searchProfile) {
        return getLocation(this.searchProfile);
      }
      return '';
    }
  },
  methods: {
    async backToResults () {
      await this.$store.dispatch('backToResults');
    },
    async showResults () {
      smartlookClient.track('spent_slp', { where: 'find connections (empty form)', quantity: 15 });
      await this.$store.dispatch('findConnections', [this.searchProfile]);
    },
    search () {
      this.profileSearchResults = [];
      this.visibleOIDs = [];
      this.savedInTagCount = this.searchResultsCount = this.searchConnectionsCount = 0;

      if (!this.profileSearchQuery) {
        return;
      }

      const results = this.$store.getters.results;
      const connections = this.$store.getters.sortedConnections;
      const searchFields = [
        'id', 'name', 'alias', 'info', 'affiliation.uid', 'created_at', 'location', 'person.name', 'twitter.id',
        'twitter.screen-name', 'description', 'experience', 'industry', 'occupation', 'lives', 'location_name',
        'username'
      ];

      this.profileSearchQuery = this.profileSearchQuery.toLowerCase();

      [results, connections].forEach((source, idx) => {
        const counter = ['searchResultsCount', 'searchConnectionsCount'][idx];
        source.forEach(ent => {
          searchFields.forEach(fld => {
            const field = ent.fields[fld];
            if (field && (field.value || '').toLowerCase().includes(this.profileSearchQuery) && !(this.visibleOIDs.includes(ent.oid))) {
              this.profileSearchResults.push(ent);
              (ent.tags && ent.tags.length) ? this.savedInTagCount++ : this[counter]++;
              this.visibleOIDs.push(ent.oid);
            }
          });
        });
      });
    },
    selectProfile (profile) {
      document.getElementsByClassName('content')[0].scrollIntoView();
      this.searchProfile = profile;
      this.profileSearchResults = [];
      this.profileSearchQuery = '';
      this.visibleOIDs = [];
      this.disableSearch = false;
    }
  }
};
</script>

<style scoped>

</style>
