<template>
  <div class="d-flex align-center mb-16">
    <span class="tag-icon mr-12" :style="'background:' + tag.color"></span>
    <h3 class="mr-24">{{ tag.name }}</h3>
    <h3 class="terms-color">{{ stat }}</h3>
  </div>
  <div class="typography-8 color-2 mb-24">Group of your main targets.</div>
  <div v-for="profile of profiles" :key="profile.oid">
    <SavedInTagsProfile :profile="profile" />
  </div>
  <div class="connections" v-for="connection of connections" :key="connection.oid">
    <SavedInTagsConnection :edge="connection" />
  </div>
</template>

<script>
import SavedInTagsProfile from './SavedInTagsProfile';
import SavedInTagsConnection from './SavedInTagsConnection';

export default {
  name: 'SavedInTagsSection',
  props: ['tag', 'allProfiles', 'allConnections'],
  computed: {
    profiles () {
      return this.allProfiles.filter(profile => profile.tags.includes(this.tag.oid));
    },
    connections () {
      return this.allConnections.filter(conn => conn.tags.includes(this.tag.oid));
    },
    stat () {
      const connections = (this.connections.length === 1) ? 'connection' : 'connections';
      const profiles = (this.profiles.length === 1) ? 'profile' : 'profiles';
      return `${this.profiles.length} ${profiles}, ${this.connections.length} ${connections}`;
    }
  },
  components: {
    SavedInTagsProfile, SavedInTagsConnection
  }
};
</script>

<style scoped>

</style>
