<template>
  <div class="modal-background"></div>
  <div class="modal-wrap" ref="modal">
    <div class="modal" @click.stop="">
      <h3 class="typography-5 fw-600 mb-12 text-center">Information will be incomplete</h3>
      <span class="text-center d-block">You can get more information about the profile saved in the tag</span>
      <div class="mt-24 mb-24">
        <span class="color-miniheader">Profiles</span>
        <ul class="mt-12 mb-24">
          <li class="mb-8" v-for="(profile, idx) in profiles" :key="profile.value + idx">
            <div class="d-flex align-center">
              <span class="icon-wrap mr-12">
                <Icon :name="socialNetworkIcon(profile)" v-if="socialNetworkIcon(profile)" />
              </span>
              <img :src="sourceAvatar(profile)" alt="" class="sidebar-avatar" v-if="sourceAvatar(profile)">
              <img src="@/assets/icons/avatar.png" alt="" class="sidebar-avatar" v-else>
              <Icon name="user-icon" class="ml--8" v-if="hasUserDetails(profile)" />
              <Icon name="question" class="ml--8" v-else />
              <Icon name="new-connection-icon" class="ml--8" v-if="hasConnections(profile)" />
              <Icon name="question" class="ml--8" v-else />
              <span class="typography-base fw-500 ml-8">{{ name(profile) }}</span>
            </div>
            <div>
              <input type="checkbox" name="" class="sidebar-checkbox" :id="profile.oid + '-' + idx" :checked="profile.checked">
              <label class="sidebar-checkbox__label" @click.stop="checkEntity(profile)"></label>
            </div>
          </li>
        </ul>
        <span class="color-miniheader">Show info</span>
        <ul class="typography-6 mt-12">
          <li class="mb-8">
            <span>Profile details</span>
            <div class="d-flex align-center">
              <span class="mr-12">5 slp for each profile</span>
              <input type="checkbox" name="" class="sidebar-checkbox" id="checkInfo" ref="checkInfo">
              <label class="sidebar-checkbox__label" for="checkInfo" @click.stop="needUserDetails = !needUserDetails"></label>
            </div>
          </li>
          <li>
            <span>Social graph – table of connection</span>
            <div class="d-flex align-center">
              <span class="mr-12">15 slp for each profile</span>
              <input type="checkbox" name="" class="sidebar-checkbox" id="checkConnections" ref="checkConnections">
              <label class="sidebar-checkbox__label" for="checkConnections" @click.stop="needConnections = !needConnections"></label>
            </div>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-between">
        <button class="opacity-btn mr-16" id="open-preview" @click="openPreview">Export it anyway</button>
        <button class="primary-btn typography-base" :disabled="!fullPrice" @click="getMoreInfo" id="more-info">Get more info ({{fullPrice}} SLP)</button>
      </div>
      <div class="search-block" :class="searchingUserDetails ? 'd-flex' : 'd-none'">
        <img src="@/assets/icons/searching.gif" alt="" class="h-78 mb-12">
        <div class="typography-theme">
          <span class="mr-4">Loading...</span>
          <button class="color-link" @click="cancelSearch" id="cancel">Сancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../app/Icon';
import { mapGetters } from 'vuex';
import { getEntityAvatar, getEntityName } from '../../utils/helpers';
import smartlookClient from 'smartlook-client';

export default {
  name: 'FastReportModal',
  components: {
    Icon
  },
  emits: ['closeModal', 'openFastReport'],
  data () {
    return {
      needUserDetails: false,
      needConnections: false
    };
  },
  mounted () {
    this.$refs.modal.addEventListener('click', this.closeModal);
    this.profiles.forEach(profile => {
      profile.checked = true;
    });
  },
  computed: {
    ...mapGetters(['currentMultiprofile', 'resultsMap', 'accountState', 'searchingUserDetails', 'sortedRelationSearches', 'connections']),
    profiles () {
      const profiles = [];
      this.currentMultiprofile && this.currentMultiprofile.entity_ids.forEach(id => {
        for (const key of Object.keys(this.resultsMap)) {
          if (key === id) {
            profiles.push(this.resultsMap[key]);
          }
        }
      });
      return profiles;
    },
    profilesWithoutUserDetails () {
      const profiles = [];
      this.currentMultiprofile && this.currentMultiprofile.entity_ids.forEach(id => {
        for (const key of Object.keys(this.resultsMap)) {
          if (key === id && !this.resultsMap[key].fields['user-details']) {
            profiles.push(this.resultsMap[key]);
          }
        }
      });
      return profiles;
    },
    userDetailsFullPrice () {
      const transforms = this.accountState.transforms.filter(transform => {
        return transform.name === '/facebook/user/v4' || transform.name === '/instagram/user' || transform.name === '/twitter/user' || transform.name === '/linkedin/user';
      });
      let price = 0;
      this.profilesWithoutUserDetails.forEach(profile => {
        if (!profile.fields['user-details'] && profile.checked) {
          if (profile.typeid === 'maltego.facebook.profile') {
            price = this.setPrice(transforms, '/facebook/user/v4', price);
          } else if (profile.typeid === 'maltego.affiliation.Twitter') {
            price = this.setPrice(transforms, '/twitter/user', price);
          } else if (profile.typeid === 'maltego.instagram.profile') {
            price = this.setPrice(transforms, '/instagram/user', price);
          } else {
            price = this.setPrice(transforms, '/linkedin/user', price);
          }
        }
      });
      return price;
    },
    fullPrice () {
      let price = 0;
      if (this.needUserDetails) {
        price = price + this.userDetailsFullPrice;
      }
      if (this.needConnections) {
        price = price + this.findConnectionsPrice;
      }
      return price;
    },
    profilesWithConnections () {
      const profilesWithConnections = new Set();
      const set = new Set();
      this.sortedRelationSearches.forEach(relation => {
        relation.targets.forEach(target => {
          set.add(target);
        });
      });
      set.forEach(id => {
        const profile = this.profiles.find(profile => {
          return id === profile.oid;
        });
        if (profile) {
          profilesWithConnections.add(profile.oid);
        }
      });
      return profilesWithConnections;
    },
    profilesForLoadConnections () {
      const profilesForLoadConnections = [];
      this.profiles.forEach(profile => {
        if (!this.profilesWithConnections.has(profile.oid)) {
          profilesForLoadConnections.push(profile);
        }
      });
      return profilesForLoadConnections;
    },
    findConnectionsPrice () {
      const transforms = this.accountState.transforms.filter(transform => {
        return transform.name === '/socializer/instagram/connections' || transform.name === '/socializer/facebook/connections' || transform.name === '/socializer/twitter/connections' || transform.name === '/socializer/linkedin/connections';
      });
      let price = 0;
      this.profilesForLoadConnections.forEach(profile => {
        if (profile.checked) {
          if (profile.typeid === 'maltego.facebook.profile') {
            price = this.setPrice(transforms, '/socializer/facebook/connections', price);
          } else if (profile.typeid === 'maltego.affiliation.Twitter') {
            price = this.setPrice(transforms, '/socializer/twitter/connections', price);
          } else if (profile.typeid === 'maltego.instagram.profile') {
            price = this.setPrice(transforms, '/socializer/instagram/connections', price);
          } else {
            price = this.setPrice(transforms, '/socializer/linkedin/connections', price);
          }
        }
      });
      return price;
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal');
    },
    socialNetworkIcon (profile) {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (profile.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    sourceAvatar (profile) {
      return getEntityAvatar(profile);
    },
    hasUserDetails (profile) {
      return !!profile.fields['user-details'];
    },
    name (profile) {
      return getEntityName(profile);
    },
    checkEntity (profile) {
      this.$store.commit('checkResultsProfile', profile.oid);
    },
    async getMoreInfo () {
      this.$store.commit('setSearchingUserDetails', true);
      this.$refs.modal.removeEventListener('click', this.closeModal);
      const connections = this.connections;
      const promises = [];
      if (this.$refs.checkInfo.checked) {
        this.profilesWithoutUserDetails.forEach(profile => {
          if (profile.checked) {
            promises.push(this.$store.dispatch('getUserDetails', profile));
          }
        });
      }
      if (this.$refs.checkConnections.checked) {
        this.profilesForLoadConnections.forEach(profile => {
          if (profile.checked) {
            promises.push(this.$store.dispatch('findConnections', [profile]));
          }
        });
      }
      Promise.all(promises).then(() => {
        // We add already found connections, if there are any
        connections.forEach(connection => {
          this.$store.commit('addConnection', connection);
        });
        this.$store.commit('setSearchingUserDetails', false);
        smartlookClient.track('spent_slp', { where: 'profile details', quantity: this.userDetailsFullPrice });
        this.$refs.modal.addEventListener('click', this.closeModal);
        this.$refs.checkInfo.checked = false;
        this.$refs.checkConnections.checked = false;
        this.openPreview();
      });
    },
    cancelSearch () {
      this.$store.state.userDetails.detailsCancelToken.cancel();
      this.$store.commit('setSearchingUserDetails', false);
    },
    openPreview () {
      this.$store.commit('setOpenFastReport', true);
      this.$emit('closeModal');
    },
    setPrice (transforms, type, price) {
      transforms.forEach(transform => {
        if (transform.name === type) {
          price = price + transform.price;
        }
      });
      return price;
    },
    hasConnections (profile) {
      const set = new Set();
      this.sortedRelationSearches.forEach(relation => {
        relation.targets.forEach(target => {
          set.add(target);
        });
      });
      return set.has(profile.oid);
    }
  }
};
</script>

<style lang="scss" scoped>
li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal button {
  max-width: 100%;
}
.search-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: baseline;
  }
}
</style>
