<template>
  <div class="profile-card" @click="checkCard(entity.oid)">
    <div class="profile-card__header d-flex justify-between">
      <div class="profile-card__type d-flex">
        <a :href="profileUrl" class="profile-url d-flex link typography-7 let-space-08" target="_blank" @click.stop="">
          <div class="profile-card__icon mr-8 d-flex align-center"><Icon :name="socialNetworkIcon" /></div>
          <div class="profile-card__text mt-2">Profile</div>
        </a>
      </div>
      <div @click.stop="">
        <input type="checkbox" name="" :id="'profile-card-checkbox' + entity.oid" class="profile-card-checkbox" :checked="entity.checked" @change="checkEntity">
        <label :for="'profile-card-checkbox' + entity.oid" class="profile-card-checkbox__label" :ref="'label-' + entity.oid"></label>
      </div>
    </div>
    <div class="profile-card__photo">
      <img :src="avatar" @load="avatarLoadHandler" @error="avatarErrorHandler" class="profile-card__avatar exists" :class="loadImage ? 'd-block' : 'd-none'">
      <img src="@/assets/icons/avatar.png" alt="" @click.stop="" class="profile-card__avatar" :class="loadImage ? 'd-none' : 'd-block'">
      <img src="@/assets/icons/dots.svg" alt="" class="profile-card__dots" @click.stop="openProfileInConnections" v-if="$store.state.connections.opened">
      <img src="@/assets/icons/dots.svg" alt="" class="profile-card__dots" @click.stop="openProfile" v-else>
    </div>
    <div class="profile-card__name tooltip" @mouseleave="hideTooltip('profile-name-' + entity.oid)">
      <div
        class="ellipsis-text"
        :id="'profile-name-' + entity.oid"
        @mouseover="showTooltip('profile-name-' + entity.oid)"
      >
        {{ name }}
      </div>
      <span class="tooltip-text">{{ name }}</span>
    </div>
    <div class="profile-card__location tooltip bottom-tooltip" @mouseleave="hideTooltip('profile-location-' + entity.oid)">
      <div
        class="ellipsis-text"
        :id="'profile-location-' + entity.oid"
        @mouseover="showTooltip('profile-location-' + entity.oid)"
      >
        {{ location }}
      </div>
      <span class="tooltip-text">{{ location }}</span>
    </div>
  </div>
</template>

<script>
import Icon from './app/Icon';
import { getLocation, getEntityName, getEntityAvatar, getEntityUrl, eventListener } from '../utils/helpers';

export default {
  name: 'ResultsEntity',
  props: ['entity'],
  emits: ['openProfileSidebar'],
  data () {
    return {
      loadImage: false
    };
  },
  methods: {
    avatarLoadHandler () {
      this.loadImage = true;
    },
    avatarErrorHandler () {
      this.loadImage = false;
    },
    checkEntity () {
      this.$store.commit('checkResultsProfile', this.entity.oid);
    },
    checkCard (entityOID) {
      this.$refs['label-' + entityOID].click();
    },
    showTooltip (elementID) {
      const nameSelector = document.getElementById(elementID);
      const tooltipText = nameSelector.nextSibling;
      const parent = nameSelector.parentElement;
      const clone = nameSelector.cloneNode(true);
      clone.style.display = 'inline';
      clone.style.width = 'auto';
      clone.style.visibility = 'hidden';
      parent.appendChild(clone);
      this.$nextTick(() => {
        if (nameSelector.getBoundingClientRect().width < clone.getBoundingClientRect().width) {
          tooltipText.style.visibility = 'visible';
          tooltipText.style.opacity = 1;
        }
        clone.remove();
      });
    },
    hideTooltip (elementID) {
      const nameSelector = document.getElementById(elementID);
      const tooltipText = nameSelector.nextSibling;
      tooltipText.style.visibility = 'hidden';
      tooltipText.style.opacity = 0;
    },
    openProfileInConnections () {
      this.$emit('openProfileSidebar', this.entity);
    },
    openProfile () {
      eventListener.emit('openProfileSidebar', this.entity);
      this.$store.commit('uncheckAllResults');
    }
  },
  computed: {
    name () {
      return getEntityName(this.entity);
    },
    location () {
      return getLocation(this.entity);
    },
    avatar () {
      return getEntityAvatar(this.entity);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    socialNetworkIcon () {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (this.entity.typeid.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    },
    profileUrl () {
      return getEntityUrl(this.entity);
    }
  },
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>

</style>
