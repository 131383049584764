<template>
  <div class="connection">
    <div class="connection__profile">
      <div class="connection__profile-body-wrap d-flex justify-between">
        <div class="connection__profile-info">
          <div class="connection__profile-info-header d-flex justify-between">
            <div class="connection__profile-info-top d-flex">
              <img class="connection__avatar-source" :src="sourceAvatar" v-if="sourceAvatar">
              <img class="connection__avatar-source" src="@/assets/icons/avatar.png" v-else>
              <img :src="destinationAvatar" class="connection__avatar ml-n32 mr-24" @error="destinationAvatar = ''" v-if="destinationAvatar">
              <img src="@/assets/icons/avatar.png" class="connection__avatar ml-n32 mr-24" v-else>
              <div class="d-flex justify-center">
                <h3>{{ sourceName }} & {{ destinationName }}</h3>
<!--                <div class="typography-theme fw-400 color-1">Have a connection with each other.</div>-->
              </div>
            </div>
          </div>
          <div class="connection__profile-info-footer d-flex justify-between typography-theme">
           <div class="d-flex align-center mt-8" v-if="edge.note">
             <div class="connection__profile-info-footer-text">Note</div>
             <div class="d-flex align-center">
               <p class="ml-8 mb-4 mt-4 typography-8 color-2">{{edge.note}}</p>
             </div>
           </div>
            <div class="d-flex align-center mt-8 mb-4">
              <div class="connection__profile-info-footer-text">Main info</div>
              <div class="d-flex align-center">
                <div class="ml-8 filter-tag mb-4 mt-4">Connection score: {{ score }}</div>
                <div class="ml-8 filter-tag mb-4 mt-4" v-if="mutualFriends">{{ mutualFriends }} common friends</div>
              </div>
            </div>
            <div class="d-flex align-center mt-6 mb-8">
              <div class="connection__profile-info-footer-text">Relationship tags</div>
              <div class="d-flex align-center flex-wrap">
                <div class="ml-8 filter-tag mb-4 mt-4" v-for="relationship in connectionTypes" :key="relationship">
                  <span>{{ relationship }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="connection-posts">
      <ConnectionPost v-for="post in posts" :key="post.oid" :post="post" :connection="destination" :source="source" />
    </div>
  </div>
</template>

<script>
import ConnectionPost from '../ConnectionPost';
import { getEntityName, getEntityAvatar } from '../../utils/helpers';

export default {
  name: 'SavedInTagsConnection',
  props: ['edge'],
  data () {
    return {
      parentNode: null,
      childNode: null,
      connectionPosts: [],
      error: false,
      destinationAvatar: ''
    };
  },
  async mounted () {
    if (this.destination) {
      this.childNode = this.destination;
    }
    if (this.source) {
      this.parentNode = this.source;
    }
    const postsLoaded = !!this.posts.length;
    if (!this.source || !this.destination || (this.postExists && !postsLoaded)) {
      const payload = {
        method: 'POST',
        url: '/api/user/searches/relation',
        body: {
          id: this.edge.oid,
          search_id: this.edge.rid,
          levels: [3, 4]
        }
      };
      if (this.edge.parent_id) {
        payload.body.parent_id = this.edge.parent_id;
      }
      const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
      if (response.status === 200) {
        const results = await this.$store.dispatch('convertEntityFieldsToMap', response.data);
        this.connectionPosts = results.filter(ent => ent.level === 4).sort(this.comparePostDates);
        if (!this.source) {
          this.parentNode = results.find(ent => ent.level === 1);
          this.parentNode.checked = false;
          this.$store.commit('addProfileToResultsMap', this.parentNode);
        }
        if (!this.destination) {
          this.childNode = results.find(ent => ent.level === 3);
          this.childNode.checked = false;
          this.$store.commit('addProfileToResultsMap', this.childNode);
        }
        this.childNode.posts = this.connectionPosts;
      } else {
        this.error = true;
      }
    }
    this.$store.commit('setLoadUrl', false);
    this.$store.commit('setSearching', false);
    this.destinationAvatar = getEntityAvatar(this.destination);
  },
  computed: {
    resultsMap () {
      return this.$store.getters.resultsMap;
    },
    source () {
      if (this.parentNode) {
        return this.parentNode;
      }
      let ent = this.resultsMap[this.edge.parent_id];
      if (!ent) {
        ent = this.$store.getters.connectionEntitiesMap[this.edge.parent_id];
      }
      return ent;
    },
    destination () {
      if (this.childNode) {
        return this.childNode;
      }
      if (!this.edge.child_id) {
        for (const oid of Object.keys(this.resultsMap)) {
          const ent = this.resultsMap[oid];
          if (ent.parent_id === this.edge.oid) {
            return ent;
          }
        }
      }
      let ent = this.resultsMap[this.edge.child_id];
      if (!ent) {
        ent = this.$store.getters.connectionEntitiesMap[this.edge.child_id];
      }
      return ent;
    },
    sourceName () {
      return getEntityName(this.source);
    },
    destinationName () {
      return getEntityName(this.destination);
    },
    sourceAvatar () {
      return getEntityAvatar(this.source);
    },
    score () {
      try {
        return this.destination.fields.score.value;
      } catch (e) {
        return 0;
      }
    },
    mutualFriends () {
      try {
        return this.destination.fields.mutual_friends.value;
      } catch (e) {
        return 0;
      }
    },
    connectionTypes () {
      try {
        return this.destination.fields.connection_types.value.split(',');
      } catch (e) {
        return [];
      }
    },
    postExists () {
      const condition1 = this.destination?.post_exists || this.destination?.fields?.post_exists?.value;
      const condition2 = this.childNode?.post_exists || this.childNode?.fields?.post_exists?.value;
      return condition1 || condition2;
    },
    posts () {
      if (this.connectionPosts.length) {
        return this.slicePosts(this.connectionPosts);
      }
      if (this.childNode?.posts) {
        return this.slicePosts(this.childNode.posts);
      }
      if (this.destination?.posts) {
        const posts = [];
        Object.keys(this.destination.posts).forEach(key => posts.push(this.destination.posts[key]));
        return this.slicePosts(posts).sort(this.comparePostDates);
      }
      return [];
    }
  },
  methods: {
    comparePostDates (post1, post2) {
      return post2.fields.date.value - post1.fields.date.value;
    },
    slicePosts (posts) {
      if (this.edge.countPostsToShow && typeof (this.edge.countPostsToShow) === 'number' && this.$route.name === 'Report') {
        return posts.slice(0, this.edge.countPostsToShow);
      } else {
        return posts;
      }
    }
  },
  components: {
    ConnectionPost
  }
};
</script>

<style lang="scss" scoped>
  .connection {
    &__profile-info-footer {
      padding: 16px 32px;
    }
  }
  .connection-post {
    background-color: #F2F8F9;
  }
  .filter-tag {
    color: #8D8D8E !important;
    background-color: rgba(217, 230, 247, 0.3);
  }
  .connection__avatar-source {
    height: 320px;
    width: 290px;
    border-radius: 6px;
    object-fit: cover;
  }
</style>
