<template>
  <header class="search-group-header d-flex justify-between mt-32">
    <div class="search-type d-flex align-center pointer" @click="openTab">{{ searchTitle }}
      <span>
        <button>
          <Icon name="arrow-down-white" color="#60BCE3" :class="{rotated: openedTab}" class="ml-10 mr-16"/>
        </button>
      </span>
    </div>
    <div class="profiles-count">{{ entities.length }} profile(s)</div>
  </header>
  <div class="profiles d-flex flex-wrap mx--8" v-show="openedTab">
    <div class="profile-card-wrap" v-for="(entity, idx) in entities" :key="entity.value + idx">
      <ResultsEntity :entity="entity" @openProfileSidebar="openProfileSidebar(entity)" />
    </div>
  </div>
</template>

<script>
import ResultsEntity from './ResultsEntity';
import Icon from './app/Icon';

export default {
  name: 'MainResultsSearchGroup',
  props: ['entities', 'searchType'],
  emits: ['openProfileSidebar'],
  data () {
    return {
      openedTab: true
    };
  },
  computed: {
    searchTitle () {
      const searchQuery = this.$store.getters.openSearch?.query || {};
      let searchTitle = '';
      for (let searchType of this.searchType.split(',')) {
        if (searchType === 'photo') searchType = 'image';
        let searchValue = searchQuery[searchType];
        if (searchValue) {
          const loadedImage = searchValue.startsWith(process.env.VUE_APP_IMAGES_SERVER_PROD) || searchValue.startsWith(process.env.VUE_APP_IMAGES_SERVER_TEST);
          if (searchType === 'image' && loadedImage) {
            searchValue = searchValue.split('/').pop();
          }
          searchTitle += `${searchType.charAt(0).toUpperCase() + searchType.replace('_', ' ').slice(1)}: ${searchValue} & `;
        }
      }
      return searchTitle.slice(0, searchTitle.length - 3);
    }
  },
  methods: {
    openProfileSidebar (profile) {
      this.$emit('openProfileSidebar', profile);
    },
    openTab () {
      this.openedTab = !this.openedTab;
    }
  },
  components: {
    ResultsEntity, Icon
  }
};
</script>

<style scoped>
  .search-group-header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
  }
  .search-type {
    color: #29292B;
  }
  .profiles-count {
    color: #A0B2BE;
    min-width: 130px;
    text-align: right;
  }
  .rotated {
    transform: rotate(-180deg);
  }
</style>
