<template>
  <SearchForm v-if="showSearchForm" :caseName="true" />
  <MainResults v-if="$store.state.results.opened" />
  <MainConnections v-if="$store.state.connections.opened" />
  <SavedInTags v-if="$store.state.tags.openSavedInTagsPage" />
  <SubscribeModal v-else-if="openSubscribeModal">
    <template v-if="getSubscribe">
      <p class="typography-5 fw-600 let-space-12 color-1 mb-24">Your Gamayun subscription is invalid</p>
      <p class="mb-24">You need to purchase a license to use these features.</p>
    </template>
    <template v-else>
      <p class="typography-5 fw-600 let-space-12 color-1 mb-24">You have run out of SL points</p>
      <p class="mb-24">You need to buy an additional package on the plan page.</p>
    </template>
  </SubscribeModal>
  <Multiprofile v-if="$store.state.multiprofile.opened" />
  <div class="popup-container"
    v-if="sidebarTagPopupOpen && !$store.state.connections.opened">
    <TagPopup
      :profiles="[openProfile]"
      @closeTagsPopup="sidebarTagPopupOpen = false"
      @showSavedTag="showSavedTag"
      :class="{fixed: sidebarTagPopupOpen}"
    />
  </div>
  <ProfileSidebar
    v-if="showProfileSidebar && !$store.state.connections.opened"
    :profile="openProfile"
    :tagInfo="savedTagInfo"
    @closeProfileSidebar="closeProfileSidebar"
    @showTagsPopup="showTagsPopup"
    @openMonitoringSidebar="openMonitoringSidebar"
  />
  <div class="aside-backwall" v-if="showProfileSidebar && !$store.state.connections.opened"></div>
  <MonitoringSidebar
    v-if="showMonitoringSidebar && openProfile"
    :profile="openProfile"
    @close="showMonitoringSidebar = false"
  />
  <Popup :profiles="checkedResults"/>
    <transition name="opacity">
      <div class="transition-container" v-show="$store.state.userDetails.getMoreInfoModalOpen">
        <GetInfoPopup />
      </div>
    </transition>
</template>

<script>
import SearchForm from '@/components/app/SearchForm.vue';
import MainResults from './MainResults';
import MainConnections from './MainConnections';
import SavedInTags from './app/SavedInTags';
import SubscribeModal from './app/SubscribeModal';
import Multiprofile from './multiprofile/Multiprofile';
import { mapGetters } from 'vuex';
import ProfileSidebar from './app/ProfileSidebar';
import TagPopup from './TagPopup.vue';
import Popup from './app/Popup';
import { eventListener } from '../utils/helpers';
import GetInfoPopup from './app/GetInfoPopup';
import MonitoringSidebar from '@/components/monitoring/Sidebar';

export default {
  name: 'Case',
  computed: {
    ...mapGetters(['openSubscribeModal', 'getSubscribe', 'showTrialInfo']),
    showSearchForm () {
      return !this.$store.state.results.opened && !this.$store.state.connections.opened && !this.$store.state.tags.openSavedInTagsPage && !this.$store.state.multiprofile.opened;
    },
    checkedResults () {
      const results = [];
      const addCheckedEntities = (array) => {
        array.forEach(ent => {
          if (ent.checked) {
            results.push(ent);
          }
        });
      };
      addCheckedEntities(this.$store.getters.results);
      this.$store.getters.results.forEach(res => { addCheckedEntities(res.children || []); });
      addCheckedEntities(this.$store.getters.sortedConnections);
      addCheckedEntities(this.$store.getters.edges);
      return results;
    }
  },
  components: {
    MonitoringSidebar,
    MainConnections,
    MainResults,
    SearchForm,
    SavedInTags,
    SubscribeModal,
    Multiprofile,
    ProfileSidebar,
    TagPopup,
    Popup,
    GetInfoPopup
  },
  data () {
    return {
      showProfileSidebar: false,
      showMonitoringSidebar: false,
      openProfile: null,
      sidebarTagPopupOpen: false,
      savedTagInfo: {
        name: '',
        color: ''
      },
      tagName: null
    };
  },
  created () {
    eventListener.on('openProfileSidebar', (profile) => {
      this.checkAndOpenProfileSidebar(profile);
    });
  },
  watch: {
    openProfile () {
      if (!this.openProfile) {
        this.showMonitoringSidebar = false;
      }
    }
  },
  methods: {
    openMonitoringSidebar () {
      this.showMonitoringSidebar = !this.showMonitoringSidebar;
    },
    getTagName (data) {
      this.TagName = data;
    },
    checkAndOpenProfileSidebar (profile) {
      if (this.openProfile && this.openProfile.oid === profile.oid) return;
      if (this.showProfileSidebar) {
        this.closeProfileSidebar().then(() => {
          this.openProfileSidebar(profile);
          this.savedTagInfo.name = '';
          this.savedTagInfo.color = '';
        });
      } else {
        this.openProfileSidebar(profile);
      }
    },
    openProfileSidebar (profile) {
      this.showProfileSidebar = true;
      this.openProfile = profile;
      setTimeout(() => {
        document.querySelector('.profile-sidebar').classList.add('profile-sidebar--show');
      }, 300);
    },
    closeProfileSidebar () {
      this.sidebarTagPopupOpen = false;
      const profileSidebar = document.querySelector('.profile-sidebar');
      if (profileSidebar) {
        profileSidebar.classList.remove('profile-sidebar--show');
      }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.showProfileSidebar = false;
          this.openProfile = null;
          resolve();
        }, 300);
      });
    },
    showSavedTag (obj) {
      if (obj) {
        this.savedTagInfo.name = obj.name;
        this.savedTagInfo.color = obj.color;
      }
    },
    showTagsPopup () {
      this.sidebarTagPopupOpen = true;
    }
  }
};
</script>

<style lang="scss" scoped>
  .opacity-enter-from,
  .opacity-leave-to {
    opacity: 0;
  }
  .opacity-enter-active,
  .opacity-leave-active {
    transition: all .1s;
  }
</style>
