<template>
  <li
    class="d-flex align-center cursor-pointer jc-space-between bg-form bg-form-darker_hover bg-form_active px-16 py-8"
    :class="{ 'b-top bc-form-border': index !== 0 }"
  >
    <div class="d-flex align-center w-100" @click="showProfile">
      <font-awesome-icon
        :icon="['fab', socialNetIcon]"
        class="results-search__item-social-icon color-theme min-w-16 mr-10"
      />
      <img class="results-search__item-avatar" v-if="avatar" :src="avatar">
      <img class="results-search__item-avatar" v-else src="@/assets/icons/avatar.png" alt="">
      <div v-if="name" class="fs-13 color-text-darker let-space-04 mr-8">{{ name }}</div>
      <div v-if="location" class="fs-13 color-text-lighter let-space-04">{{ location }}</div>
    </div>
  </li>
</template>

<script>
import { getEntityAvatar, getEntityName, getEntitySocialNetIcon, getLocation } from '../utils/helpers';

export default {
  name: 'ResultsSearchProfile',
  props: ['profile', 'index'],
  emits: ['selectProfile'],
  computed: {
    avatar () {
      return getEntityAvatar(this.profile);
    },
    name () {
      return getEntityName(this.profile);
    },
    location () {
      return getLocation(this.profile);
    },
    socialNetIcon () {
      return getEntitySocialNetIcon(this.profile);
    }
  },
  methods: {
    showProfile () {
      this.$emit('selectProfile', this.profile);
      this.$store.commit('showProfilesByIDs', [this.profile.oid]);
    }
  }
};
</script>

<style scoped>

</style>
