import { getEntityName, getLocation, getEntityAvatar, getEntityUrl } from '../utils/helpers';

export default {
  computed: {
    name () {
      return getEntityName(this.profile);
    },
    location () {
      return getLocation(this.profile);
    },
    avatar () {
      return getEntityAvatar(this.profile);
    },
    profileUrl () {
      return getEntityUrl(this.profile);
    },
    bio () {
      const bio = [this.name];
      if (this.alias) {
        bio.push(this.alias);
      }
      if (this.id) {
        bio.push((!isNaN(this.id)) ? 'ID: ' + this.id : this.id);
      }
      if (this.createdDate) {
        bio.push('Date of creation: ' + this.createdDate);
      }
      if (this.followersCount) {
        bio.push(this.followersCount + ' followers');
      }
      if (this.tweetsCount) {
        bio.push(this.tweetsCount + ' tweets');
      }
      if (this.friendsCount) {
        bio.push(this.friendsCount + ' friends');
      }
      if (this.description) {
        bio.push('Description: ' + this.description);
      }
      if (this.education) {
        bio.push('Education: ' + this.education);
      }
      if (this.experience) {
        bio.push('Experience: ' + this.experience);
      }
      if (this.industry) {
        bio.push('Industry: ' + this.industry);
      }
      if (this.occupation) {
        bio.push('Occupation: ' + this.occupation);
      }
      if (this.location && this.socialNet !== 'Facebook') {
        bio.push(this.location);
      }
      bio.push(...this.info);
      return bio;
    },
    alias () {
      const fields = this.profile.fields;
      let alias = fields && fields.alias;
      if (!alias) {
        alias = fields && fields['twitter.screen-name'];
      }
      if (!alias) {
        alias = fields && fields.username;
      }
      return (alias && alias.value) || '';
    },
    id () {
      const fields = this.profile.fields;
      let id = fields && fields.id;
      if (!id) {
        id = fields && fields['affiliation.uid'];
      }
      if (!id) {
        id = fields && fields['twitter.id'];
      }
      return (id && id.value) || '';
    },
    createdDate () {
      return this.getProfileFieldValue('created_at');
    },
    followersCount () {
      return this.getProfileFieldValue('followers_count');
    },
    tweetsCount () {
      return this.getProfileFieldValue('tweets_count');
    },
    friendsCount () {
      return this.getProfileFieldValue('twitter.friendcount');
    },
    description () {
      return this.getProfileFieldValue('description');
    },
    education () {
      return this.getProfileFieldValue('education');
    },
    experience () {
      return this.getProfileFieldValue('experience');
    },
    industry () {
      return this.getProfileFieldValue('industry');
    },
    occupation () {
      return this.getProfileFieldValue('occupation');
    },
    socialNet () {
      const typeID = this.profile.typeid.toLowerCase();
      if (typeID.includes('facebook')) {
        return 'Facebook';
      }
      if (typeID.includes('twitter')) {
        return 'Twitter';
      }
      if (typeID.includes('instagram')) {
        return 'Instagram';
      }
      if (typeID.includes('linkedin')) {
        return 'Linkedin';
      }
      return '';
    },
    info () {
      if (this.profile.fields && this.profile.fields.info) {
        return this.profile.fields.info.value.split('###');
      }
      return [];
    }
  }
};
