<template>
  <div class="report-preview">
    <div class="report-preview_wrap" id="htmltopdf">
      <div class="d-flex flex-column align-center justify-center w-100 mb-48">
        <div class="d-flex mb-12">
          <h1 class="typography-1 color-1 mr-12" v-if="!inputH1">{{title || 'Report'}}</h1>
          <input
            type="text"
            class="case-name-input typography-11 mr-16"
            v-model.trim="title"
            maxlength="25"
            @keydown.enter="updateReportHeaders('h1')"
            id="input-title"
            v-else
          >
          <button @click="showInputH1" v-if="!inputH1" id="edit-title"><Icon name="edit" class="icon-hover" /></button>
          <template v-else>
            <button @click="updateReportHeaders('h1')" id="save-title"><Icon name="ok" /></button>
          </template>
        </div>
        <div class="d-flex mb-16">
          <h2 class="typography-5 fw-400 color-2 mr-12" v-if="!inputH2">{{subtitle || 'Subtitle'}}</h2>
          <input
            type="text"
            class="case-name-input typography-11 mr-16"
            v-model.trim="subtitle"
            maxlength="25"
            @keydown.enter="updateReportHeaders('h2')"
            id="input-subtitle"
            v-else
          >
          <button @click="showInputH2" v-if="!inputH2" id="edit-subtitle"><Icon name="edit" class="icon-hover" /></button>
          <template v-else>
            <button @click="updateReportHeaders('h2')" id="save-subtitle"><Icon name="ok" /></button>
          </template>
        </div>
        <div class="d-flex w-100">
          <p class="typography-8 mr-12" v-if="!inputDesc">{{description || 'Description'}}</p>
          <textarea
            cols="30"
            rows="7"
            class="report-textarea mr-16"
            v-model="description"
            @keydown.enter="updateReportHeaders('inputDesc')"
            id="subscription"
            v-else
          ></textarea>
          <button @click="showInputDesc" v-if="!inputDesc" id="edit-subscription">
            <Icon name="edit" class="icon-hover" />
          </button>
          <template v-else>
            <button @click="updateReportHeaders('inputDesc')" id="save-subscription"><Icon name="ok" /></button>
          </template>
        </div>
      </div>
      <div class="pb-48">
        <div class="mb-48">
          <ReportProfilePreview :profiles="profiles" :multiprofile="currentMultiprofile" />
        </div>
      </div>
    </div>
  </div>
  <ReportPopup @closePreview="closePreview" />
</template>

<script>
import Icon from '../app/Icon';
import { getEntityAvatar, getEntityName, getEntityUrl } from '../../utils/helpers';
import ReportPopup from './ReportPopup';
import ReportProfilePreview from '@/components/report/ReportProfilePreview';
import { mapGetters } from 'vuex';

export default {
  name: 'FastReport',
  components: {
    Icon,
    ReportPopup,
    ReportProfilePreview
  },
  props: ['profiles'],
  data () {
    return {
      inputH1: false,
      inputH2: false,
      inputDesc: false,
      title: '',
      subtitle: '',
      description: ''
    };
  },
  computed: {
    ...mapGetters(['currentMultiprofile'])
  },
  methods: {
    sourceAvatar (profile) {
      return getEntityAvatar(profile);
    },
    url (profile) {
      return getEntityUrl(profile);
    },
    socialNetworkIcon (socialNetwork) {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (socialNetwork.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    },
    socialIconFromProfile (profile) {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (profile.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    setClass1 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' && field !== 'Projects' ? 'd-flex align-start' : '';
    },
    setClass2 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' ? 'subhead-width' : '';
    },
    name (profile) {
      return getEntityName(profile);
    },
    profileId (profile) {
      if (profile.fields.id) {
        return `<a href="${profile.fields.surl.value}" target="_blank">(id: ${profile.fields.id.value})</a>`;
      } else if (profile.fields['twitter.id']) {
        return `<a href="${profile.fields['affiliation.profile-url'].value}" target="_blank">(id: ${profile.fields['twitter.id'].value})</a>`;
      } else if (profile.fields.number_id) {
        return `<a href="${profile.fields.surl.value}" target="_blank">(id: ${profile.fields.number_id.value})</a>`;
      } else if (profile.fields.alias && profile.fields.surl) {
        return `<a href="${profile.fields.surl.value}" target="_blank">(alias: ${profile.fields.alias.value})</a>`;
      } else return '';
    },
    showInputH1 () {
      this.inputH1 = true;
    },
    showInputH2 () {
      this.inputH2 = true;
    },
    showInputDesc () {
      this.inputDesc = true;
    },
    updateReportHeaders (type) {
      if (type === 'h1') {
        this.inputH1 = false;
      } else if (type === 'h2') {
        this.inputH2 = false;
      } else {
        this.inputDesc = false;
      }
    },
    closePreview () {
      this.$store.commit('setOpenFastReport', false);
    },
    connectionTypes (profile) {
      return profile.fields.connection_types.value.split(',').join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
.case-name-input {
  background: #eff4f7;
  padding: 12px;
  width: 300px;
  border-radius: 6px;
}
.subhead-width::first-letter {
  text-transform: uppercase;
}
</style>
