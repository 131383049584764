<template>
  <div>
    <h1 class="typography-1 color-1 mb-20">Saved in tags</h1>
    <div v-if="showAll">
      <div v-for="tag in tags" :key="tag.oid" class="mb-36">
        <SavedInTagsSection :all-connections="connections" :all-profiles="profiles" :tag="tag" :key="Math.random()" />
      </div>
    </div>
    <div v-else>
      <div v-for="profile of profiles" :key="profile.oid">
        <SavedInTagsProfile :profile="profile" />
      </div>
      <div class="connections" v-for="connection of connections" :key="connection.oid">
        <SavedInTagsConnection :edge="connection" :key="Math.random()"/>
      </div>
    </div>
  </div>
</template>

<script>
import SavedInTagsProfile from './SavedInTagsProfile';
import SavedInTagsConnection from './SavedInTagsConnection';
import SavedInTagsSection from './SavedInTagsSection';

export default {
  name: 'SavedInTags',
  computed: {
    showAll () {
      return this.$store.state.tags.showAllSavedInTags;
    },
    tags () {
      return this.$store.getters.notEmptyTags;
    },
    profiles () {
      return this.$store.getters.savedInTagsProfiles;
    },
    connections () {
      return this.$store.getters.savedInTagsConnections;
    },
    checkedEntities () {
      const entities = [];
      const addCheckedEntities = (map) => {
        Object.keys(map).forEach(oid => {
          const ent = map[oid];
          if (ent.checked) {
            entities.push(ent);
          }
        });
      };
      addCheckedEntities(this.$store.getters.resultsMap);
      addCheckedEntities(this.$store.getters.connectionEntitiesMap);
      addCheckedEntities(this.$store.getters.edgesMap);
      return entities;
    }
  },
  components: {
    SavedInTagsProfile,
    SavedInTagsConnection,
    SavedInTagsSection
  }
};
</script>

<style lang="scss" scoped>

</style>
