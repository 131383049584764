<template>
  <div>
    <template v-if="!openFastReport && !openConnectionsPage">
      <div class="d-flex justify-between mb-24">
        <button class="opacity-btn mr-16" @click="backToResults" id="back"><Icon name="arrow-left-blue" class="mr-8" /> Back</button>
        <button
          class="primary-btn typography-base"
          @click="openModal = true"
          v-if="hasInfoForLoad"
          id="fast-report"
        >
          Fast report
        </button>
        <button
          class="primary-btn typography-base"
          @click="openPreview"
          id="fast-report"
          v-else
        >
          Fast report
        </button>
      </div>
      <div>
        <div class="tagged-profile-card d-flex">
          <div class="multiprofile-avatar-block">
            <img :src="avatar" alt="" class="circle-avatar mb-12" @load="avatarLoadHandler" @error="avatarErrorHandler" :class="loadImage ? 'd-block' : 'd-none'">
            <img src="@/assets/icons/avatar.png" alt="" class="circle-avatar mb-12" :class="loadImage ? 'd-none' : 'd-block'">
            <h3 class="typography-12 color-miniheader subhead-block">{{mainName}}</h3>
            <div class="typography-theme color-light subhead-block mb-24">{{mainInfo.location?.value}}</div>
            <!-- <button class="link typography-7 let-space-08">
              <div class="tag-circle mr-12 icon-size" style="background:'#eff4f7'">
                <Icon name="tag" color="#60BCE3" />
              </div>
              {{tagInfoName ? `Saved in ${tagInfoName}` : 'Save in tag'}}
            </button> -->
          </div>
          <div class="w-100">
            <ul class="profile-info-list mb-24">
              <li v-for="(val, key) in mainInfo" :key="key" class="d-flex">
                <p class="color-1 typography-7 subhead-width">{{key}}</p>
                <div class="d-flex justify-between align-start w-100">
                  <span class="lh-24" v-html="val.value" v-linkified></span>
                  <div class="d-flex align-center mt-6">
                    <span class="icon-wrap" v-for="social in val.socialNetwork" :key="social">
                      <Icon :name="socialNetworkIcon(social)" color="#A0B2BE" />
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="d-flex justify-between mb-24">
              <button class="primary-btn typography-base mr-16"
                @click="getUserDetails"
                id="user-details-btn"
                v-if="userDetailsFullPrice"
              >
                Get more info ({{userDetailsFullPrice}} slp)
              </button>
              <button class="opacity-btn" @click="findConnections" v-if="findConnectionsPrice">Explore connection ({{findConnectionsPrice}} slp)</button>
              <button class="opacity-btn" @click="showConnectionsPage" v-else>Open connections</button>
            </div>

            <div v-for="(type, name) in allUserDetails" :key="type">
              <p class="typography-theme mb-12">{{name}}</p>
              <ul class="profile-info-list mb-24">
                <li v-for="(row, propertyName) in type" :key="row" class="d-flex justify-between">
                  <div class="subhead-width" v-if="name === 'Social events'"><img src="@/assets/icons/social-event.png" alt="" class="big-icon"></div>
                  <div v-for="(val, key) in row" :key="key"  :class="{'d-flex': setClass1(name), 'w-100': key !== 'socialNetwork'}">
                    <template v-if="key !== 'socialNetwork'">
                      <p class="color-1 typography-7" :class="setClass2(name)" v-html="propertyName"></p>
                      <div v-if="typeof(val) === 'object' && val.length" class="d-flex flex-column">
                        <span class="lh-24" v-for="v in val" :key="v" v-html="v"></span>
                      </div>
                      <span class="lh-24" v-else v-html="val"></span>
                    </template>
                    <span class="icon-wrap mt-6" v-for="network in val" :key="network" v-else>
                      <Icon :name="socialNetworkIcon(network)" color="#A0B2BE" />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="results-filter d-flex justify-between">
          <div class="results-filter__categories">
            <div class="profiles d-flex flex-wrap m-0">
              <div class="profile-card-wrap" v-for="(entity, idx) in profiles" :key="entity.value + idx">
                <ResultsEntity :entity="entity" />
              </div>
            </div>
          </div>
          <div class="results-filter__select">
            <input type="checkbox" class="sidebar-checkbox" :checked="currentMultiprofile.checked" id="checkMultiprofile">
            <label class="sidebar-checkbox__label" @click.stop="checkMultiprofile"></label>
            <div class="mt-16 typography-9">Select all</div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="openConnectionsPage">
      <div class="search-input">
        <h3>Explore connections</h3>
        <div class="search-input__block justify-between align-center">
          <div class="search-input__info">
            <Icon name="tagged-connection-icon" color="#BFD2DF" />
            <p>{{ mainName }} multiprofile</p>
          </div>
        </div>
        <div class="d-flex mr-12">
          <button id="connections-controller-back" class="opacity-btn typography-base mt-24" @click="openConnectionsPage = false">
            <Icon name="arrow-left-blue" class="mr-14"></Icon>
            Back
          </button>
        </div>
      </div>
      <ConnectionsSearch :key="connections.length" v-if="!$store.getters.getSearching && visibleConnections.length" />
      <ConnectionsFilter :key="connectionsFilterID" v-if="!$store.getters.getSearching && visibleConnections.length" />
      <div class="score-desc mb-32" v-if="connectionsToShow?.length">
        <p class="fw-600">Connection score</p>
        <p><span class="fw-600">Friend (+50 points) –</span> the profile is in the subjects’s friend list</p>
        <p><span class="fw-600">Tagged with (+30 points) –</span> the person was tagged in a photo with the subject</p>
        <p><span class="fw-600">Liked post/photo (+3 points) –</span> the person liked the user's post or photo</p>
        <p><span class="fw-600">Commented post/photo (+3 points) –</span> the person commented on the  subject's post or photo</p>
      </div>
      <div class="connections" v-for="connection in connectionsToShow" :key="connection.oid">
        <Connection :connection="connection" :source="connection.parent" @openSidebar="openSidebar" />
      </div>
      <div ref="end-of-connections"></div>
    </div>
    <FastReportModal
      @closeModal="openModal = false"
      v-if="openModal"
    />
    <FastReport
      :profiles="profiles"
      v-if="openFastReport"
    />
  </div>
  <ProfileSidebar v-if="sourceProfile" :profile="sourceProfile" :styles="sidebarStyles" @closeProfileSidebar="closeProfileSidebar(0)" :tagInfo="savedTagInfoLeft" @showTagsPopup="showTagsPopup(2)" />
  <div class="popup-container right" v-if="sidebarTagPopupOpenRight && connectedProfile"><TagPopup :profiles="[connectedProfile]" @closeTagsPopup="sidebarTagPopupOpenRight = false" @showSavedTag="showSavedTag" :class="{fixed: sidebarTagPopupOpenRight}"/></div>
  <ProfileSidebar v-if="sidebarProfile" :profile="sidebarProfile" @closeProfileSidebar="closeProfileSidebar(1)" :tagInfo="savedTagInfoRight" @showTagsPopup="showTagsPopup(1)"/>
  <div class="popup-container left"  v-if="sidebarTagPopupOpenLeft" :class="{shifted: shifted && !sidebarProfile }"><TagPopup :profiles="[sourceProfile]" @closeTagsPopup="sidebarTagPopupOpenLeft = false" @showSavedTag="showSavedTag" :class="{fixed: sidebarTagPopupOpenLeft}"/></div>
  <div class="aside-backwall" v-if="sourceProfile || sidebarProfile"></div>
</template>

<script>
import Icon from '../app/Icon';
import { mapGetters } from 'vuex';
import ResultsEntity from '../ResultsEntity';
import { getEntityAvatar, eventListener } from '../../utils/helpers';
import smartlookClient from 'smartlook-client';
import Connection from '../Connection';
import ProfileSidebar from '../app/ProfileSidebar';
import TagPopup from '../TagPopup.vue';
import FastReportModal from '../report/FastReportModal.vue';
import FastReport from '../report/FastReport.vue';
import ConnectionsFilter from '../ConnectionsFilter';
import ConnectionsSearch from '../ConnectionsSearch';
import profilePreviewMixin from '@/mixins/profilePreviewMixin';

export default {
  name: 'Multiprofile',
  components: {
    Icon,
    ResultsEntity,
    Connection,
    ProfileSidebar,
    TagPopup,
    FastReportModal,
    FastReport,
    ConnectionsFilter,
    ConnectionsSearch
  },
  data () {
    return {
      loadingCount: 10,
      loadedCount: 0,
      sidebarStyles: 'right: 322px',
      sidebarProfile: null,
      sourceProfile: null,
      sidebarTagPopupOpenRight: false,
      sidebarTagPopupOpenLeft: false,
      connectedProfile: {},
      shifted: false,
      savedTagInfoLeft: {
        name: '',
        color: ''
      },
      savedTagInfoRight: {
        name: '',
        color: ''
      },
      openModal: false,
      loadImage: false,
      openConnectionsPage: false,
      sortedConnByScore: {
        's-3+': true,
        's-50+': true,
        's-150+': true,
        's-250+': true
      }
    };
  },
  mixins: [profilePreviewMixin],
  mounted () {
    this.loadedCount += this.loadingCount;
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll();
    });

    this.$store.commit('setMultiprofileScore', { mp: this.currentMultiprofile.id, score: this.sortedConnByScore });
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    ...mapGetters(['currentMultiprofile', 'resultsMap', 'accountState', 'connections', 'sortedRelationSearches', 'sortedConnForMultiprofile', 'openFastReport', 'results', 'visibleConnections']),
    connectionsToShow () {
      const visibleConnections = this.sortedConnForMultiprofile.filter(conn => conn.visible);
      return visibleConnections.filter((conn, idx) => {
        return idx <= this.loadedCount;
      });
    },
    profiles () {
      const profiles = [];
      this.currentMultiprofile && this.currentMultiprofile.entity_ids.forEach(id => {
        for (const key in this.resultsMap) {
          if (Object.prototype.hasOwnProperty.call(this.resultsMap, key) && key === id) {
            profiles.push(this.resultsMap[key]);
          }
        }
      });
      return profiles;
    },
    mainInfo () {
      return this.getInfoOfProfiles(this.profiles);
    },
    userDetailsObjForSorting () {
      return this.getUserDetails(this.profiles);
    },
    allUserDetails () {
      return this.getSortingUserDetails(this.userDetailsObjForSorting);
    },
    userDetailsFullPrice () {
      const transforms = this.accountState.transforms.filter(transform => {
        return transform.name === '/facebook/user/v4' || transform.name === '/instagram/user' || transform.name === '/twitter/user' || transform.name === '/linkedin/user';
      });
      let price = 0;
      this.profiles.forEach(profile => {
        if (!profile.fields['user-details']) {
          if (profile.typeid === 'maltego.facebook.profile') {
            price = this.setPrice(transforms, '/facebook/user/v4', price);
          } else if (profile.typeid === 'maltego.affiliation.Twitter') {
            price = this.setPrice(transforms, '/twitter/user', price);
          } else if (profile.typeid === 'maltego.instagram.profile') {
            price = this.setPrice(transforms, '/instagram/user', price);
          } else {
            price = this.setPrice(transforms, '/linkedin/user', price);
          }
        }
      });
      return price;
    },
    profilesWithConnections () {
      const profilesWithConnections = new Set();
      const set = new Set();
      this.sortedRelationSearches.forEach(relation => {
        relation.targets.forEach(target => {
          set.add(target);
        });
      });
      set.forEach(id => {
        const profile = this.profiles.find(profile => {
          return id === profile.oid;
        });
        if (profile) {
          profilesWithConnections.add(profile.oid);
        }
      });
      return profilesWithConnections;
    },
    profilesForLoadConnections () {
      const profilesForLoadConnections = [];
      this.profiles.forEach(profile => {
        if (!this.profilesWithConnections.has(profile.oid)) {
          profilesForLoadConnections.push(profile);
        }
      });
      return profilesForLoadConnections;
    },
    findConnectionsPrice () {
      const transforms = this.accountState.transforms.filter(transform => {
        return transform.name === '/socializer/instagram/connections' || transform.name === '/socializer/facebook/connections' || transform.name === '/socializer/twitter/connections' || transform.name === '/socializer/linkedin/connections';
      });
      let price = 0;
      this.profilesForLoadConnections.forEach(profile => {
        if (profile.typeid === 'maltego.facebook.profile') {
          price = this.setPrice(transforms, '/socializer/facebook/connections', price);
        } else if (profile.typeid === 'maltego.affiliation.Twitter') {
          price = this.setPrice(transforms, '/socializer/twitter/connections', price);
        } else if (profile.typeid === 'maltego.instagram.profile') {
          price = this.setPrice(transforms, '/socializer/instagram/connections', price);
        } else {
          price = this.setPrice(transforms, '/socializer/linkedin/connections', price);
        }
      });
      return price;
    },
    mainName () {
      function sortNames (maininfo, socialNetwork) {
        const socialNetworks = maininfo.name.socialNetwork;
        for (let i = 0; i < socialNetworks.length; i++) {
          if (socialNetworks[i] === socialNetwork) {
            return maininfo.name.value[i];
          }
        }
        return '';
      }
      if (this.mainInfo && this.mainInfo.name) {
        let sortedName;
        sortedName = sortNames(this.mainInfo, 'maltego.facebook.profile');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.mainInfo, 'maltego.linkedin.profile');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.mainInfo, 'maltego.affiliation.Twitter');
        if (sortedName) {
          return sortedName;
        }
        sortedName = sortNames(this.mainInfo, 'maltego.instagram.profile');
        if (sortedName) {
          return sortedName;
        }
      }
      return '';
    },
    avatar () {
      let profile;
      function sortProfiles (profiles, type) {
        return profiles.find(profile => {
          return profile.typeid === type;
        });
      }
      profile = sortProfiles(this.profiles, 'maltego.facebook.profile');
      if (profile) {
        return getEntityAvatar(profile);
      }
      profile = sortProfiles(this.profiles, 'maltego.linkedin.profile');
      if (profile) {
        return getEntityAvatar(profile);
      }
      profile = sortProfiles(this.profiles, 'maltego.affiliation.Twitter');
      if (profile) {
        return getEntityAvatar(profile);
      }
      profile = sortProfiles(this.profiles, 'maltego.instagram.profile');
      if (profile) {
        return getEntityAvatar(profile);
      }
      return '';
    },
    hasInfoForLoad () {
      const index = this.profiles.findIndex((profile) => {
        return !profile.fields['user-details'];
      });
      return index !== -1 || this.profilesForLoadConnections.length;
    }
  },
  methods: {
    avatarLoadHandler () {
      this.loadImage = true;
    },
    avatarErrorHandler () {
      this.loadImage = false;
    },
    checkMultiprofile () {
      const check = !this.currentMultiprofile.checked;
      this.profiles.forEach(profile => {
        profile.checked = check;
      });
      const obj = {
        id: this.currentMultiprofile.id,
        check: check
      };
      this.$store.commit('checkMultiprofile', obj);
    },
    socialNetworkIcon (socialNetwork) {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (socialNetwork.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    },
    backToResults () {
      this.$store.commit('openMultiprofile', false);
      this.$store.commit('openResults', true);
      eventListener.emit('clearFilter');
    },
    async getUserDetails () {
      this.$store.commit('setSearching', true);
      this.$store.commit('setSearchingUserDetails', true);
      const promises = [];
      this.profiles.forEach(profile => {
        if (!profile.fields['user-details']) {
          promises.push(this.$store.dispatch('getUserDetails', profile));
        }
      });
      Promise.all(promises).then(() => {
        this.$store.commit('setSearchingUserDetails', false);
        this.$store.commit('setSearching', false);
        smartlookClient.track('spent_slp', { where: 'profile details', quantity: this.userDetailsFullPrice });
      });
    },
    setClass1 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' && field !== 'Projects' ? 'd-flex align-start' : '';
    },
    setClass2 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' ? 'subhead-width' : '';
    },
    async findConnections () {
      this.$store.commit('setSearching', true);
      this.$store.commit('setCallConnFromMultiprofile', true);
      const connections = this.connections;
      const promises = [];
      this.profilesForLoadConnections.forEach(profile => {
        promises.push(this.$store.dispatch('findConnections', [profile]));
      });
      Promise.all(promises).then(() => {
        // We add already found connections, if there are any
        connections.forEach(connection => {
          this.$store.commit('addConnection', connection);
        });
        this.$store.commit('setSearching', false);
        this.$store.commit('openMultiprofile', true);
        this.$store.commit('searchConnections', false);
        this.$store.commit('setCallConnFromMultiprofile', false);
        this.openConnectionsPage = true;
      });
    },
    onScroll () {
      const end = this.$refs['end-of-connections'];
      if (end) {
        const marginTop = end.getBoundingClientRect().top;
        const innerHeight = window.innerHeight;
        if (marginTop <= innerHeight) {
          this.loadedCount += this.loadingCount;
        }
      }
    },
    setPrice (transforms, type, price) {
      transforms.forEach(transform => {
        if (transform.name === type) {
          price = price + transform.price;
        }
      });
      return price;
    },
    closeProfileSidebar (idx) {
      const elem = document.getElementsByClassName('profile-sidebar')[idx];
      if (elem) {
        elem.classList.remove('profile-sidebar--show');
      } else {
        document.getElementsByClassName('profile-sidebar')[0].classList.remove('profile-sidebar--show');
      }
      setTimeout(() => {
        if (idx === 0) {
          this.sidebarTagPopupOpenLeft = false;
          this.savedTagInfoLeft.name = '';
          this.savedTagInfoLeft.color = '';
          this.sourceProfile = null;
        }
        if (idx === 1) {
          this.sidebarTagPopupOpenRight = false;
          this.sidebarStyles = '';
          this.shifted = true;
          this.savedTagInfoRight.name = '';
          this.savedTagInfoRight.color = '';
        }
        if (idx) {
          this.sidebarProfile = null;
          this.sidebarStyles = '';
        }
      }, 300);
    },
    openSidebar (connectionProfiles) {
      this.sidebarProfile = connectionProfiles.connection;
      this.sourceProfile = this.results.find(result => {
        return connectionProfiles.parentId === result.oid;
      });
      this.sidebarStyles = 'right: 322px';
      setTimeout(() => {
        document.getElementsByClassName('profile-sidebar').forEach(el => {
          el.classList.add('profile-sidebar--show');
        });
      }, 300);
    },
    showSavedTag (obj) {
      if (this.sidebarTagPopupOpenLeft) {
        this.savedTagInfoLeft.name = obj.name;
        this.savedTagInfoLeft.color = obj.color;
      }
      if (this.sidebarTagPopupOpenRight) {
        this.savedTagInfoRight.name = obj.name;
        this.savedTagInfoRight.color = obj.color;
      }
    },
    showTagsPopup (value) {
      if (value === 1) {
        this.sidebarTagPopupOpenRight = true;
      }
      if (value === 2) {
        this.sidebarTagPopupOpenLeft = true;
      }
      const result = {};
      for (const key in this.sidebarProfile) {
        if (Object.prototype.hasOwnProperty.call(this.sidebarProfile, key)) {
          if (key.toLowerCase().trim().indexOf('parent') !== -1) continue;
          result[key] = this.sidebarProfile[key];
        }
      }
      this.connectedProfile = result;
    },
    openPreview () {
      this.$store.commit('setOpenFastReport', true);
    },
    showConnectionsPage () {
      this.openConnectionsPage = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.tagged-profile-card {
  padding-right: 64px;
}
.multiprofile-avatar-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  width: 100%;
  margin-left: -24px;
}
.profile-info-list p::first-letter {
  text-transform: uppercase;
}
.subhead-block {
  word-wrap: break-word;
  text-align: center;
  width: 100%;
  padding: 0 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.results-filter__categories {
  padding-left: 18px;
  padding-right: 18px;
}
.profiles .profile-card {
  margin-bottom: 0;
}
.popup-container {
  position: fixed;
  top: 144px;
  z-index: 2001;
  height: 78px;
  width: 300px;
}
.left {
  right: 383px;
}
.right {
  right: 60px;
}
.shifted {
  right: 60px !important;
  transition: .4s;
}
</style>
