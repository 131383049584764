<template>
  <button class="details-block-btn" @click="opened = !opened">
    {{ name }}
    <img src="@/assets/icons/arrow.svg" alt="" class="ml-10" :class="{ rotated: opened }" />
  </button>
  <ul class="profile-info-list" v-if="opened">
    <li v-for="(row, idx) in type" :key="row + idx">
      <div class="d-flex flex-column" v-for="(val, key) in row" :key="key" v-linkified>
        <p class="color-1 typography-7" v-html="key"></p>
        <template v-if="typeof(val) === 'object' && val.length">
          <span v-for="v in val" :key="v" v-html="v"></span>
        </template>
        <span v-else v-html="val"></span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ProfileSidebarInfoGroup',
  props: ['name', 'type'],
  data () {
    return {
      opened: true
    };
  }
};
</script>

<style scoped>
  .rotated {
    transform: rotate(-180deg);
  }
</style>
