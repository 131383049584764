<template>
  <form class="search-form mb-32">
    <div class="d-flex align-center mb-16" v-if="caseName">
      <div class="case-name-container">
        <Tooltip
          :text="customCaseName || currentCaseName"
          :limit="26"
          className="case-length-counter"
        />
        <input
          type="text"
          :placeholder="!isCaseNameInputActive ? currentCaseName.substring(0,25) : currentCaseName"
          :disabled="!isCaseNameInputActive"
          id="case-name-input"
          v-model="customCaseName"
          :class="{ 'edit': isCaseNameInputActive }"
          @keyup.enter="saveCaseName"
        />
      </div>
      <button
        id="edit-case-name"
        type="button"
        v-if="!isCaseNameInputActive"
        @click.prevent="editCaseName"
        class="mb-4 ml-16"
      >
        <Icon name="edit" color="#60BCE3" class="icon-hover" />
      </button>
      <div class="d-flex" v-else>
        <div class="mr-16 color-2 typography-8 ml-16">
          <span :class="{ 'color-warning': customCaseName.length > limit }">
            {{ customCaseName.length }}
          </span>
            /{{ limit }}  would be visible</div>
        <button
          type="button"
          @click.prevent="saveCaseName"
          class="mr-8"
          id="save-case-name"
          v-if="customCaseName.trim().length >= 1"
        >
          <Icon name="ok" />
        </button>
        <button id="cancel-case-name" type="button" @click.prevent="cancelCaseEditing"><Icon name="cancel" /></button>
      </div>
    </div>
    <slot></slot>
    <div class="info-warning typography-8" v-if="infoWarning">
      <span>Please, note: LinkedIn life search is temporarily unavailable.</span>
      <button class="cancel-btn" @click="infoWarning = false"><Icon name="cancel-btn" /></button>
    </div>
    <section class="common-data">
      <h2 class="search-form-heading-2">Common data</h2>
      <div class="input-block name mb-12" :class="{alert: nameValidationNotification && validationPass}">
        <div>
          <label for="name">SUBJECT NAME</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter name of person"
            v-model.trim="v$.name.$model"
            maxlength="64"
          >
          <div class="input-description mt-12 name-error" v-if="nameValidationNotification && validationPass">inavalid name. please do not use digits (0-9) and special characters (!, #, %, etc)</div>
        </div>
        <div>
          <div class="d-flex justify-between">
            <label for="location">Location</label>
            <label for="location" class="additional-label">SEARCH ONLY WITH NAME</label>
          </div>
          <input
            type="text"
            name="location"
            id="location"
            placeholder="Enter region / city / town"
            v-model.trim="location"
            maxlength="64"
          >
        </div>
      </div>
      <div class="d-flex">
        <button
          class="search-field-btn wide"
          :class="{ active: !hiddenFields.education }"
          @click.prevent="deleteEducation()"
          type="button"
          id="education-btn"
        >
          <Icon :name="hiddenFields.education ? 'plus' : 'filled-check'" />Education ({{ educationPrice }} slp)
        </button>
        <button
          class="search-field-btn wide"
          :class="{ active: !hiddenFields.company }"
          @click.prevent="deleteCompany()"
          type="button"
          id="company-btn"
        >
          <Icon :name="hiddenFields.company ? 'plus' : 'filled-check'" />Company ({{ companyPrice }} slp)
        </button>
      </div>
      <div v-if="!hiddenFields.education" class="mt-16">
        <label for="education">Education</label>
        <div class="input-block-wide">
          <input
            type="text"
            id="education"
            name="education"
            placeholder="School or university"
            v-model.trim="education"
            maxlength="64"
          >
          <span class="input-description">Work only with name, We will search in facebook</span>
        </div>
      </div>
      <div v-if="!hiddenFields.company" class="mt-16">
        <label for="company">Company</label>
        <div class="input-block-wide">
          <input
            type="text"
            id="company"
            name="company"
            placeholder="Company name"
            v-model.trim="company"
            maxlength="64"
          >
          <span class="input-description">Work only with name, We will search in facebook</span>
        </div>
      </div>
    </section>
    <section class="unique-parameters">
      <h2 class="search-form-heading-2">Unique parameters</h2>
      <label for="username">Username (alias)</label>
      <div class="input-block-wide mb-12" :class="{ 'alert': v$.alias.aliasValidator.$invalid && validationPass }">
        <input
          type="text"
          id="username"
          name="username"
          placeholder="Enter username"
          v-model.trim="v$.alias.$model"
          maxlength="64"
        >
        <span class="input-description" v-if="v$.alias.aliasValidator.$invalid && validationPass">
          Invalid alias, please do not use special characters ($,^,& etc)
        </span>
        <span class="input-description" v-else>
          FACEBOOK / LINKEDIN / INSTAGRAM / TWITTER
        </span>
      </div>
      <div class="d-flex mb-24 flex-wrap">
        <button
          class="search-field-btn"
          :class="{ active: !hiddenFields.photo }"
          @click.prevent="deletePhoto()"
          type="button"
          id="photo-btn"
          :disabled="disabled.photo"
        >
          <Icon :name="hiddenFields.photo ? 'plus' : 'filled-check'" />Photo ({{ photoPrice }} slp)
        </button>
        <button
          class="search-field-btn wide"
          :class="{ active: !hiddenFields.photoUrl }"
          @click.prevent="deletePhotoUrl()"
          type="button"
          :disabled="disabled.photoUrl"
          id="photo-url-btn"
        >
          <Icon :name="hiddenFields.photoUrl ? 'plus' : 'filled-check'" />Photo url ({{ photoPrice }} slp)
        </button>
        <button
          class="search-field-btn wide"
          :class="{ 'active': !hiddenFields.profileUrl }"
          @click.prevent="deleteProfileUrl()"
          type="button"
          id="profile-url-btn"
        >
          <Icon :name="hiddenFields.profileUrl ? 'plus' : 'filled-check'" />Profile url ({{ profileUrlPrice }} slp)
        </button>
        <button
          class="search-field-btn wide"
          :class="{ 'active': !hiddenFields.profileID }"
          @click.prevent="deleteProfileID()"
          type="button"
          id="profile-id-btn"
        >
          <Icon :name="hiddenFields.profileID ? 'plus' : 'filled-check'" />Profile ID ({{ profileIDPrice }} slp)
        </button>
        <button
          class="search-field-btn"
          :class="{ 'active': !hiddenFields.phone }"
          @click.prevent="deletePhone()"
          type="button"
          id="phone-btn"
        >
          <Icon :name="hiddenFields.phone ? 'plus' : 'filled-check'" class="mr-6" />Phone number ({{ phonePrice }} slp)
        </button>
        <button
          class="search-field-btn"
          :class="{ 'active': !hiddenFields.email }"
          @click.prevent="deleteEmail()"
          type="button"
          id="email-btn"
        >
          <Icon :name="hiddenFields.email ? 'plus' : 'filled-check'" />Email ({{ emailPrice }} slp)
        </button>
      </div>
      <div class="upload mb-12" v-if="!hiddenFields.photo">
        <label v-if="!photo"
               for="upload-photo"
               role="button"
               class="upload__img"
        >
          <Icon name="upload-icon" />
          <input type="file" id="upload-photo" class="d-none" accept=".jpg, .jpeg, .png" @change.prevent="onImageChange">
          <span class="upload-photo-text">
            <span>Click here</span>
            <br>
            <span>to upload</span>
          </span>
        </label>
        <div v-else class="upload__photo">
          <img :src="url(photo)" class="mb-6" width="260" height="200" alt="">
        </div>
        <div class="upload__info">
          <h3 class="search-form-heading-3 mb-8" :style="photo && 'color: #585859'">
            {{ (!photo) ? 'Upload subject’s photo' : photo.name }}
          </h3>
          <div v-if="!photo">
            <p class="additional-label mb-8">SEARCH ONLY WITH NAME</p>
            <p class="upload__description mb-8">
              Supported file formats: .png and .jpeg. Max size: {{ imageMaxSize }} MB
            </p>
            <a class="upload__description" @click.prevent="[deletePhoto(), deletePhotoUrl()]">Upload by URL</a>
          </div>
          <div class="photo-buttons d-flex" v-else>
            <button id="edit-photo" type="button" class="mr-16" @click.prevent="updatePhoto">Edit</button>
            <button id="delete-photo" type="button" @click.prevent="deletePhoto">Delete</button>
          </div>
        </div>
      </div>
      <div class="mb-24 img-error" v-if="imageError.length"><span class="input-description"> inavalid image. Supported file formats: .png and .jpeg. Max size: 1 MB</span></div>
      <div v-if="!hiddenFields.photoUrl">
        <label for="profile-url">Photo url</label>
        <div class="input-block-wide mb-24" :class="{ alert: v$.photoUrl.url.$invalid && validationPass }">
          <input
            type="url"
            id="photo-url"
            name="photo-url"
            placeholder="Paste the link"
            v-model.trim="v$.photoUrl.$model"
          >
          <span class="input-description" v-if="v$.photoUrl.url.$invalid && validationPass">
            Invalid link
          </span>
          <div class="input-description d-flex justify-between" v-else>
           <span>Work only with name, We will search in facebook, linkedin, instagram, twitter</span>
           <span class="color-theme pointer capitalize typography-7" @click.prevent="[deletePhoto(), deletePhotoUrl()]">Upload by photo</span>
          </div>
        </div>
      </div>
      <div v-if="!hiddenFields.profileUrl">
        <label for="profile-url">Profile url</label>
        <div class="input-block-wide mb-24" :class="{ 'alert': v$.profileUrl.profileUrlValidator.$invalid && validationPass }">
          <input
            type="url"
            id="profile-url"
            name="profile-url"
            placeholder="Paste the link"
            v-model.trim="v$.profileUrl.$model"
            maxlength="94"
          >
          <span class="input-description" v-if="v$.profileUrl.profileUrlValidator.$invalid && validationPass">
            Invalid link
          </span>
          <span class="input-description" v-else>
            FACEBOOK / LINKEDIN / INSTAGRAM / TWITTER
          </span>
        </div>
      </div>
      <div v-if="!hiddenFields.profileID">
        <label for="profile-url">Profile id</label>
        <div class="input-block-wide mb-24" :class="{ alert: v$.profileID.profileIDValidator.$invalid && validationPass }">
          <input
            type="text"
            id="profile-id"
            name="profile-id"
            placeholder="Paste the ID"
            v-model.trim="v$.profileID.$model"
            maxlength="64"
          >
          <span class="input-description" v-if="v$.profileID.profileIDValidator.$invalid && validationPass">
            Invalid id
          </span>
          <span class="input-description" v-else>
            FACEBOOK / LINKEDIN / INSTAGRAM / TWITTER
          </span>
        </div>
      </div>
      <div v-if="!hiddenFields.phone">
        <label for="phone-input">Phone number</label>
        <div class="input-block-wide mb-24" :class="{ 'alert': v$.phone.checkPhoneInputError.$invalid && validationPass }">
          <input type="tel" id="phone-input" name="phone" placeholder="Type number" v-model.trim="v$.phone.$model">
          <span class="input-description" v-if="v$.phone.checkPhoneInputError.$invalid && validationPass">
            Invalid number, please use valid digit number (+1 000 000 00 00)
          </span>
          <div class="input-description d-flex justify-between" v-else>
            <span>FACEBOOK / LINKEDIN / INSTAGRAM / TWITTER</span>
            <span>DATA MAY BE OUT OF DATE</span>
          </div>
        </div>
      </div>
      <div v-if="!hiddenFields.email">
        <label for="email">Email</label>
        <div class="input-block-wide mb-24" :class="{ alert: v$.email.email.$invalid && validationPass }">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Type email"
            v-model.trim="v$.email.$model"
            maxlength="64"
          >
          <span class="input-description" v-if="v$.email.email.$invalid && validationPass">
            Invalid Email, please use valid email (example@domain.com)
          </span>
          <span class="input-description" v-else>
            FACEBOOK / LINKEDIN / INSTAGRAM / TWITTER
          </span>
        </div>
      </div>
      <div class="d-flex flex-wrap align-center slpTooltip">
        <button
          id="search-btn"
          type="submit"
          class="primary-btn typography-base mr-24 custom-btn"
          @click.prevent="submitHandler"
          :disabled="disableSearch || disableSearchByFields"
          @mouseover="showTooltip = true"
          @mouseleave="showTooltip = false"
        >
          Start live search ({{ totalPrice }} SLP)
        </button>
        <div class="tooltip-text d-flex flex-column" v-if="disableSearch">
          <span>There are not enough SLP on your account</span>
          <router-link :to="'/plan'"  class="color-theme typography-7">
          Buy more
          </router-link>
        </div>
      </div>
    </section>
  </form>
</template>

<script>
import smartlookClient from 'smartlook-client';
import Icon from './Icon.vue';
import { maxLength, email, url } from '@vuelidate/validators';
import { nameInAllLanguages, checkPhoneInputError, aliasValidator, profileUrlValidator, profileIDValidator } from '@/utils/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapGetters } from 'vuex';
import amplitude from 'amplitude-js';
import Tooltip from '@/components/UI/Tooltip';

export default {
  components: {
    Icon,
    Tooltip
  },
  props: ['caseName', 'searchQueries'],
  created () {
    const searchQueries = this.searchQueries || this.openSearch?.query;
    if (searchQueries) {
      this.name = searchQueries.name;
      this.alias = searchQueries.alias;
      this.email = searchQueries.email;
      this.location = searchQueries.location;
      this.phone = searchQueries.phone;
      this.education = searchQueries.education;
      this.company = searchQueries.company;
      this.profileUrl = searchQueries.profile_url;
      this.profileID = searchQueries.profile_id;
      this.setPhotoByQueryUrl(searchQueries.image);
      this.hiddenFields.education = !this.education;
      this.hiddenFields.company = !this.company;
      this.hiddenFields.photo = !this.photo;
      this.hiddenFields.photoUrl = !this.photoUrl;
      this.hiddenFields.profileUrl = !this.profileUrl;
      this.hiddenFields.profileID = !this.profileID;
      this.hiddenFields.phone = !this.phone;
      this.hiddenFields.email = !this.email;
      this.disabled.photo = !!this.photoUrl;
      this.disabled.photoUrl = !!this.photo;
    }
    this.subscriptionTransforms = this.$store.getters.accountState?.transforms || [];
  },
  data () {
    return {
      name: '',
      education: '',
      company: '',
      photoUrl: '',
      profileUrl: '',
      profileID: '',
      alias: '',
      photo: null,
      location: '',
      locations: [],
      showLocationInformer: false,
      locationError: '',
      maxLocationLength: 64,
      email: '',
      phone: '',
      imageError: '',
      isCaseNameInputActive: false,
      customCaseName: '',
      imageMaxSize: process.env.VUE_APP_PHOTO_MAX_UPLOAD_SIZE_MB,
      disabled: { photo: false, photoUrl: false },
      hiddenFields: {
        education: true,
        company: true,
        photo: true,
        photoUrl: true,
        profileUrl: true,
        profileID: true,
        phone: true,
        email: true
      },
      subscriptionTransforms: [],
      infoWarning: false,
      transforms: [
        {
          template: 'facebook_search_profiles_detailed_onlyby_n2',
          name: 'fbDTCsearchPerson3',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'instagram_search_person',
          name: 'instagramSearchPerson',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'premium_search_people_linkedin',
          name: 'searchLinkedInPersonNew',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'twitter_search_users',
          name: 'twitterSearchUsersFromPerson',
          entityType: 'maltego.Person',
          searchType: 'name'
        },
        {
          template: 'linkedin_lookup_by_email_v2',
          name: 'linkedinLookupByEmail2',
          entityType: 'maltego.EmailAddress',
          searchType: 'email'
        },
        {
          template: 'facebook_search_profiles_detailed_v2_graph',
          name: 'facebookSearchProfilesDetailed2graph',
          entityType: 'maltego.SearchPerson',
          searchType: 'name,education'
        },
        {
          template: 'facebook_search_profiles_detailed_v2_graph',
          name: 'facebookSearchProfilesDetailed2graph',
          entityType: 'maltego.SearchPerson',
          searchType: 'name,company'
        },
        {
          template: 'sm2_facebook_by_searchface',
          name: 'sm2FacebookBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'sm2_instagram_by_searchface',
          name: 'sm2InstagramBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'sm2_linkedin_by_searchface',
          name: 'sm2LinkedinBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'sm2_twitter&name_field=fullname&photo_field=photo',
          name: 'sm2TwitterBySearchFace',
          entityType: 'maltego.SearchFace',
          searchType: 'name,photo'
        },
        {
          template: 'facebook_to_entity_v2&query_field_name=query',
          name: 'aliasToFacebook2',
          entityType: 'maltego.Alias',
          searchType: 'alias'
        },
        {
          template: 'instagram_search_person',
          name: 'instagramSearchUser',
          entityType: 'maltego.Alias',
          searchType: 'alias'
        },
        {
          template: 'twitter_alias_to_user',
          name: 'twitterSearchUsersFromAlias',
          entityType: 'maltego.Alias',
          searchType: 'alias'
        },
        {
          template: 'delayed_facebook_search_users',
          name: 'facebookSearchProfilesDetailedDelayedR',
          entityType: 'maltego.delayed.facebook.search.users',
          searchType: 'name,location'
        },
        {
          template: 'facebook_to_entity_v2&query_field_name=query',
          name: 'aliasToFacebook2',
          entityType: 'maltego.Alias',
          searchType: 'profile_url'
        },
        {
          template: 'alias_to_instagram',
          name: 'aliasToInstagram2',
          entityType: 'maltego.Alias',
          searchType: 'profile_url'
        },
        {
          template: 'twitter_alias_to_user',
          name: 'twitterSearchUsersFromAlias',
          entityType: 'maltego.Alias',
          searchType: 'profile_url'
        },
        {
          template: 'linkedin_profile_details&full=0',
          name: 'LinkedinProfileDetail',
          entityType: 'maltego.URL',
          searchType: 'profile_url'
        },
        {
          template: 'linkedin_profile_details&full=0',
          name: 'LinkedinProfileDetail',
          entityType: 'maltego.URL',
          searchType: 'profile_id'
        },
        {
          template: 'facebook_to_entity_v2',
          name: 'fromFBidToEntityV2',
          entityType: 'maltego.facebook.id',
          searchType: 'profile_id'
        },
        {
          template: 'gamayun_twitter_id_to_user',
          name: 'gamayun_twitter_id_to_user',
          entityType: 'maltego.affiliation.Twitter',
          searchType: 'profile_id'
        },
        {
          template: 'gamayun_instagram_id_to_user',
          name: 'gamayun_instagram_id_to_user',
          entityType: 'maltego.instagram.profile',
          searchType: 'profile_id'
        }
      ],
      SLDBTransforms: [
        {
          template: 'webdata_by_new&type=phone',
          name: 'phoneToSldb',
          entityType: 'maltego.PhoneNumber',
          searchType: 'phone'
        },
        {
          template: 'webdata_by_new&type=email',
          name: 'emailToSldb',
          entityType: 'maltego.EmailAddress',
          searchType: 'email'
        }
      ],
      transformsToRunBySLDBResults: [
        {
          template: 'facebook_to_entity_v2',
          name: 'fromFBidToEntityV2',
          entityType: 'maltego.facebook.id'
        },
        {
          template: 'twitter_alias_to_user',
          name: 'twitterAliasToUserV2',
          entityType: 'maltego.Alias'
        },
        {
          template: 'linkedin_alias_to_entities',
          name: 'LinkedinAliasToEntities',
          entityType: 'maltego.Alias'
        },
        {
          template: 'alias_to_instagram',
          name: 'aliasToInstagram2',
          entityType: 'maltego.Alias'
        }
      ],
      v$: useVuelidate(),
      validationPass: false,
      showTooltip: false,
      limit: 26,
      showNameTooltip: false
    };
  },
  validations () {
    return {
      name: { maxLength: maxLength(64), nameInAllLanguages },
      alias: { maxLength: maxLength(64), aliasValidator },
      email: { email, maxLength: maxLength(64) },
      phone: { checkPhoneInputError },
      profileUrl: { profileUrlValidator },
      profileID: { profileIDValidator },
      photoUrl: { url }
    };
  },
  computed: {
    ...mapGetters(['getSearching', 'currentCase', 'currentCaseName', 'accountState', 'openSearch']),
    totalPrice () {
      const requestedTransforms = [];
      if (this.name) requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'name'));
      if (this.alias) requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'alias'));

      if (this.location) requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'name,location'));
      if (this.photo || this.photoUrl) requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'name,photo'));
      if (this.company) requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'name,company'));
      if (this.education) requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'name,education'));
      if (this.email) {
        requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'email'));
        requestedTransforms.push(...this.SLDBTransforms.filter(tr => tr.searchType === 'email'));
        requestedTransforms.push(...this.transformsToRunBySLDBResults);
      }
      if (this.phone) {
        requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'phone'));
        requestedTransforms.push(...this.SLDBTransforms.filter(tr => tr.searchType === 'phone'));
        requestedTransforms.push(...this.transformsToRunBySLDBResults);
      }
      if (this.profileUrl) {
        requestedTransforms.push(this.transforms.find(tr => tr.searchType === 'profile_url'));
      }
      if (this.profileID) {
        requestedTransforms.push(...this.transforms.filter(tr => tr.searchType === 'profile_id'));
      }
      return this.getPriceByTransformsList(requestedTransforms);
    },
    photoPrice () {
      const transformsByPhoto = this.transforms.filter(tr => tr.searchType === 'name,photo');
      return this.getPriceByTransformsList(transformsByPhoto);
    },
    emailPrice () {
      const transformsByEmail = this.transforms.filter(tr => tr.searchType === 'email');
      transformsByEmail.push(...this.SLDBTransforms.filter(tr => tr.searchType === 'email'));
      transformsByEmail.push(...this.transformsToRunBySLDBResults);
      return this.getPriceByTransformsList(transformsByEmail);
    },
    phonePrice () {
      const transformsByPhone = this.transforms.filter(tr => tr.searchType === 'phone');
      transformsByPhone.push(...this.SLDBTransforms.filter(tr => tr.searchType === 'phone'));
      transformsByPhone.push(...this.transformsToRunBySLDBResults);
      return this.getPriceByTransformsList(transformsByPhone);
    },
    profileUrlPrice () {
      const transformByProfileUrl = this.transforms.find(tr => tr.searchType === 'profile_url');
      return this.getPriceByTransformsList([transformByProfileUrl]);
    },
    profileIDPrice () {
      const transformsByProfileID = this.transforms.filter(tr => tr.searchType === 'profile_id');
      return this.getPriceByTransformsList(transformsByProfileID);
    },
    educationPrice () {
      const transformsByEducation = this.transforms.filter(tr => tr.searchType === 'name,education');
      return this.getPriceByTransformsList(transformsByEducation);
    },
    companyPrice () {
      const transformsByCompany = this.transforms.filter(tr => tr.searchType === 'name,company');
      return this.getPriceByTransformsList(transformsByCompany);
    },
    disableSearch () {
      if (this.accountState.balance < this.totalPrice) {
        return true;
      }
      return false;
    },
    nameValidationNotification () {
      return this.v$.$errors.some(item => item.$property === 'name');
    },
    disableSearchByFields () {
      return !this.name && !this.alias && !this.profileUrl && !this.profileID && !this.phone && !this.email;
    }
  },
  methods: {
    submitHandler () {
      if (this.v$.$invalid) {
        this.validationPass = true;
      } else {
        const event = 'profile_search';
        const eventProperties = {
          input: [],
          slp: this.totalPrice
        };
        if (this.alias) eventProperties.input.push('alias');
        if (this.company) eventProperties.input.push('company');
        if (this.education) eventProperties.input.push('education');
        if (this.location) eventProperties.input.push('location');
        if (this.name) eventProperties.input.push('name');
        if (this.phone) eventProperties.input.push('phone');
        if (this.email) eventProperties.input.push('email');
        if (this.photo) eventProperties.input.push('photo');
        if (this.photoUrl) eventProperties.input.push('photoUrl');
        if (this.profileID) eventProperties.input.push('profileID');
        if (this.profileUrl) eventProperties.input.push('profileUrl');

        const userProperties = {
          subscription: this.accountState.subscription.name,
          company: this.accountState.company,
          role: this.accountState.role
        };

        amplitude.getInstance().setUserProperties(userProperties);
        amplitude.getInstance().logEvent(event, eventProperties);

        this.search();
      }
    },
    setPhotoByQueryUrl (url) {
      let uploadedImage = url.startsWith(process.env.VUE_APP_IMAGES_SERVER_TEST);
      if (!uploadedImage) {
        uploadedImage = url.startsWith(process.env.VUE_APP_IMAGES_SERVER_PROD);
      }
      if (uploadedImage) {
        this.photo = url;
        this.photoUrl = '';
      } else {
        this.photoUrl = url;
        this.photo = null;
      }
    },
    getPriceByTransformsList (transforms) {
      const selectedTransforms = [];
      transforms.forEach(tr => {
        const transform = this.subscriptionTransforms.find(subTr => subTr.name === tr.name);
        if (transform) selectedTransforms.push(transform);
      });
      return selectedTransforms.reduce((previousValue, currentValue) => previousValue + currentValue.price, 0);
    },
    cancelCaseEditing () {
      this.customCaseName = '';
      this.isCaseNameInputActive = false;
      document.getElementById('case-name-input').style.color = '#585859';
    },
    editCaseName () {
      this.isCaseNameInputActive = true;
      document.getElementById('case-name-input').style.color = '#BFD2DF';
    },
    async saveCaseName () {
      try {
        if (this.customCaseName.trim() === '') return;
        const caseInfo = {
          fid: this.currentCase.oid,
          tag: this.customCaseName
        };
        await this.$store.dispatch('editCase', caseInfo);
        this.isCaseNameInputActive = false;
        document.getElementById('case-name-input').style.color = '#585859';
        this.$store.commit('updateCaseName', this.customCaseName);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    url (image) {
      if (typeof (image) === 'string') {
        return image;
      } else {
        return URL.createObjectURL(image);
      }
    },
    onImageChange (e) {
      this.imageError = '';
      this.photo = (e.target.files || e.dataTransfer.files)[0];
      const ext = (this.photo.name.match(/\.([^.]+)$/)[1]).toLowerCase();
      if ((ext !== 'png') && (ext !== 'jpg') && (ext !== 'jpeg')) {
        this.imageError = 'The image format must be jpg, jpeg or png';
        this.photo = null;
        document.getElementById('upload-photo').value = null;
        if (this.imageError) {
          const timer = setInterval(() => {
            clearInterval(timer);
            this.imageError = '';
          }, 13000);
        }
        return;
      }
      if (this.photo.size > (this.imageMaxSize * 1048576)) {
        this.imageError = `Image size exceeds the maximum upload size (${this.imageMaxSize}mb)`;
        this.photo = null;
        document.getElementById('upload-photo').value = null;
        if (this.imageError) {
          const timer = setInterval(() => {
            clearInterval(timer);
            this.imageError = '';
          }, 13000);
        }
      }
    },
    updatePhoto () {
      this.photo = null;
      this.imageError = '';
      this.$nextTick(() => {
        document.getElementById('upload-photo').click();
      });
    },
    deletePhoto () {
      this.hiddenFields.photo = !this.hiddenFields.photo;
      this.photo = null;
      this.imageError = '';
      this.disabled.photoUrl = !this.disabled.photoUrl;
    },
    deletePhone () {
      this.hiddenFields.phone = !this.hiddenFields.phone;
      this.phone = '';
    },
    deletePhotoUrl () {
      this.hiddenFields.photoUrl = !this.hiddenFields.photoUrl;
      this.photoUrl = '';
      this.disabled.photo = !this.disabled.photo;
    },
    deleteProfileUrl () {
      this.hiddenFields.profileUrl = !this.hiddenFields.profileUrl;
      this.profileUrl = '';
    },
    deleteProfileID () {
      this.hiddenFields.profileID = !this.hiddenFields.profileID;
      this.profileID = '';
    },
    deleteEmail () {
      this.hiddenFields.email = !this.hiddenFields.email;
      this.email = '';
    },
    deleteEducation () {
      this.hiddenFields.education = !this.hiddenFields.education;
      this.education = '';
    },
    deleteCompany () {
      this.hiddenFields.company = !this.hiddenFields.company;
      this.company = '';
    },
    async uploadPhoto (name, photo) {
      if (typeof (photo) === 'string') {
        return {
          label: 'photo',
          name: 'photo',
          rule: null,
          value: photo
        };
      }
      const fd = new FormData();
      fd.append('image', photo, photo.name);

      const data = {
        filename: photo.name,
        filesize: photo.size,
        filetype: photo.type,
        modifytime: photo.lastModified,
        fullname: name,
        uploadtime: Date.now()
      };

      fd.append('data', JSON.stringify(data));
      fd.append('skip_ml', true);

      try {
        const payload = {
          method: 'POST',
          url: '/api/v2/photos',
          body: fd
        };
        const resp = await this.$store.dispatch('ajaxWithTokenRefresh', payload);

        if (resp.status === 201) {
          const photo = resp.data;
          return {
            label: 'photo',
            name: 'photo',
            rule: null,
            value: photo.url
          };
        }
      } catch (e) {
        console.error(e);
      }
    },
    getSearchTypes () {
      const searchBy = [];
      if (this.name) {
        searchBy.push('name');
      }
      if (this.alias) {
        searchBy.push('alias');
      }
      if (this.email) {
        searchBy.push('email');
      }
      if (this.phone) {
        searchBy.push('phone');
      }
      if (this.profileUrl) {
        searchBy.push('profile_url');
      }
      if (this.profileID) {
        searchBy.push('profile_id');
      }
      if (this.name && (this.photo || this.photoUrl)) {
        searchBy.push('name,photo');
      }
      if (this.name && this.location) {
        searchBy.push('name,location');
      }
      if (this.name && this.education) {
        searchBy.push('name,education');
      }
      if (this.name && this.company) {
        searchBy.push('name,company');
      }
      return searchBy;
    },
    getTransformNameByProfileUrl () {
      if (/^https?:\/\/(www\.)?instagram.com\/[a-zA-Z0-9._]{1,30}\/?$/.test(this.profileUrl)) {
        return 'aliasToInstagram2';
      }
      if (/^https?:\/\/(www\.)?twitter.com\/[a-zA-Z0-9_]{4,15}\/?$/.test(this.profileUrl)) {
        return 'twitterSearchUsersFromAlias';
      }
      if (/^https?:\/\/(www\.)?facebook.com\/profile\.php\?id=[0-9]+$/.test(this.profileUrl)) {
        return 'aliasToFacebook2';
      }
      if (/^https?:\/\/(www\.)?facebook.com\/[a-zA-Z0-9.]{5,50}\/?$/.test(this.profileUrl)) {
        return 'aliasToFacebook2';
      }
      if (/^https?:\/\/(www\.)?linkedin.com\/in\/[a-zA-Z0-9-_]+\/?$/.test(this.profileUrl)) {
        return 'LinkedinProfileDetail';
      }
      return '';
    },
    selectTransforms (searchTypes, sldb = false) {
      const transforms = sldb ? this.SLDBTransforms : this.transforms;
      const selectedTransforms = {};
      for (const searchType of searchTypes) {
        selectedTransforms[searchType] = {};
        if (searchType === 'profile_url') {
          selectedTransforms[searchType].transforms = transforms.filter(tr => {
            return tr.searchType === searchType && tr.name === this.getTransformNameByProfileUrl();
          });
        } else {
          selectedTransforms[searchType].transforms = transforms.filter(tr => tr.searchType === searchType);
        }
      }
      return selectedTransforms;
    },
    async setSearchEntities (transforms) {
      for (const searchType of Object.keys(transforms)) {
        const trs = transforms[searchType];
        const entity = { typeid: '', value: '', weight: 100, fields: [] };
        entity.fields.push({ label: 'search-type', name: 'search-type', rule: null, value: searchType });
        if (searchType === 'name') {
          entity.typeid = 'maltego.Person';
          entity.value = this.name;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: this.name });
        } else if (searchType === 'alias') {
          entity.typeid = 'maltego.Alias';
          entity.value = this.alias;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: this.alias });
        } else if (searchType === 'email') {
          entity.typeid = 'maltego.EmailAddress';
          entity.value = this.email;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: this.email });
        } else if (searchType === 'phone') {
          entity.typeid = 'maltego.PhoneNumber';
          entity.value = this.phone;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: this.phone });
        } else if (searchType === 'name,photo') {
          entity.typeid = 'maltego.SearchFace';
          entity.value = this.name;
          let image;
          if (this.photo) {
            image = await this.uploadPhoto(this.name, this.photo);
          }
          if (this.photoUrl) {
            image = { label: 'photo', name: 'photo', rule: null, value: this.photoUrl };
          }
          entity.fields.push(image);
        } else if (searchType === 'name,location') {
          entity.typeid = 'maltego.delayed.facebook.search.users';
          entity.value = this.name;
          entity.fields.push({ label: 'location', name: 'location', rule: null, value: this.location.split(',')[0] });
          entity.fields.push({ label: 'fullname', name: 'fullname', rule: null, value: this.name });
        } else if (searchType === 'name,education') {
          entity.typeid = 'maltego.SearchPerson';
          entity.value = this.name;
          entity.fields.push({ label: 'education', name: 'education', rule: null, value: this.education });
        } else if (searchType === 'name,company') {
          entity.typeid = 'maltego.SearchPerson';
          entity.value = this.name;
          entity.fields.push({ label: 'company', name: 'company', rule: null, value: this.company });
        } else if (searchType === 'profile_url') {
          entity.typeid = 'maltego.Alias';
          const regexResults = /^https?:\/\/(www\.)?twitter.com\/(?<username>[a-zA-Z0-9_]{4,15})\/?$/.exec(this.profileUrl);
          entity.value = regexResults?.groups?.username || this.profileUrl;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: this.profileUrl });
        } else if (searchType === 'profile_id') {
          entity.typeid = 'maltego.URL';
          entity.value = this.profileID;
          entity.fields.push({ label: searchType, name: searchType, rule: null, value: this.profileID });
        }
        trs.entity = entity;
      }
    },
    async runSLDBTransforms (transforms) {
      const sldbTransforms = Object.keys(transforms).reduce((prevSLDBTransforms, searchType) => {
        return [...prevSLDBTransforms, ...transforms[searchType].transforms];
      }, []);
      const allSLDBResults = [];
      return Promise.all(sldbTransforms.map(async tr => {
        const entityType = tr.entityType;
        const entity = {
          typeid: entityType,
          value: (entityType === 'maltego.PhoneNumber') ? this.phone : this.email,
          weight: 100,
          fields: []
        };
        const transformData = {
          entity,
          limit: 256,
          entityType,
          additionalFields: [],
          weight: 100
        };
        const results = await this.$store.dispatch('runTransform', {
          url: '/endpoint.php?template=' + tr.template,
          transformName: tr.name,
          xml: await this.$store.dispatch('createXML', transformData)
        });
        results.forEach(res => { res.search_type = tr.searchType; });
        allSLDBResults.push(...results);
      })).then(() => { return allSLDBResults; });
    },
    updateTransformsBySLDBResults (allResults, SLDBResults, transforms) {
      Object.keys(transforms).forEach(searchType => { transforms[searchType].transforms = []; });
      const linkedInProfileByEmail = this.getLinkedInProfileByEmail(allResults);
      const searchByPhone = {};
      const searchByEmail = {};
      for (const entity of SLDBResults) {
        const map = (entity.search_type === 'phone') ? searchByPhone : searchByEmail;
        map.searchType = entity.search_type;
        const facebook = entity.fields.find(field => field.name === 'facebook_id');
        if (facebook) map.facebookID = facebook.value;
        const twitter = entity.fields.find(field => field.name === 'twitter_alias');
        if (twitter) map.twitterAlias = twitter.value;
        const linkedin = entity.fields.find(field => field.name === 'linkedin_alias');
        if (linkedin && (entity.search_type === 'phone' || !linkedInProfileByEmail)) map.linkedinAlias = linkedin.value;
        const instagram = entity.fields.find(field => field.name === 'instagram_alias');
        if (instagram) map.instagramAlias = instagram.value;
      }

      [searchByPhone, searchByEmail].forEach(map => {
        Object.keys(map).forEach(socialNet => {
          const searchType = map.searchType;
          const transform = {
            entityType: 'maltego.Alias',
            searchQuery: map[socialNet],
            searchType
          };
          if (socialNet === 'facebookID') {
            transform.entityType = 'maltego.facebook.id';
            transform.name = 'fromFBidToEntityV2';
            transform.template = 'facebook_to_entity_v2';
            transforms[map.searchType].transforms.push(transform);
          } else if (socialNet === 'twitterAlias') {
            transform.name = 'twitterAliasToUserV2';
            transform.template = 'twitter_alias_to_user';
            transforms[map.searchType].transforms.push(transform);
          } else if (socialNet === 'linkedinAlias') {
            transform.name = 'LinkedinAliasToEntities';
            transform.template = 'linkedin_alias_to_entities';
            transforms[map.searchType].transforms.push(transform);
          } else if (socialNet === 'instagramAlias') {
            transform.name = 'aliasToInstagram2';
            transform.template = 'alias_to_instagram';
            transforms[map.searchType].transforms.push(transform);
          }
        });
      });
    },
    getLinkedInProfileByEmail (resultsArr) {
      const linkedInProfiles = resultsArr.filter(ent => ent.typeid === 'maltego.linkedin.profile');
      return linkedInProfiles.find(ent => {
        const searchType = ent.fields.find(fld => {
          return fld.name === 'search-type';
        });
        return searchType && searchType.value === 'email';
      });
    },
    trackSearch () {
      const smartLookEventProperties = {};
      if (this.name) smartLookEventProperties.search_name = this.name;
      if (this.location) smartLookEventProperties.search_location = this.location;
      if (this.education) smartLookEventProperties.search_education = this.education;
      if (this.company) smartLookEventProperties.search_company = this.company;
      if (this.photo) smartLookEventProperties.search_photo = true;
      if (this.photoUrl) smartLookEventProperties.search_photo_url = true;
      if (this.profileUrl) smartLookEventProperties.search_profile_url = this.profileUrl;
      if (this.profileID) smartLookEventProperties.search_profile_id = this.profileID;
      if (this.phone) smartLookEventProperties.search_phone = this.phone;
      if (this.email) smartLookEventProperties.search_email = this.email;
      smartlookClient.track('start_live_search', smartLookEventProperties);
      smartlookClient.track('spent_slp', { where: 'live_search', quantity: this.totalPrice });
    },
    async search () {
      if (this.accountState.balance < 12) {
        this.$store.commit('setNoSlPoints', true);
        this.$store.commit('setConditionsOfSubscribeModal', true);
        return;
      }

      this.trackSearch();
      this.$store.dispatch('removeZeroResultsUrlParameter');
      this.$store.commit('setSearching', true);
      this.$store.commit('searchResults', true);

      // Resetting checkboxes
      this.$store.commit('uncheckTags');
      this.$store.commit('uncheckAllResults');

      this.$store.commit('hideResults');
      this.$store.commit('updateResultsCancelToken');
      this.$store.commit('clearCurrentSearchResults');

      const searchTypes = this.getSearchTypes();
      const transforms = this.selectTransforms(searchTypes);
      await this.setSearchEntities(transforms);

      try {
        const search = await this.$store.dispatch('createNewSearch', transforms);
        search.nodes = [];
        this.$store.commit('openedSearch', search);
        this.$store.commit('setSearchID', search.rid);
        this.$store.commit('addToLoadedSearches', search.rid);
        const entities = Object.keys(transforms).map(searchType => transforms[searchType].entity);
        await this.$store.dispatch('createEntities', { search, entities });
        const results = await this.$store.dispatch('fetchResults', { transforms, entities });
        if (this.phone || this.email) {
          const searchTypes = [];
          if (this.phone) searchTypes.push('phone');
          if (this.email) searchTypes.push('email');
          const SLDBTransforms = this.selectTransforms(searchTypes, true);
          await this.setSearchEntities(SLDBTransforms);
          const entities = Object.keys(SLDBTransforms).map(searchType => SLDBTransforms[searchType].entity);
          await this.$store.dispatch('createEntities', { search, entities });
          const SLDBResults = await this.runSLDBTransforms(SLDBTransforms);
          this.updateTransformsBySLDBResults(results, SLDBResults, SLDBTransforms);
          const additionalResults = await this.$store.dispatch('fetchResults', { transforms: SLDBTransforms, entities });
          results.push(...additionalResults);
        }

        if (!results.length) {
          this.$store.dispatch('addZeroResultsUrlParameter');
        }

        await this.$store.dispatch('saveProfiles', { search, entities: results });
        const profiles = await this.$store.dispatch('convertEntityFieldsToMap', results);
        this.$store.commit('pushResults', profiles);
        this.$store.commit('pushToCurrentSearchResults', profiles);
        this.$store.commit('updateResultsMap', profiles);

        await this.$store.dispatch('getLatLon', this.$store.state.results.currentSearchResults);
      } catch (e) {
        console.error(e);
      }

      this.$store.commit('openResults', true);
      this.$store.commit('setSearching', false);
      this.$store.commit('searchResults', false);
      this.$store.commit('hideSearch', true);
      this.name = this.email = this.alias = this.phone = this.location = this.education = this.company = '';
      this.profileUrl = this.profileID = this.photoUrl = '';
      this.photo = null;
    }
  }
};
</script>

<style lang="scss" scoped>
  .search-form {
    padding: 0;
    font-style: normal;
    & input {
      border-radius: 5px;
      padding: 12px 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.04em;
      color: #A0B2BE;
      min-height: 48px;
    }
    & label {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #8D8D8E;
      margin-bottom: 4px;
      font-weight: 600;
      pointer-events: none;
    }
    & .additional-label {
      @extend label;
      color: #A0B2BE;
    }
  }
  .common-data {
    padding: 24px;
    background: #FFFFFF;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    & input {
      background: #F8FCFF;
      width: 100%;
      &:hover {
        background-color: #EAF3F9;
      }
    }
  }
  .unique-parameters {
    padding: 24px;
    background: #F8FCFF;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    & input {
      background: #FFFFFF;
      border-radius: 5px 0 0 5px;
      &:hover {
        background-color: #EAF2F9;
      }
    }
    & .input-block {
      display: grid;
      grid-template-columns: 260px 1fr;
      border-radius: 5px;
    }
  }
  .search-form-heading-2 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #585859;
    margin-bottom: 16px;
    font-weight: 600;
  }
  .search-form-heading-3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    color: #29292B;
  }
  .input-description {
    display: flex;
    background: #F0F8FC;
    border-radius: 0 5px 5px 0;
    padding: 16px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    align-items: center;
    color: #A5BAC9;
    min-height: 48px;
    margin-right: auto;
    width: 100%;
  }
  .upload-photo-text {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #BFD2DF;
  }
  .photo-buttons button {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    color: #60BCE3;
  }
  #case-name-input, .case-length-counter {
    background-color: #eff4f7;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.02em;
    padding: 0;
    margin-right: 16px;
    min-height: fit-content;
    &::-webkit-input-placeholder {
      color: #585859;
    }
    &::-moz-placeholder {
      color: #585859;
    }
    &:-moz-placeholder {
      color: #585859;
    }
    &:-ms-input-placeholder {
      color: #585859;
    }
    &.edit {
      text-decoration: underline #D6E2F1;
      text-underline-offset: 3px;
      &::-webkit-input-placeholder {
        color: #A0B2BE;
      }
      &::-moz-placeholder {
        color: #A0B2BE;
      }
      &:-moz-placeholder {
        color: #A0B2BE;
      }
      &:-ms-input-placeholder {
        color: #A0B2BE;
      }
    }
  }
  .input-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    border-radius: 5px;
  }
  .input-block-wide {
    display: grid;
    grid-template-columns: 260px 1fr;
    border-radius: 5px;
    & input {
      border-radius: 5px 0 0 5px;
    }
  }
  .input-block-wide.alert {
    & span {
      background-color: #FF7A7A;
      color: #FFFFFF;
    }
    & input {
      border: 2px solid #FF7A7A;
      border-right: none;
    }
  }
  .input-block.alert {
      & #name {
      border: 2px solid #FF7A7A;
      background: white;
    }
    & .name-error {
      background-color: #FF7A7A;
      color: #FFFFFF;
      border-radius: 5px;
    }
  }
  .case-name-container {
    display: inline-block;
    position: relative;
  }
  .case-length-counter {
    display: inline-block;
    visibility: hidden;
    white-space: pre;
  }
  #case-name-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  label[for="upload-photo"] {
    pointer-events: auto;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .slpTooltip {
    position: relative;
  }
  .custom-btn:hover + .tooltip-text{
    visibility: visible;
    opacity: 1;
  }
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s all cubic-bezier(0.83, 0, 0.17, 1);
    position: absolute;
    top: -122px;
    padding: 16px;
    bottom: auto;
    width: 260px;
    height: 108px;
    background: #FFFFFF;
    border-radius: 16px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #8D8D8E;
    &:hover {
      visibility: visible;
      opacity: 1;
    }
    &:after {
       content: url('../../assets/icons/slpTooltip.png');
       position: absolute;
       bottom: -18px;
       left: 106px;
       right: 0;
    }
    &:before {
       content: '';
    }
  }
  .img-error {
    max-width: 620px;
    span {
      background: #FF7A7A;
      color: #FFFFFF;
      border-radius: 5px;
    }
  }
</style>
