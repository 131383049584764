<template>
  <div class="map-profile d-flex align-center justify-between" :class="{ checked }">
    <div class="map-profile__left-side d-flex align-center">
      <img :src="avatar" class="map-profile__avatar" v-if="avatar" alt="">
      <img v-else src="@/assets/icons/avatar.png" class="map-profile__avatar" alt="">
      <div class="map-profile__social-icon d-flex align-center justify-center">
        <font-awesome-icon :icon="['fab', socialNetIcon]" />
      </div>
      <div class="map-profile__name mr-5">{{ name }}</div>
      <div class="map-profile__location" v-if="location">({{ location }})</div>
    </div>
    <div class="map-profile__right-side d-flex justify-end align-center">
      <button @click="openProfileSidebar" class="map-view-user-details">User details</button>
      <button @click="showOnMap" class="map-view-show-profile">Show on map</button>
      <div>
        <input type="checkbox" name="" class="profile-card-checkbox" :id="'profile-card-checkbox' + profile.oid" :checked="profile.checked" @change="checkProfile">
        <label :for="'profile-card-checkbox' + profile.oid" class="profile-card-checkbox__label"></label>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntityAvatar, getEntityName, getEntitySocialNetIcon, getLocation } from '../../utils/helpers';

export default {
  name: 'MapViewProfile',
  props: ['profile'],
  emits: ['openProfileSidebar'],
  computed: {
    avatar () {
      return getEntityAvatar(this.profile);
    },
    socialNetIcon () {
      if (this.profile) {
        return getEntitySocialNetIcon(this.profile);
      }
      return '';
    },
    name () {
      return getEntityName(this.profile);
    },
    location () {
      return getLocation(this.profile);
    },
    checked () {
      return this.profile.checked;
    }
  },
  methods: {
    checkProfile () {
      this.$store.commit('checkResultsProfile', this.profile.oid);
    },
    openProfileSidebar () {
      this.$emit('openProfileSidebar', this.profile);
    },
    async showOnMap () {
      await this.$store.dispatch('focusEntityOnMap', this.profile);
      window.scrollTo(0, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
  .map-profile {
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 16px 24px;
    margin-bottom: 4px;
    height: 56px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    & button {
      margin-right: 24px;
      color: #60BCE3;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.08em;
    }
    &__name {
      color: #8D8D8E;
    }
    &__location {
      color: #5692EC;
    }
    &__avatar, &__social-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
    }
    &__social-icon {
      background-color: #F8FCFF;
      color: #60BCE3;
      font-size: 16px;
    }
  }
  .checked {
    border: 2px solid #60BCE3;
  }
</style>
