<template>
  <div class="d-flex align-center justify-between">
    <div class="country d-flex align-center mb-16" @click="opened = !opened">
      <Icon name="location-marker" color="#60BCE3" class="mr-10" />
      <div>{{ country }}</div>
      <Icon name="arrow-down-white" color="#60BCE3" class="ml-10" />
    </div>
    <div class="profiles-count mb-16">
      {{ profilesByCountry.length }} profile{{ profilesByCountry.length !== 1 ? 's' : ''}}
    </div>
  </div>
  <div v-for="profile in visibleProfiles" :key="profile.oid">
    <MapViewProfile :profile="profile" @openProfileSidebar="openProfileSidebar" />
  </div>
</template>

<script>
import Icon from '../app/Icon';
import MapViewProfile from './MapViewProfile';

export default {
  name: 'MapViewAccordion',
  props: ['country', 'profiles'],
  emits: ['openProfileSidebar'],
  data () {
    return {
      opened: true
    };
  },
  computed: {
    profilesByCountry () {
      return this.profiles.filter(profile => profile.country === this.country);
    },
    visibleProfiles () {
      if (this.opened) {
        return this.profilesByCountry;
      }
      return [];
    }
  },
  methods: {
    openProfileSidebar (profile) {
      this.$emit('openProfileSidebar', profile);
    }
  },
  components: {
    Icon, MapViewProfile
  }
};
</script>

<style scoped>
  .country {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: #29292B;
    cursor: pointer;
  }
  .profiles-count {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: #8D8D8E;
  }
</style>
