<template>
  <div class="connection">
    <div class="connection__profile">
      <div class="connection__profile-body-wrap d-flex justify-between">
        <div class="connection__profile-info">
          <div class="connection__profile-info-header d-flex justify-between">
            <div class="connection__profile-info-top d-flex justify-between align-center">
              <div class="d-flex">
                <img class="connection__avatar-destination" :src="connectionAvatar" @load="connAvatarLoadHandler" @error="connAvatarErrorHandler" :class="loadImageConnection ? 'd-block' : 'd-none'">
                <img src="@/assets/icons/avatar.png" class="connection__avatar-destination" :class="loadImageConnection ? 'd-none' : 'd-block'">
                <img class="connection__avatar ml-n32 mr-24" :src="searchProfileAvatar" :style="sourceAvatarOpacity" @load="searchAvatarLoadHandler" @error="searchAvatarErrorHandler" :class="loadImageSource ? 'd-block' : 'd-none'">
                <img src="@/assets/icons/avatar.png" class="connection__avatar ml-n32 mr-32" :class="loadImageSource ? 'd-none' : 'd-block'">
                <div class="d-flex justify-center">
                  <h3>{{ connectionName }} & {{ searchProfileName }}</h3>
                  <div class="d-flex align-center flex-row mt-6" style="flex-direction: row !important;">
                    <span class="blue-chip mr-12">Connection score: {{ connection.fields.score.value }}</span>
                    <Icon :name="socialNetworkIcon" />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row" style="flex-direction: row !important;">
                <a class="color-theme typography-7 mr-16" @click="openSidebar">Users details</a>
                <a class="color-theme typography-7" @click="openDetails">{{showDetails ? 'Hide' : 'Connection details'}}</a>
              </div>
            </div>
          </div>
          <div class="connection__profile-info-footer d-flex justify-between typography-theme" v-if="showDetails">
            <div class="d-flex align-center mt-8 mb-6" v-if="mutualFriends">
              <div class="connection__profile-info-footer-text">Main info</div>
              <div class="d-flex align-center">
                <div class="ml-8 filter-tag mb-4 mt-4">{{ mutualFriends }} common friends</div>
              </div>
            </div>
            <div class="d-flex align-center mt-6 mb-8">
              <div class="connection__profile-info-footer-text">Relationship tags</div>
              <div class="d-flex align-center flex-wrap">
                <div class="ml-8 filter-tag mb-4 mt-4" v-for="relationship in connectionTypes" :key="relationship + connection.value">
                  <template v-if="relation && relationship === 'Relative'">{{ relationship }} ({{ relation }})</template>
                  <template v-else>{{ relationship }}</template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="connection__profile-checkbox">
          <input
            type="checkbox"
            :id="'profile-checkbox-' + connectionIdentity"
            class="sidebar-checkbox"
            :checked="(connection.edge && connection.edge.checked) || false"
            @change="checkConnection"
          >
          <label :for="'profile-checkbox-' + connectionIdentity" class="sidebar-checkbox__label"></label>
          <div class="mt-16 typography-9">Select</div>
        </div>
      </div>
      <template v-if="showDetails">
        <div class="connection__profile-post-stats d-flex flex-column align-center typography-theme justify-between" v-if="postExists && !postsAreEmpty">
         <div class="d-flex w-100  justify-between align-center p-24">
          <div class="d-flex align-center">Connected posts and events</div>
          <div class="view-controller d-flex align-center justify-between" :class="{rowReverse: !checkPostsLocation}">
          <button id="map-btn" :class="{ active: mapOpen }" @click="toggleButton('#map-btn')" v-if="checkPostsLocation"><Icon name="location-marker" />Show on map</button>
          <button id="posts-btn" :class="{ active: postsOpen }" @click="toggleButton('#posts-btn')"><Icon name="window" />Show by posts</button>
          </div>
          </div>
          <div class="d-flex w-100  justify-between align-center p-24">
            <div class="d-flex align-center">Event type</div>
          <div class="d-flex align-center flex-wrap">
            <button class="ml-8 results-filter-btn mb-4 mt-4" v-if="+commentedPostsCount" @click="showPosts($event,'commented')">
              Commented ({{ commentedPostsCount }})
            </button>
            <button class="ml-8 results-filter-btn mb-4 mt-4" v-if="+likedPostsCount" @click="showPosts($event, 'liked')">
              Liked ({{ likedPostsCount }})
            </button>
            <button class="ml-8 results-filter-btn mb-4 mt-4" v-if="+taggedPostsCount" @click="showPosts($event, 'tagged')">
              Tagged ({{ taggedPostsCount }})
            </button>
            <button class="ml-8 results-filter-btn mb-4 mt-4" v-if="+reactedPostsCount" @click="showPosts($event, 'reacted')">
              Reacted ({{ reactedPostsCount }})
            </button>
            <button class="ml-8 results-filter-btn mb-4 mt-4" v-if="+repostPostsCount" @click="showPosts($event, 'repost')">
              Repost ({{ repostPostsCount }})
            </button>
            <button class="ml-8 results-filter-btn mb-4 mt-4" v-if="+mentionedPostsCount" @click="showPosts($event, 'mentioned')">
              Mentioned ({{ mentionedPostsCount }})
            </button>
            <button class="ml-8 results-filter-btn mb-4 mt-4" @click="showPosts($event)">
              Show all ({{ postsCount }})
            </button>
          </div>
        </div>
        </div>
        <div class="connection__profile-post-stats d-flex align-center typography-theme justify-between" v-else-if="postExists && !loading">
          <div class="p-24">Connected posts and events</div>
          <button class="primary-btn typography-base" @click="uploadPosts">Download posts</button>
        </div>
      </template>
      <div class="modal loading-details d-flex flex-column align-center" v-if="loading">
        <div class="mb-12">
          <img src="@/assets/icons/searching.gif" alt="" class="h-78">
        </div>
        <div class="d-flex">
          <span class="typography-theme mr-4">Loading...</span>
          <button class="typography-theme color-link cancel-connection-posts-downloading" @click="cancelSearch">
            Cancel
          </button>
        </div>
      </div>
      <div class="w-100" v-if="mapOpen">
      <div v-if="!foundLocations" id="mapview-loader">
        <img src="@/assets/icons/searching.gif" alt="" class="h-78 mb-12">
        <span class="typography-3 color-2">Loading...</span>
      </div>
          <div v-else>
            <Map :entities="Object.values(connection.posts)"></Map>
              <div class="connection-posts" v-show="connection.show_posts">
                <ConnectionPostMap
                 v-for="(post, idx) in loadedPostsToMap"
                 :key="post.id + connectionIdentity + idx"
                 :post="post"
                 :ref="post.id + connectionIdentity + idx"
                 :connection="connection"
                 :source="source"
                 @postRelations="getPostRelations"
                />
             </div>
          </div>
         </div>
    </div>
    <div class="connection-posts" v-show="connection.show_posts" v-if="postsOpen">
      <ConnectionPost
        v-for="(post, idx) in loadedPosts"
        :key="post.id + connectionIdentity + idx"
        :post="post"
        :ref="post.id + connectionIdentity + idx"
        :connection="connection"
        :source="source"
        @postRelations="getPostRelations"
      />
     </div>
    <div :ref="'end-of-posts-' + connectionIdentity"></div>
    </div>
</template>

<script>
import { getEntityName, getEntityAvatar } from '../utils/helpers';
import ConnectionPost from './ConnectionPost.vue';
import ConnectionPostMap from './ConnectionPostMap.vue';
import Map from './map/Map';
import Icon from '../components/app/Icon';

export default {
  name: 'Connection',
  props: ['connection', 'source'],
  emits: ['openSidebar'],
  data () {
    return {
      visiblePosts: [],
      clickedButtons: [],
      postRelations: {},
      hiddenPosts: [],
      loadedPosts: [],
      loadedPostsToMap: [],
      loadedPostsCount: 3,
      loading: false,
      postsOpen: true,
      mapOpen: false,
      result: {
        like: [],
        comment: [],
        reaction: [],
        with: [],
        repost: [],
        mention: []
      },
      showDetails: false,
      loadImageConnection: false,
      loadImageSource: false
    };
  },
  computed: {
    foundLocations () {
      return this.$store.state.results.foundLocations;
    },
    sourceAvatarOpacity () {
      if (this.$store.state.connections.between) {
        return 'opacity: 1';
      }
      return 'opacity: 0.5';
    },
    connectionIdentity () {
      return this.connection.id || this.connection.username || this.connection.oid;
    },
    searchProfileName () {
      return getEntityName(this.source);
    },
    connectionName () {
      const fields = this.connection.fields;
      return this.connection.name || this.connection.username || fields?.name?.value || fields?.username?.value;
    },
    searchProfileAvatar () {
      return getEntityAvatar(this.source);
    },
    connectionAvatar () {
      return getEntityAvatar(this.connection);
    },
    mutualFriends () {
      return this.connection.fields.mutual_friends && this.connection.fields.mutual_friends.value;
    },
    relation () {
      return this.connection.fields.relation && this.connection.fields.relation.value;
    },
    postsCount () {
      if (this.mapOpen && this.postsToMapLength.length) {
        return this.postsToMapLength.length;
      } else {
        return Object.keys(this.connection.posts).length;
      }
    },
    likedPostsCount () {
      if (this.mapOpen) {
        return this.reactionCounter('like');
      } else {
        return this.connection.fields.liked_count.value;
      }
    },
    taggedPostsCount () {
      if (this.mapOpen) {
        return this.reactionCounter('with');
      } else {
        return this.connection.fields.tagged_count.value;
      }
    },
    commentedPostsCount () {
      if (this.mapOpen) {
        return this.reactionCounter('comment');
      } else {
        return this.connection.fields.commented_count.value;
      }
    },
    reactedPostsCount () {
      if (this.mapOpen) {
        return this.reactionCounter('reaction');
      } else {
        return this.connection.fields.reacted_count.value;
      }
    },
    repostPostsCount () {
      if (this.mapOpen) {
        return this.reactionCounter('repost');
      } else {
        return this.connection.fields.repost_count.value;
      }
    },
    mentionedPostsCount () {
      if (this.mapOpen) {
        return this.reactionCounter('mention');
      } else {
        return this.connection.fields.mentioned_count.value;
      }
    },
    connectionTypes () {
      return this.connection.fields.connection_types.value.split(',');
    },
    postExists () {
      return this.connection.post_exists || this.connection.fields?.post_exists?.value;
    },
    postsAreEmpty () {
      return !Object.keys(this.connection.posts).length;
    },
    checkPostsLocation () {
      return Object.values(this.connection.posts).some(ent => ent?.lat || ent?.location?.lat);
    },
    postsToMap () {
      if (this.connection.lon || this.connection?.location?.lat) {
        return Object.values(this.connection.posts);
      } else {
        this.$store.dispatch('getLatLon', Object.values(this.connection.posts));
        return Object.values(this.connection.posts);
      }
    },
    postsToMapLength () {
      return this.postsToMap.filter(item => {
        if (item.location && Object.keys(item.location).length) {
          return item.location;
        }
      });
    },
    // eslint-disable-next-line vue/return-in-computed-property
    socialNetworkIcon () {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (this.connection.typeid.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    }
  },
  mounted () {
    this.loadPosts();
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onScroll);
      this.onScroll();
    });
    this.countReactionPostWithLocation();
    this.makePostToMap();
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll);
    const mutation = (this.$store.state.connections.between) ? 'updateMergedConnectionBetween' : 'updateConnection';
    this.$store.commit(mutation, { conn: this.connectionIdentity, key: 'show_posts', val: false });
    this.visiblePosts = [];
    this.clickedButtons = [];
    this.hiddenPosts = [];
    this.loadedPosts = [];
  },
  async beforeMount () {
    await this.$store.dispatch('getLatLon', Object.values(this.connection.posts));
  },
  methods: {
    countReactionPostWithLocation () {
      for (const postId in this.connection.posts) {
        const currentConnectionPost = this.connection.posts[postId];
        const fbPosts = this.connection?.connection_posts;
        if (currentConnectionPost?.location && fbPosts) {
          for (const fbPost in fbPosts[postId]) {
            this.result[fbPost].push(fbPosts[postId][fbPost]);
          }
        }
        if (currentConnectionPost?.location) {
          if (this.connection.relations) {
            this.connection.relations.forEach(item => {
              if (item.target === postId) {
                this.result[item.type].push(item.type);
              }
            });
          }
        }
      }
    },
    reactionCounter (value) {
      switch (true) {
        case value === 'like' : return this.result.like.length;
        case value === 'comment' : return this.result.comment.length;
        case value === 'reaction' : return this.result.reaction.length;
        case value === 'with' : return this.result.with.length;
        case value === 'repost' : return this.result.repost.length;
        case value === 'mention' : return this.result.mention.length;
      }
    },
    makePostToMap () {
      for (const post in this.visiblePosts) {
        if (this.visiblePosts[post].location && Object.keys(this.visiblePosts[post].location).length) {
          this.loadedPostsToMap.push(this.visiblePosts[post]);
        }
      }
      this.loadedPostsToMap = [...new Set(this.loadedPostsToMap)];
    },
    toggleButton (btnID) {
      if (btnID === '#posts-btn') {
        if (this.mapOpen) {
          this.postsOpen = true;
          this.mapOpen = false;
        }
      }
      if (btnID === '#map-btn') {
        if (this.postsOpen) {
          this.mapOpen = true;
          this.postsOpen = false;
        }
      }
    },
    connAvatarLoadHandler () {
      this.loadImageConnection = true;
    },
    connAvatarErrorHandler () {
      this.loadImageConnection = false;
    },
    searchAvatarLoadHandler () {
      this.loadImageSource = true;
    },
    searchAvatarErrorHandler () {
      this.loadImageSource = false;
    },
    cancelSearch () {
      this.loading = false;
    },
    loadPosts () {
      this.makeAllPostsVisible();
      this.visiblePosts.sort(this.comparePostDates);
      this.loadedPosts.push(...this.visiblePosts);
      this.hiddenPosts.push(...this.visiblePosts);
      this.loadedPosts.push(...this.hiddenPosts.slice(0, this.loadedPostsCount));
      this.hiddenPosts = this.hiddenPosts.slice(this.loadedPostsCount);
    },
    async uploadPosts () {
      this.loading = true;
      const payload = {
        method: 'POST',
        url: '/api/user/searches/nodes',
        body: {
          search_id: this.connection.rid,
          parent_id: this.connection.parent_id,
          levels: [4],
          limit: 1000,
          offset: 0
        }
      };
      while (true) {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        if (response.status === 200) {
          const posts = response.data;
          await this.$store.dispatch('convertEntityFieldsToMap', posts);
          const postsMap = {};
          posts.forEach(post => {
            post.id = post.value;
            postsMap[post.value] = post;
          });
          this.$store.commit('updateConnection', { conn: this.connection.oid, key: 'posts', val: postsMap });
        }
        if (response.data.length < payload.body.limit) {
          break;
        }
        payload.body.offset += payload.body.limit;
      }
      this.loadPosts();
      this.loading = false;
      this.makePostToMap();
    },
    openSidebar () {
      this.$store.commit('uncheckAllResults');
      this.$store.commit('checkAllEdges', false);
      const profiles = {
        connection: this.connection,
        parentId: this.source.oid
      };
      this.$emit('openSidebar', profiles);
    },
    checkConnection () {
      const oid = this.connection.edge.oid;
      const typeid = this.connection.typeid;
      this.$store.commit('checkEdge', { oid, typeid });
    },
    makeAllPostsVisible () {
      Object.keys(this.connection.posts).forEach((id) => { this.visiblePosts.push(this.connection.posts[id]); });
    },
    onScroll () {
      const end = this.$refs['end-of-posts-' + this.connectionIdentity];
      if (end) {
        const marginTop = end.getBoundingClientRect().top;
        const innerHeight = window.innerHeight;
        if (marginTop <= innerHeight) {
          this.loadedPosts.push(...this.hiddenPosts.slice(0, this.loadedPostsCount));
          this.hiddenPosts = this.hiddenPosts.slice(this.loadedPostsCount);
        }
      }
    },
    comparePostDates (post1, post2) {
      return post2.fields.date.value - post1.fields.date.value;
    },
    getPostRelations (data) {
      this.postRelations[data.postID] = data;
    },
    getSiblings (elem) {
      const siblings = [];
      let sibling = elem.parentNode.firstChild;
      while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
          siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
      return siblings;
    },
    getShowAllButton (elem) {
      const buttons = this.getSiblings(elem);
      return buttons.find((btn) => btn.innerHTML.includes('Show'));
    },
    updatePostsByRelation () {
      Object.keys(this.connection.posts).forEach((id) => {
        this.clickedButtons.forEach((relation) => {
          if (this.postRelations[id] && this.postRelations[id][relation] && !this.visiblePosts.includes(this.connection.posts[id])) {
            this.visiblePosts.push(this.connection.posts[id]);
          }
        });
      });
    },
    showPosts (event, relationshipType) {
      this.visiblePosts = [];
      this.loadedPosts = [];
      this.hiddenPosts = [];
      this.loadedPostsToMap = [];
      const btn = event.target;
      const isButtonActive = btn.className.includes('active');

      if (!relationshipType) {
        this.clickedButtons = [];
      }

      if (!relationshipType && !isButtonActive) {
        this.makeAllPostsVisible();
        this.getSiblings(btn).forEach((btn) => { btn.classList.remove('active'); });
      }

      if (relationshipType && isButtonActive) {
        const idx = this.clickedButtons.indexOf(relationshipType);
        this.clickedButtons.splice(idx, 1);
        this.updatePostsByRelation();
      }

      if (relationshipType && !isButtonActive) {
        this.clickedButtons.push(relationshipType);
        this.getShowAllButton(btn).classList.remove('active');
        this.updatePostsByRelation();
      }

      if (!isButtonActive || this.clickedButtons.length) {
        const mutation = (this.$store.state.connections.between) ? 'updateMergedConnectionBetween' : 'updateConnection';
        this.$store.commit(mutation, { conn: this.connectionIdentity, key: 'show_posts', val: true });
      }

      (isButtonActive) ? btn.classList.remove('active') : btn.classList.add('active');
      this.visiblePosts.sort(this.comparePostDates);
      this.hiddenPosts.push(...this.visiblePosts);
      this.loadedPosts.push(...this.hiddenPosts.slice(0, this.loadedPostsCount));
      this.hiddenPosts = this.hiddenPosts.slice(this.loadedPostsCount);
      this.makePostToMap();
    },
    openDetails () {
      this.showDetails = !this.showDetails;
      if (!this.showDetails) {
        this.visiblePosts = [];
        this.loadedPosts = [];
        this.hiddenPosts = [];
        this.loadedPostsToMap = [];
        this.postsOpen = true;
        this.mapOpen = false;
      }
    }
  },
  components: {
    ConnectionPost, Icon, ConnectionPostMap, Map
  }
};
</script>

<style scoped lang="scss">
  .connection-btn {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: rgba(180, 214, 255, 0.31);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #5692EC !important;
    transition: 0.3s;
    &:hover {
      background: rgba(180, 214, 255, 0.8);
      transition: 0.3s;
    }
    &.active {
      background: #60BCE3;
      transition: 0.3s;
      color: #FFFFFF !important;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border-radius: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.04em;
      &:hover {
        background-color: #69C8F0;
        color: #FFFFFF;
      }
    }
  }
  .modal {
    width: 100% !important;
  }
  .view-controller {
    padding: 4px;
    width: 401px;
    height: 48px;
    background: #F8FCFF;
    border-radius: 6px;
    & button {
      color: #60BCE3;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      justify-content: center;
      padding: 8px 24px;
      border-radius: 5px;
      & svg {
        margin-right: 14px;
      }
      &:hover {
        background-color: #60BCE3;
        color: #ffffff;
      }
      &:hover svg path {
        fill: #ffffff
      }
      &.active {
        background-color: #60BCE3;
        color: #ffffff;
        & svg path {
          fill: #ffffff
        }
      }
      &:disabled:hover {
        background-color: #F8FCFF;
        color: #60BCE3;
      }
      &:disabled:hover svg path {
        fill: #60BCE3
      }
    }
  }
  .connection__profile-post-stats {
    padding: 0;
    &>button {
      margin: 24px;
    }
  }
  .p-24 {
    padding: 12px 24px 12px 24px;
  }
  .pb-1 {
    padding-bottom: 1px;
  }
  .upload-title {
    padding: 20px;
  }
  .rowReverse {
    flex-direction: row-reverse;
    width: auto;
  }
</style>
