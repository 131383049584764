<template>
  <aside class="monitoring-sidebar">
    <div>
      <h3 class="mb-16">Monitoring</h3>
      <button class="close-btn" @click="close"><Icon name="cancel-btn" /></button>
    </div>
    <div class="monitoring-sidebar__settings">
      <h4 class="mb-16">Settings</h4>
      <div class="monitoring-sidebar__input-block">
        <label for="profile-changes">Changes in profile information (Avatar, Name, Place of work, education, etc.)</label>
        <label for="profile-changes">{{ profileChangesPrice }} slp</label>
        <input
          id="profile-changes"
          type="checkbox"
          class="profile-card-checkbox"
          :checked="checkProfileInformationChanges"
          @change="checkProfileInformationChanges = !checkProfileInformationChanges"
        >
        <label for="profile-changes" class="profile-card-checkbox__label"></label>
      </div>
      <div class="monitoring-sidebar__input-block">
        <label for="friends-changes">
          Changes to the subject’s social graph (additions / removals of friends / followers / followings)
        </label>
        <label for="friends-changes">{{ friendsChangesPrice }} slp</label>
        <input
          id="friends-changes"
          type="checkbox"
          class="profile-card-checkbox"
          :checked="checkFriendsListChanges"
          @change="checkFriendsListChanges = !checkFriendsListChanges"
        >
        <label for="friends-changes" class="profile-card-checkbox__label"></label>
      </div>
      <div class="beta-container" v-if="beta">
        <div class="d-flex justify-between mb-4">
          <h4>Monitoring beta is available</h4>
          <button @click="beta = false"><Icon name="cancel-btn" /></button>
        </div>
        <p>
          Our service will check the profile at time intervals you specify.
          We will notify you of any changes by email,
          and you will also be able to view the entire history of changes in the case report.
          At the moment, we are only working with Facebook profiles but will be connecting other platforms soon.
        </p>
      </div>
      <div class="monitoring-sidebar__range-title">
        <h4>Step</h4>
        <div>1 iteration per {{ period }}</div>
      </div>
      <input id="iteration-range" type="range" step="1" min="0" max="4" v-model="step">
    </div>
    <p class="total-price">{{ totalPrice }} slp per day</p>
    <button
      class="start-monitoring primary-btn typography-base w-100 max-w-100 mt-24"
      :disabled="!totalPrice"
      @click="startMonitoring"
    >
      Start monitoring
    </button>
  </aside>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  name: 'MonitoringSidebar',
  props: ['profile'],
  emits: ['close'],
  data () {
    return {
      profileChangesPrice: 0,
      friendsChangesPrice: 0,
      checkProfileInformationChanges: true,
      checkFriendsListChanges: true,
      step: 1,
      period: '',
      beta: true
    };
  },
  mounted () {
    this.setPrices();
    this.setPeriod();
    this.setInputRangeFunctionality();
  },
  watch: {
    step () {
      this.setPeriod();
    }
  },
  computed: {
    totalPrice () {
      let price = 0;
      if (this.checkProfileInformationChanges) {
        price += this.profileChangesPrice;
      }
      if (this.checkFriendsListChanges) {
        price += this.friendsChangesPrice;
      }
      return price;
    },
    monitoringOptions () {
      return this.$store.getters.monitoringOptions;
    }
  },
  methods: {
    close () {
      this.$emit('close');
    },
    setPrices () {
      const transforms = this.$store.getters.accountState.transforms;
      this.profileChangesPrice = transforms.find(tr => tr.name === 'facebook_profile').price;
      this.friendsChangesPrice = transforms.find(tr => tr.name === 'facebook_friends').price;
    },
    setPeriod () {
      const periods = ['12 hours', 'day', 'week', 'month'];
      this.period = periods[this.step - 1];
    },
    getRequestPeriod () {
      const periods = ['1h', '24h', '168h', '720h'];
      return periods[this.step - 1];
    },
    setInputRangeFunctionality () {
      const inputRange = document.querySelector('input[type=range]');
      const rangeColorLeft = '#51AEE2';
      const rangeColorRight = '#bee0f4';
      inputRange.addEventListener('input', () => {
        if (inputRange.value === '0') {
          inputRange.value = this.step = 1;
          this.setPeriod();
        }
        const percent = inputRange.value * 25;
        const leftRange = `${rangeColorLeft} 0%, ${rangeColorLeft} ${percent}%`;
        const rightRange = `${rangeColorRight} ${percent}%, ${rangeColorRight} 100%`;
        inputRange.style.background = `linear-gradient(to right, ${leftRange}, ${rightRange})`;
      });
    },
    getPipelines () {
      const pipelines = [];
      let option;
      if (this.checkProfileInformationChanges) {
        option = this.monitoringOptions.find(option => {
          return option.category === 'profile' && option.entity === this.profile.typeid;
        });
        pipelines.push(option.pipeline);
      }
      if (this.checkFriendsListChanges) {
        option = this.monitoringOptions.find(option => {
          return option.category === 'social_graph' && option.entity === this.profile.typeid;
        });
        pipelines.push(option.pipeline);
      }
      return pipelines;
    },
    async startMonitoring () {
      const response = await this.$store.dispatch('ajaxWithTokenRefresh', {
        method: 'POST',
        url: '/api/monitoring/tasks/add',
        body: {
          case_id: this.$route.params.id,
          period: this.getRequestPeriod(),
          targets: [{
            entity_id: this.profile.oid,
            pipelines: this.getPipelines()
          }]
        }
      });

      console.log(response);
    }
  },
  components: { Icon }
};
</script>

<style lang="scss">
  $light-grey: #F8FCFF;
  $font-grey: #A0B2BE;
  $grey: #8D8D8E;
  $dark-grey: #29292B;
  $blue: #51AEE2;
  $white: #FFFFFF;
  $border-radius: 5px;

  @mixin fontStyles($fontSize, $lineHeight, $letterSpacing) {
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
  }

  .monitoring-sidebar {
    width: 470px;
    background: #FFFFFF;
    position: fixed;
    min-height: calc(100vh - 80px);
    top: 80px;
    right: 940px;
    bottom: 0;
    z-index: 1000;
    box-shadow: 0 70px 100px 8px rgba(0, 0, 0, 0.05);
    transform: translateX(100%);
    transition: 0.3s;
    padding: 24px;
    &__settings {
      background: $white;
      padding: 0;
      border-radius: $border-radius;

      & h4 {
        @include fontStyles(16px, 24px, 0.04em);
        color: $dark-grey;
      }
    }
    &__input-block {
      color: $dark-grey;
      display: flex;
      margin-bottom: 12px;
      & label {
        @include fontStyles(14px, 24px, 0.04em);
      }
      & label:nth-child(2) {
        margin-right: 12px;
        margin-left: auto;
        color: $blue;
        min-width: 40px;
      }
    }
    &__range-title {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & div {
        color: $font-grey;
        @include fontStyles(16px, 24px, 0.04em);
      }
    }
    & input[type=range] {
      background: linear-gradient(to right, $blue 0%, $blue 25%, #bee0f4 25%, #bee0f4 100%);
      border: 0 solid #000000;
      border-radius: 8px;
      height: 2px;
      width: 100%;
      outline: none;
      transition: background 450ms ease-in;
      -webkit-appearance: none;
      margin-bottom: 30px;
      margin-top: 21px
    }
    & input[type=range]::-webkit-slider-thumb {
      box-shadow: 0 0 0 #000000;
      border: 0 solid #000000;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $blue;
      cursor: pointer;
      -webkit-appearance: none;
    }
  }

  .beta-container {
    background: #F8FCFF;
    border-radius: 5px;
    padding: 16px;
    margin: 68px 0 0;
    & h4 {
      @include fontStyles(16px, 24px, 0.04em);
      color: $blue;
    }
    & p {
      @include fontStyles(14px, 24px, 0.04em);
      font-size: 14px;
      color: $grey;
    }
  }

  .total-price {
    @include fontStyles(20px, 24px, 0.08em);
    text-align: center;
    color: $font-grey;
  }
</style>
