<template>
   <div class="results-filter d-flex justify-between">
     <div class="results-filter__categories">
       <div class="results-filter-row d-flex">
         <div class="results-filter__category">
           Social networks
         </div>
         <div class="flex-wrap d-flex">
           <div class="results-filter__results d-flex flex-wrap">
             <button
               class="results-filter-btn"
               v-if="counters.socialNet['maltego.facebook.profile']"
               @click="filterResults($event, 'maltego.facebook.profile', 'socialNet')"
             >
               <font-awesome-icon :icon="['fab', 'facebook-f']" />
               Facebook ({{ counters.socialNet['maltego.facebook.profile'] }})
             </button>
             <button
               class="results-filter-btn"
               v-if="counters.socialNet['maltego.linkedin.profile']"
               @click="filterResults($event, 'maltego.linkedin.profile', 'socialNet')"
             >
               <font-awesome-icon :icon="['fab', 'linkedin-in']" />
               Linked In ({{ counters.socialNet['maltego.linkedin.profile'] }})
             </button>
             <button
               class="results-filter-btn"
               v-if="counters.socialNet['maltego.instagram.profile']"
               @click="filterResults($event, 'maltego.instagram.profile', 'socialNet')"
             >
               <font-awesome-icon :icon="['fab', 'instagram']" />
               Instagram ({{ counters.socialNet['maltego.instagram.profile'] }})
             </button>
             <button
               class="results-filter-btn"
               v-if="counters.socialNet['maltego.affiliation.Twitter']"
               @click="filterResults($event, 'maltego.affiliation.Twitter', 'socialNet')"
             >
               <font-awesome-icon :icon="['fab', 'twitter']" />
               Twitter ({{ counters.socialNet['maltego.affiliation.Twitter'] }})</button>
           </div>
         </div>
       </div>
       <div class="results-filter__row d-flex">
         <div class="results-filter__category">
           Location
         </div>
         <div class="flex-wrap d-flex">
           <div class="results-filter__results d-flex flex-wrap">
             <button
               class="results-filter-btn"
               v-for="(count, location) in firstRow"
               :key="location"
               @click="filterResults($event, location, 'location')"
             >
               {{ location }} ({{ count }})
             </button>
             <button
               class="filter-btn-opacity"
               v-if="!showMore && filteredLocationsCount > 4"
               @click="clickShowMore"
             >
               Show more
             </button>
           </div>
           <div class="results-filter__results d-flex flex-wrap" v-show="showMore">
             <button
               class="results-filter-btn"
               v-for="(count, location) in nextRows"
               :key="location"
               @click="filterResults($event, location, 'location')"
             >
               {{ location }} ({{ count }})
             </button>
             <button
               class="filter-btn-opacity"
               v-show="showMore && filteredLocationsCount > 4"
               @click="clickShowMore"
             >
               Show less
             </button>
           </div>
         </div>
       </div>
        <div class="results-filter__row d-flex">
       <div class="btn" @click="cancelSelection" v-if="isLocationChecked || isSocialNetChecked">Cancel selection <Icon name="cancel-btn" /></div>
        </div>
     </div>
     <div class="results-filter__select">
       <input
         type="checkbox"
         id="profile-checkbox"
         class="sidebar-checkbox"
         @input="checkAllVisibleProfiles($event.target.checked)"
         :checked="checked"
       >
       <label for="profile-checkbox" class="sidebar-checkbox__label"></label>
       <div class="mt-16 typography-9">Select all</div>
     </div>
   </div>
</template>

<script>
import Icon from './app/Icon';
import { getLocation, eventListener } from '../utils/helpers';

export default {
  name: 'ResultsFilter',
  components: {
    Icon
  },
  data () {
    return {
      locations: {},
      buttons: {
        socialNet: {},
        location: {}
      },
      counters: {
        socialNet: {},
        location: {}
      },
      showMore: false
    };
  },
  mounted () {
    this.checkResults();
    eventListener.on('clearFilter', () => { this.cancelSelection(); });
  },
  computed: {
    checked () {
      const visibleResultsLength = this.visibleResults.length;
      const checkedResultsLength = this.visibleResults.filter(res => res.checked).length;
      return visibleResultsLength === checkedResultsLength;
    },
    results () {
      return this.$store.state.results.currentSearchResults;
    },
    visibleResults () {
      return this.$store.getters.visibleResults;
    },
    clear () {
      return !this.isSocialNetChecked && !this.isLocationChecked;
    },
    isLocationChecked () {
      // eslint-disable-next-line no-unused-vars
      for (const [_, val] of Object.entries(this.buttons.location)) {
        if (val) { return true; }
      }
      return false;
    },
    isSocialNetChecked () {
      // eslint-disable-next-line no-unused-vars
      for (const [_, val] of Object.entries(this.buttons.socialNet)) {
        if (val) { return true; }
      }
      return false;
    },
    filteredLocations () {
      const locations = {};
      Object.keys(this.counters.location).forEach(location => {
        if (this.counters.location[location]) {
          locations[location] = this.counters.location[location];
        }
      });
      return Object.entries(locations)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    },
    firstRow () {
      const locations = {};
      Object.keys(this.filteredLocations).slice(0, 4).forEach(loc => {
        locations[loc] = this.filteredLocations[loc];
      });
      return locations;
    },
    nextRows () {
      const locations = {};
      Object.keys(this.filteredLocations).forEach(loc => {
        if (!this.firstRow[loc]) {
          locations[loc] = this.filteredLocations[loc];
        }
      });
      return locations;
    },
    filteredLocationsCount () {
      return Object.keys(this.filteredLocations).length;
    }
  },
  methods: {
    cancelSelection () {
      this.locations = {};
      this.counters = {
        socialNet: {},
        location: {}
      };
      this.buttons = {
        socialNet: {},
        location: {}
      };
      this.$store.commit('updateResultsVisibility', {
        buttons: this.buttons,
        locationExists: false,
        socialNetExists: false
      });
      this.checkResults();
      this.resetAllButtonsStyle();
    },
    clickShowMore () {
      this.showMore = !this.showMore;
      this.updateButtonStylesByState();
    },
    checkAllVisibleProfiles (checked) {
      this.$store.commit('checkAllVisible', checked);
    },
    updateButtonStates () {
      Object.keys(this.buttons.socialNet).forEach(sn => {
        if (!this.counters.socialNet[sn]) {
          this.buttons.socialNet[sn] = false;
        }
      });
      Object.keys(this.buttons.location).forEach(loc => {
        if (!this.counters.location[loc]) {
          this.buttons.location[loc] = false;
        }
      });
    },
    updateButtonStylesByState () {
      const buttons = document.getElementsByClassName('results-filter-btn');
      for (const btn of buttons) {
        const text = btn.innerText.split('(')[0].trim();
        if (this.buttons.location[text]) {
          btn.classList.remove('results-filter-btn--active');
          this.updateButtonStyle(btn, text, 'location');
        }
      }
    },
    updateButtonStyle (selector, value, type) {
      const className = selector.className;
      if (className.includes('active')) {
        selector.classList.remove('results-filter-btn--active');
        this.buttons[type][value] = false;
      } else {
        selector.classList.add('results-filter-btn--active');
        this.buttons[type][value] = true;
      }
    },
    updateCounters (type) {
      const setDefaultLocationCounters = () => {
        Object.keys(this.counters.location).forEach(location => {
          this.counters.location[location] = this.locations[location];
        });
      };
      const setDefaultSocialNetCounters = () => {
        Object.keys(this.buttons.socialNet).forEach(sn => {
          this.counters.socialNet[sn] = this.profilesCount(sn);
        });
      };
      const setVisibleSocialNetCounters = () => {
        Object.keys(this.buttons.socialNet).forEach(sn => {
          this.counters.socialNet[sn] = this.visibleProfilesCount(sn);
        });
      };
      const setVisibleLocationCounters = () => {
        const visibleLocations = this.visibleLocations();
        Object.keys(this.counters.location).forEach(location => {
          this.counters.location[location] = visibleLocations[location] || 0;
        });
      };

      if (this.clear) {
        setDefaultLocationCounters();
        setDefaultSocialNetCounters();
      } else if (!this.isSocialNetChecked) {
        setDefaultLocationCounters();
        setVisibleSocialNetCounters();
      } else if (!this.isLocationChecked) {
        setDefaultSocialNetCounters();
        setVisibleLocationCounters();
      }

      if (type === 'socialNet' && !this.clear && this.isSocialNetChecked) {
        setVisibleLocationCounters();
      } else if (type === 'location' && !this.clear && this.isLocationChecked) {
        setVisibleSocialNetCounters();
      }
    },
    filterResults (event, value, type) {
      this.$store.commit('selectProfile', null);
      this.$store.commit('removeLastVisibleOIDs');

      this.updateButtonStyle(event.target, value, type);
      this.$store.commit('updateResultsVisibility', {
        buttons: this.buttons,
        locationExists: this.isLocationChecked,
        socialNetExists: this.isSocialNetChecked
      });

      this.updateCounters(type);
      this.updateButtonStates();
      this.$nextTick(() => {
        this.updateButtonStylesByState();
      });
    },
    visibleProfilesCount (entityType) {
      return this.visibleResults.filter(res => res.typeid === entityType).length;
    },
    profilesCount (entityType) {
      return this.results.filter(res => res.typeid === entityType).length;
    },
    visibleLocations () {
      const locations = {};
      this.visibleResults.forEach(res => {
        const location = getLocation(res) || 'No info';
        locations[location] = (locations[location] || 0) + 1;
      });
      return locations;
    },
    checkResults () {
      if (this.results) {
        this.results.forEach(res => {
          const location = getLocation(res) || 'No info';
          this.locations[location] = this.counters.location[location] = (this.locations[location] || 0) + 1;
          this.counters.socialNet[res.typeid] = (this.counters.socialNet[res.typeid] || 0) + 1;
          this.buttons.location[location] = this.buttons.socialNet[res.typeid] = false;
        });
      }
    },
    resetAllButtonsStyle () {
      const buttons = document.getElementsByClassName('results-filter-btn');
      for (const btn of buttons) {
        if (btn.classList.contains('results-filter-btn--active')) {
          btn.classList.remove('results-filter-btn--active');
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn{
cursor: pointer;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
text-align: right;
letter-spacing: 0.08em;
color: #60BCE3;
}
</style>
