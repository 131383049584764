<template>
  <div class="tagged-profile-card d-flex mb-12">
    <div class="tagged-profile-card__left-block mr-24">
      <img class="tagged-profile-card__avatar mb-16" :src="avatar" v-if="avatar">
      <img v-else src="@/assets/icons/profile-card-avatar.svg" class="tagged-profile-card__avatar mb-16">
      <h1 class="typography-1 color-1">{{ name }}</h1>
      <div class="typography-4 fw-400 terms-color mb-16" v-if="location">{{ location }}</div>
      <a class="tagged-profile-card__profile-url d-flex align-center" target="_blank" :href="profileUrl" v-if="profileUrl" style="page-break-inside:avoid">
        <span class="tagged-profile-card__icon-circle mr-8">
          <img src="@/assets/icons/instagram.png" alt="" v-if="socialNet === 'Instagram'">
          <img src="@/assets/icons/facebook.png" alt="" v-if="socialNet === 'Facebook'">
          <img src="@/assets/icons/linkedin.png" alt="" v-if="socialNet === 'Linkedin'">
          <img src="@/assets/icons/twitter.png" alt="" v-if="socialNet === 'Twitter'">
        </span>
        <span class="typography-7 color-theme" v-if="id || alias">{{ socialNet }} profile: {{ id || alias }}</span>
        <span class="typography-7 color-theme" v-else>{{ socialNet }} profile</span>
      </a>
    </div>
    <div class="tagged-profile-card__info-block color-2 d-flex">
<!--      <div class="tagged-profile-card__note d-flex">-->
<!--        <h4 class="typography-theme mb-12">Note</h4>-->
<!--        <div class="typography-8 mb-24">This person is our main target.</div>-->
<!--      </div>-->
      <div class="tagged-profile-card__info d-flex"  v-linkified>
        <div v-if="profile.note" class="mb-24">
          <h4 class="typography-theme mb-8">Note</h4>
          <span class="typography-8 wrap-word">{{profile.note}}</span>
        </div>
        <h4 class="typography-theme mb-12">General info</h4>
        <ul class="tagged-profile-card__info-list mb-24">
          <li v-for="(row, idx) of bio" :key="row + idx">{{ row }}</li>
        </ul>
        <div v-for="(type, name) in userDetails" :key="type">
          <p class="typography-theme mb-12">{{name}}</p>
          <ul class="profile-info-list mb-24">
            <li v-for="(row, idx) in type" :key="row + idx" :class="name === 'Social events' ? 'd-flex' : ''">
              <div class="subhead-width" v-if="name === 'Social events'"><img src="@/assets/icons/social-event.png" alt="" class="big-icon"></div>
              <div v-for="(val, key) in row" :key="key" :class="setClass1(name)">
                <p class="color-1 typography-7" :class="setClass2(name)" v-html="key"></p>
                <div v-if="typeof(val) === 'object' && val.length" class="d-flex flex-column">
                  <span class="lh-24" v-for="v in val" :key="v" v-html="v"></span>
                </div>
                <span class="lh-24" v-else v-html="val"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userDetailsMixin from '../../mixins/userDetailsMixin';

export default {
  name: 'SavedInTagsProfile',
  props: ['profile'],
  mixins: [userDetailsMixin],
  computed: {
    userDetails () {
      if (this.profile.fields['user-details']) {
        return JSON.parse(this.profile.fields['user-details'].value);
      } else return {};
    }
  },
  methods: {
    getProfileFieldValue (fieldName) {
      try {
        return this.profile.fields[fieldName].value.split('###');
      } catch (e) {
        return '';
      }
    },
    setClass1 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' ? 'd-flex align-start' : '';
    },
    setClass2 (field) {
      return field !== 'Relatives' && field !== 'Social events' && field !== 'Education' && field !== 'Experience' && field !== 'Volunteer expirience' ? 'subhead-width' : '';
    }
  }
};
</script>
