<template>
  <div class="popup" v-if="profiles.length">
    <div class="popup-left b-top b-left b-bottom bc-form-border">
      <div class="d-flex align-center justify-between mb-16">
        <span class="color-link custom-typography">{{profiles.length}} {{profileType}}</span>
      </div>
      <div class="fs-14 fw-600 color-text-lighter let-space-08 lh-20 mb-20">{{ leftText }}</div>
      <div class="d-flex justify-between">
        <button id="popup-save-in-tag" class="fs-13 fw-600 let-space-08 color-link_hover" @click="clickSaveInTag">
          <div class="tag-circle mr-12" :style="'background:' + savedTagInfo.color || '#eff4f7'">
            <Icon name="tag" :color="savedTagInfo.color ? '#ffffff' : '#60BCE3'" />
          </div>
          {{savedTagInfo.name ? `Saved in ${savedTagInfo.name}` : 'Save in tag'}}
        </button>
        <TagPopup :profiles="profiles" @closeTagsPopup="showTagsPopup = false" v-if="showTagsPopup" @showSavedTag="showSavedTag" />
        <Feature visible="VUE_APP_NSA_1366">
          <button class="fs-13 fw-600 let-space-08 color-link_hover" @click="startSearchForMultiprofile" v-if="profiles.length === 1">
            <Icon name="avatar-icon" class="mr-12" />
            Find profiles (12 slp)
          </button>
        </Feature>
      </div>
    </div>
    <Feature visible="VUE_APP_NSA_1630">
      <div class="popup-right">
        <div>
          <div class="d-flex align-center justify-between mb-16">
            <div class="typography-3 d-flex align-center justify-between" v-if="checkProfilesInMultiprofile && profiles.length > 1"> MP has already created</div>
            <div class="typography-3 d-flex align-center justify-between" v-else-if="countSocialNetwork > 1 && profiles.length === countSocialNetwork"> Combine profiles</div>
            <div class="typography-3" v-else-if="areConnections || (profiles.length > 1)"> try later</div>
            <div class="typography-3" v-else-if="countSocialNetwork > 1">Different social nets</div>
            <div class="typography-3" v-else>Get closest contacts</div>
            <Icon name="cancel-btn" :color="'white'" class="ml-16 fix-size pointer" @click="closePopup" />
          </div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-if="checkProfilesInMultiprofile && profiles.length > 1">Multiprofile from these profiles has already created</div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else-if="countSocialNetwork > 1 && profiles.length === countSocialNetwork">Combine these profiles into one multiprofile.</div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else-if="areConnections">The connection details is currently unavailable. Work in progress.</div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else-if="profiles.length > 1">The connection search is currently unavailable. Work in progress.</div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else-if="isLoadConnections">Social graph is already loaded. <br> All connections are available.</div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else>Get more info about the closest contacts of this profile</div>
        </div>
        <button class="fs-13 fw-600 let-space-08"
          v-if="countSocialNetwork > 1 && profiles.length === countSocialNetwork" @click="makeMultiprofile"
          :disabled="$store.state.multiprofile.opened || checkProfilesInMultiprofile"
          id="popup-action">
          <Icon name="tagged-connection-icon" class="mr-12" /> Make multiprofile
        </button>
        <button class="fs-13 fw-600 let-space-08" v-else-if="isLoadConnections" @click="openConnections">
          <Icon name="social-graph" class="mr-12" />
          Open connections
        </button>
        <button class="fs-13 fw-600 let-space-08"
          :disabled="countSocialNetwork > 1 || profiles.length > 1 || areConnections || isLoadConnections" @click="findConnections"
          id="popup-action"
          v-else
        >
          <Icon name="social-graph" class="mr-12" />
          {{ buttonText }}
        </button>
      </div>
    </Feature>
    <Feature visible="!VUE_APP_NSA_1630">
      <div class="popup-right">
        <div>
  <!--      <div class="typography-3 mb-12 d-flex align-center justify-between" v-if="countSocialNetwork > 1 && profiles.length === countSocialNetwork"> Combine profiles <Icon @click="closePopup" name="cancel-btn" class="ml-16 fix-size pointer" :color="'#fff'"/></div>-->
          <div class="d-flex jc-space-between align-center mb-16">
            <div class="typography-3" v-if="areConnections || (profiles.length > 1)"> try later</div>
            <div class="typography-3" v-else-if="countSocialNetwork > 1">Different social nets</div>
            <div class="typography-3" v-else>Get closest contacts</div>
            <Icon name="cancel-btn" :color="'white'" class="ml-16 fix-size pointer" @click="closePopup" />
          </div>
  <!--      <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-if="countSocialNetwork > 1 && profiles.length === countSocialNetwork">Combine these profiles into one multiprofile.</div>-->
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-if="areConnections">The connection details is currently unavailable. Work in progress.</div>
  <!--      <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else-if="countSocialNetwork > 1">Profiles must be from the same social network.</div>-->
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else-if="profiles.length > 1">The connection search is currently unavailable. Work in progress.</div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else-if="isLoadConnections">Social graph is already loaded. <br> All connections are available.</div>
          <div class="fs-14 fw-600 color-white let-space-08 lh-20 mb-20" v-else>Get more info about the closest contacts of this profile</div>
        </div>
  <!--      <button class="typography-7 let-space-08"-->
  <!--        v-if="countSocialNetwork > 1 && profiles.length === countSocialNetwork" @click="makeMultiprofile"-->
  <!--        id="popup-action">-->
  <!--        <Icon name="tagged-connection-icon" class="mr-12" /> Make multiprofile-->
  <!--      </button>-->
        <button class="fs-13 fw-600 let-space-08" v-if="isLoadConnections" @click="openConnections">
          <Icon name="social-graph" class="mr-12" />
          Open connections
        </button>
        <button class="fs-13 fw-600 let-space-08"
          :disabled="countSocialNetwork > 1 || profiles.length > 1 || areConnections || isLoadConnections" @click="findConnections"
          id="popup-action"
          v-else
        >
          <Icon name="social-graph" class="mr-12" />
          {{ buttonText }}
        </button>
      </div>
    </Feature>
  </div>
</template>

<script>
import smartlookClient from 'smartlook-client';
import Icon from './Icon';
import TagPopup from '../TagPopup';
import { eventListener } from '../../utils/helpers';
import { mapGetters } from 'vuex';
import Feature from '@/components/app/Feature';
import amplitude from 'amplitude-js';

export default {
  name: 'Popup',
  components: {
    Icon,
    TagPopup,
    Feature
  },
  data () {
    return {
      showTagsPopup: false,
      savedTagInfo: {
        name: '',
        color: ''
      }
    };
  },
  props: ['profiles'],
  created () {
    eventListener.on('clearInfoInPopup', () => {
      this.savedTagInfo.name = '';
      this.savedTagInfo.color = '';
    });
  },
  watch: {
    'profiles' () {
      if (!this.profiles.length) {
        this.closePopup();
        this.checkedArr = [];
      }
    }
  },
  computed: {
    ...mapGetters(['resultsMap', 'sortedRelationSearches', 'multiprofiles', 'accountState']),
    checkProfilesInMultiprofile () {
      let result = false;
      if (this.multiprofiles) {
        this.multiprofiles.forEach(mp => {
          if (mp.entity_ids.length === this.profiles.length) {
            const arr = [];
            mp.entity_ids.forEach(id => {
              const profile = this.profiles.find(profile => profile.oid === id);
              if (profile) arr.push(profile);
            });
            if (arr.length === mp.entity_ids.length) {
              result = true;
            }
          }
        });
      }
      return result;
    },
    countSocialNetwork () {
      const arr = [];
      this.profiles.forEach(profile => {
        if (!arr.includes(profile.typeid)) {
          arr.push(profile.typeid);
        }
      });
      return arr.length;
    },
    areConnections () {
      if (!this.profiles.length) {
        return false;
      }
      for (const profile of this.profiles) {
        if (!profile?.fields?.relation?.value) {
          return false;
        }
      }
      return true;
    },
    profileType () {
      let profileType = this.areConnections ? 'connection' : 'profile';
      if (this.profiles.length > 1) {
        profileType += 's';
      }
      return profileType;
    },
    leftText () {
      if (!this.areConnections) {
        return 'You can tag these results for inclusion in a report, or expand this profile further.';
      }
      return 'View connected events on timeline or save whole connection to add to a report later.';
    },
    buttonText () {
      if (this.areConnections) {
        return 'Provide details (15 SLP)';
      }
      return this.profiles.length === 1 ? 'Explore connections (15 SLP)' : 'Find connections (15 SLP)';
    },
    isLoadConnections () {
      if (this.profiles.length > 1) return false;
      let isLoaded = false;
      for (let i = 0; i < this.sortedRelationSearches.length; i++) {
        if (this.sortedRelationSearches[i].targets.length > 1) continue;
        if (this.sortedRelationSearches[i].targets[0] === this.profiles[0].oid) {
          isLoaded = this.sortedRelationSearches[i];
          break;
        }
      }
      return isLoaded;
    },
    profilesForMp () {
      return this.countSocialNetwork > 1 && this.profiles.length === this.countSocialNetwork;
    }
  },
  methods: {
    closePopup () {
      if (!this.areConnections) {
        this.$store.commit('updateFilterCheck', false);
      }
      this.$store.commit('uncheckAllResults');
      this.$store.commit('checkAllConnectionProfiles', false);
      this.$store.commit('checkAllEdges', false);
      this.savedTagInfo.name = '';
      this.savedTagInfo.color = '';
      this.$store.commit('uncheckTags');
      this.$store.commit('uncheckMultiProfiles');
    },
    async findConnections () {
      this.amplitudeTracking('prof_details_conn');

      smartlookClient.track('spent_slp', { where: 'find connections (popup)', quantity: 15 });
      this.$store.commit('selectProfile', null);
      this.$store.commit('removeLastVisibleOIDs');
      await this.$store.dispatch('findConnections', this.profiles);
    },
    showSavedTag (obj) {
      if (obj) {
        this.savedTagInfo.name = obj.name;
        this.savedTagInfo.color = obj.color;
      }
    },
    async makeMultiprofile () {
      const event = 'mp_create';
      const eventProperties = {
        place: 'popup',
        auto: false
      };
      const userProperties = {
        subscription: this.accountState.subscription.name,
        company: this.accountState.company,
        role: this.accountState.role
      };
      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);

      try {
        const payload = {
          method: 'POST',
          url: '/api/user/multiprofiles/create',
          body: {
            case_id: this.$route.params.id,
            entity_ids: []
          }
        };
        this.profiles.forEach(profile => payload.body.entity_ids.push(profile.oid));
        const multiprofile = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        multiprofile.data.checked = false;
        this.$store.commit('addMultiprofiles', multiprofile.data);
        this.$store.dispatch('openMultiprofile', multiprofile.data);
      } catch (error) {
        console.error(error);
      }
    },
    async startSearchForMultiprofile () {
      try {
        let payload = {
          method: 'POST',
          url: '/api/multiprofile/match',
          body: {
            search_id: this.profiles[0].rid,
            entity_id: this.profiles[0].oid
          }
        };
        const multiprofile = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        if (!multiprofile.data.multiprofiles?.length) return;
        multiprofile.data.checked = false;
        const profiles = [];
        const set = new Set();
        multiprofile.data.multiprofiles[0].entity_ids.forEach(id => {
          for (const key of Object.keys(this.resultsMap)) {
            if (this.resultsMap[key].oid === id) {
              if (set.has(this.resultsMap[key].typeid)) return;
              set.add(this.resultsMap[key].typeid);
              profiles.push(id);
            }
          }
        });

        payload = {
          method: 'POST',
          url: '/api/user/multiprofiles/create',
          body: {
            case_id: this.$route.params.id,
            entity_ids: profiles
          }
        };
        const saveMp = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        this.$store.commit('addMultiprofiles', saveMp.data);
        this.$store.dispatch('openMultiprofile', saveMp.data);
      } catch (error) {
        console.error(error);
      }
    },
    async openConnections () {
      this.$store.commit('activateRelationsSection', true);
      await this.$store.dispatch('openConnections', this.isLoadConnections);
      this.$nextTick(async () => { await this.$store.dispatch('stopSearching', true); });
    },
    clickSaveInTag () {
      this.amplitudeTracking('save_tag');

      this.showTagsPopup = true;
    },
    amplitudeTracking (eventName) {
      const event = eventName;
      const eventProperties = {
        soc_net: [],
        place: 'popup'
      };
      const userProperties = {
        subscription: this.accountState.subscription.name,
        company: this.accountState.company,
        role: this.accountState.role
      };

      for (const profile of this.profiles) {
        switch (profile.typeid.replace('maltego.', '').replace('.profile', '').replace('affiliation.', '').toLowerCase()) {
          case 'instagram':
            eventProperties.soc_net.push('instagram');
            break;
          case 'facebook':
            eventProperties.soc_net.push('facebook');
            break;
          case 'twitter':
            eventProperties.soc_net.push('twitter');
            break;
          case 'linkedin':
            eventProperties.soc_net.push('linkedin');
            break;
        }
      }
      eventProperties.soc_net = eventProperties.soc_net.filter((val, index, array) => array.indexOf(val) === index);

      amplitude.getInstance().setUserProperties(userProperties);
      amplitude.getInstance().logEvent(event, eventProperties);
    }
  }
};
</script>

<style lang="scss" scoped>
.fix-size {
  width: 16px;
  height: 16px;
}
.popup-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.custom-typography {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.02em;
}
.custom-color {
  color: #A0B2BE;
}
.custom-line-height {
  line-height: 20px;
}
</style>
